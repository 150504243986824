import React, { useState } from "react";
import Navbar from "../../components/Page Component/Navbar";
import Footer from "../../components/Page Component/Footer";
import { Box } from "@mui/material";
import HRDataPoints from "./HRDataPoints";
import FilllAndViewDataPoints from "./FilllAndViewDataPoints";

const rolesToCheck = ["HR", "Manager", "Admin"];

const DataPoints = () => {
  const [role, setRole] = useState<string | null>(
    localStorage.getItem("role") || null
  );

  return (
    <>
      <Navbar />
      <Box sx={{ width: "98vw", mt: 12, mb: 10 }}>
        {role && rolesToCheck.some((value) => role?.includes(value)) ? (
          <Box>
            <HRDataPoints />
          </Box>
        ) : (
          <Box>
            <FilllAndViewDataPoints />
          </Box>
        )}
      </Box>
      <Footer />
    </>
  );
};

export default DataPoints;
