import { Badge, Box, Button, Paper, Tab, Tabs } from "@mui/material";
import { ThunkDispatch } from "@reduxjs/toolkit";
import React, { useEffect, useMemo, useState } from "react";
import { useDispatch } from "react-redux";
import {
  CustomTabPanel,
  a11yProps,
} from "../../../components/TabPanel/TabPanel";
import { RootState } from "../../../store/Store";
import {
  FetchDataFailureAction,
  FetchDataSuccessAction,
  fetchData,
} from "../../../store/actions";
import {
  admin360AllReview,
  showEmployeeAssigned360Form,
  showManagerAssigned360Form,
  showOrganizationLeaderAssigned360Form,
  showPeerAssigned360Form,
  showPrevious360FormsEmployee,
} from "../../../Service/Apis";
import { DataGrid, GridColDef, GridToolbar } from "@mui/x-data-grid";
import CustomNoRowsOverlay from "../../../components/Data/NoRowsOverlayComponent";
import { log } from "util";
import { setFormTeamId, setId } from "../../../store/FormSlice";
import { useNavigate } from "react-router-dom";
import { setIsLoading } from "../../../store/LoadingSlice";

interface AdminAllReviewType {
  admin_average_rating: string;
  employee_average_rating: string;
  employee_code: string;
  employee_designation: string;
  employee_name: string;
  form_description: string;
  form_id: string;
  form_name: string;
  hr_edit_permission: boolean;
  hr_id: string;
  hr_name: string;
  manager_average_rating: string;
  manager_name: string;
  response_id: string;
  standard_deviation: string;
  team_name: string;
}

interface AssignedForms {
  team_id: string;
  project_name: string;
  manager_name: string;
  manager: string;

  form_team_id: string;
  form_id: string;
  form_name: string;
  responses: {
    employee_code: string;
    employee_designation: string;
    employee_id: string;
    employee_name: string;
    final_submit_done: boolean;
    manager_final_submit_done: boolean;
  };
  admin_final_submit_done: boolean;
  response: [
    {
      section_id: string;
      form_id: string;
      section: null;
      categories: [
        {
          category_id: string;
          section_id: string;
          category: string;
          is_rating_required: boolean;
          questions: [
            {
              question_id: string;
              category_id: string;
              form_id: string;
              question: string;
              answer_type: string;
              answer_choices: [
                {
                  option_id: string;
                  answer_value: string;
                }
              ];
            }
          ];
        }
      ];
    }
  ];
  questions_old_format: [];
  employee_deadline: string;
  has_submitted: boolean;
  final_submit_done: boolean;
  is_appraisal: boolean;
  is_hierarchical: boolean;
  is_360: boolean;
}

interface FormType {
  project_name: string;
  team_id: string;

  employee_deadline: string;
  final_submit_done: boolean;
  form_id: string;
  form_team_id: string;
  form_name: string;
}

const ThreeSixtyAdminDashboard = () => {
  const [value, setValue] = React.useState(0);
  const [adminAssignedForms, setAdminAssignedForms] = useState<AssignedForms[]>(
    []
  );
  const [adminAllReviews, setAdminAllReviews] = useState<AdminAllReviewType[]>(
    []
  );
  const [previousForms, setPreviousForm] = useState<FormType[]>([]);
  const [pendingFormsCount, setPendingFormsCount] = useState<number | null>(
    null
  );

  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setValue(newValue);
  };

  const navigate = useNavigate();
  const dispatch = useDispatch();
  const dispatch2: ThunkDispatch<
    RootState,
    null,
    FetchDataSuccessAction | FetchDataFailureAction
  > = useDispatch();

  const adminRows = adminAssignedForms.map((row, index) => ({
    ...row,
    index: index,
    sr_no: index + 1,
  }));

  const adminAllReviewRows = adminAllReviews.map((row, index) => ({
    ...row,
    index: index,
    sr_no: index + 1,
  }));

  const adminPreviousFormsRows = previousForms.map((row, index) => ({
    ...row,
    index: index,
    sr_no: index + 1,
  }));

  const adminColumns: GridColDef[] = [
    {
      field: "sr_no",
      headerName: "Sr. No",
      renderHeader: () => (
        <strong
          className="custom-header"
          style={{ fontSize: 16, textAlign: "center" }}
        >
          Sr No.
        </strong>
      ),
      width: 155,
      align: "center",
      headerAlign: "center",
      headerClassName: "header-background",
    },
    {
      field: "form_name",
      headerName: "Form Name",
      renderHeader: () => (
        <strong
          className="custom-header"
          style={{ fontSize: 16, textAlign: "center" }}
        >
          Form Name
        </strong>
      ),
      width: 155,
      align: "center",
      headerAlign: "center",
      headerClassName: "header-background",
    },
    {
      field: "employee_name",
      headerName: "Employee Name",
      renderHeader: () => (
        <strong
          className="custom-header"
          style={{ fontSize: 16, textAlign: "center" }}
        >
          Employee Name
        </strong>
      ),
      width: 155,
      align: "center",
      headerAlign: "center",
      headerClassName: "header-background",
    },
    {
      field: "manager",
      headerName: "Manager Name",
      renderHeader: () => (
        <strong
          className="custom-header"
          style={{ fontSize: 16, textAlign: "center" }}
        >
          Manager Name
        </strong>
      ),
      width: 155,
      align: "center",
      headerAlign: "center",
      headerClassName: "header-background",
    },
    {
      field: "employee_deadline",
      headerName: "Deadline",
      renderHeader: () => (
        <strong
          className="custom-header"
          style={{ fontSize: 16, textAlign: "center" }}
        >
          Deadline
        </strong>
      ),
      width: 155,
      align: "center",
      headerAlign: "center",
      headerClassName: "header-background",
    },
    {
      field: "action",
      headerName: "Actions",
      renderHeader: () => (
        <strong
          className="custom-header"
          style={{ fontSize: 16, textAlign: "center" }}
        >
          Actions
        </strong>
      ),
      width: 155,
      align: "center",
      headerAlign: "center",
      headerClassName: "header-background",
      renderCell: (params: any) => (
        <Button
          size="small"
          onClick={() => {
            dispatch(setFormTeamId(params.row.form_team_id));
            localStorage.setItem("formTeamId", params.row.form_team_id);
            dispatch(setId(params.row.form_id));
            localStorage.setItem("responseId", params.row.response_id);
            localStorage.setItem("formid", params.row.form_id);
            localStorage.setItem(
              "Role360",
              JSON.stringify({
                isEmployee: false,
                isManager: false,
                isOrganizationLeader: true,
                isPeer: false,
              })
            );
            localStorage.setItem(
              "feedbackRole",
              JSON.stringify({
                is_employee_feedback: false,
                is_manager_feedback: false,
              })
            );
            navigate("/fillForm");
          }}
        >
          Go To Review
        </Button>
      ),
    },
  ];

  const adminAllReviewColumns: GridColDef[] = [
    {
      field: "sr_no",
      headerName: "Sr. No",
      renderHeader: () => (
        <strong
          className="custom-header"
          style={{ fontSize: 16, textAlign: "center" }}
        >
          Sr No.
        </strong>
      ),
      width: 105,
      align: "center",
      headerAlign: "center",
      headerClassName: "header-background",
    },
    {
      field: "form_name",
      headerName: "Form Name",
      renderHeader: () => (
        <strong
          className="custom-header"
          style={{ fontSize: 16, textAlign: "center" }}
        >
          Form Name
        </strong>
      ),
      width: 155,
      align: "center",
      headerAlign: "center",
      headerClassName: "header-background",
    },
    {
      field: "employee_name",
      headerName: "Employee Name",
      renderHeader: () => (
        <strong
          className="custom-header"
          style={{ fontSize: 16, textAlign: "center" }}
        >
          Employee Name
        </strong>
      ),
      width: 150,
      align: "center",
      headerAlign: "center",
      headerClassName: "header-background",
    },
    {
      field: "manager_name",
      headerName: "Manager Name",
      renderHeader: () => (
        <strong
          className="custom-header"
          style={{ fontSize: 16, textAlign: "center" }}
        >
          Manager Name
        </strong>
      ),
      width: 140,
      align: "center",
      headerAlign: "center",
      headerClassName: "header-background",
    },
    {
      field: "employee_360_average_rating",
      headerName: "Employee AVG Rating",
      renderHeader: () => (
        <strong
          className="custom-header"
          style={{ fontSize: 16, textAlign: "center" }}
        >
          Employee AVG
        </strong>
      ),
      width: 130,
      align: "center",
      headerAlign: "center",
      headerClassName: "header-background",
    },
    {
      field: "manager_360_average_rating",
      headerName: "Manager AVG Rating",
      renderHeader: () => (
        <strong
          className="custom-header"
          style={{ fontSize: 16, textAlign: "center" }}
        >
          Manager AVG
        </strong>
      ),
      width: 120,
      align: "center",
      headerAlign: "center",
      headerClassName: "header-background",
    },
    {
      field: "organization_leader_360_average_rating",
      headerName: "Peers AVG Rating",
      renderHeader: () => (
        <strong
          className="custom-header"
          style={{ fontSize: 16, textAlign: "center" }}
        >
          Company Leader AVG
        </strong>
      ),
      width: 215,
      align: "center",
      headerAlign: "center",
      headerClassName: "header-background",
    },
    {
      field: "peer_360_average_rating",
      headerName: "Peers AVG Rating",
      renderHeader: () => (
        <strong
          className="custom-header"
          style={{ fontSize: 16, textAlign: "center" }}
        >
          Peers AVG
        </strong>
      ),
      width: 100,
      align: "center",
      headerAlign: "center",
      headerClassName: "header-background",
    },
    {
      field: "action",
      headerName: "Actions",
      renderHeader: () => (
        <strong
          className="custom-header"
          style={{ fontSize: 16, textAlign: "center" }}
        >
          Actions
        </strong>
      ),
      width: 155,
      align: "center",
      headerAlign: "center",
      headerClassName: "header-background",
      renderCell: (params: any) => (
        <Button
          size="small"
          variant="contained"
          onClick={() => {
            localStorage.setItem("responseId", params.row.response_id);
            localStorage.setItem("teamId", params.row.team_id);
            navigate("/showthreesixtyformresponse");
          }}
        >
          Go To Review
        </Button>
      ),
    },
  ];

  const adminPreviousFormColumns: GridColDef[] = useMemo(
    () => [
      {
        headerName: "Sr No.",
        field: "sr_no",
        align: "center",
        headerAlign: "center",
        headerClassName: "header-background",
        width: 125,
        renderHeader: () => (
          <strong className="custom-header" style={{ fontSize: 15 }}>
            Sr No.
          </strong>
        ),
      },
      {
        headerName: "Employee Name",
        field: "employee_name",
        align: "center",
        headerAlign: "center",
        headerClassName: "header-background",
        width: 165,
        renderHeader: () => (
          <strong className="custom-header" style={{ fontSize: 15 }}>
            Employee Name
          </strong>
        ),
      },
      {
        headerName: "Manager Name",
        field: "manager",
        align: "center",
        headerAlign: "center",
        headerClassName: "header-background",
        width: 165,
        renderHeader: () => (
          <strong className="custom-header" style={{ fontSize: 15 }}>
            Manager Name
          </strong>
        ),
      },
      {
        headerName: "Form Name",
        field: "form_name",
        align: "center",
        headerAlign: "center",
        headerClassName: "header-background",
        width: 165,
        renderHeader: () => (
          <strong className="custom-header" style={{ fontSize: 15 }}>
            Form Name
          </strong>
        ),
      },
      {
        headerName: "Employee Deadline",
        field: "employee_deadline",
        align: "center",
        headerAlign: "center",
        headerClassName: "header-background",
        width: 195,
        renderHeader: () => (
          <strong className="custom-header" style={{ fontSize: 15 }}>
            Employee Deadline
          </strong>
        ),
      },
      {
        headerName: "Options",
        field: "options",
        align: "center",
        headerAlign: "center",
        headerClassName: "header-background",
        width: 175,
        renderHeader: () => (
          <strong className="custom-header" style={{ fontSize: 15 }}>
            Options
          </strong>
        ),
        renderCell: (params) => (
          <>
            <Button
              variant="contained"
              size="small"
              onClick={(e) => {
                dispatch(setFormTeamId(params.row.form_team_id));
                localStorage.setItem("formTeamId", params.row.form_team_id);
                localStorage.setItem("teamId", params.row.team_id);
                dispatch(setId(params.row.form_id));
                localStorage.setItem("responseId", params.row.response_id);
                localStorage.setItem("formid", params.row.form_id);
                localStorage.setItem("peerNumber", params.row.peer_number);
                localStorage.setItem(
                  "Role360",
                  JSON.stringify({
                    isEmployee: false,
                    isManager: false,
                    isOrganizationLeader: true,
                    isPeer: false,
                  })
                );
                navigate("/employee360previousresponse");
              }}
            >
              View Response
            </Button>
          </>
        ),
      },
    ],
    [previousForms, adminPreviousFormsRows]
  );

  useEffect(() => {
    const fetchAdminAssignedForms = async () => {
      try {
        const response = await dispatch2(
          fetchData(`${showOrganizationLeaderAssigned360Form}`)
        );
        setAdminAssignedForms(response as AssignedForms[]);
        setPendingFormsCount(
          (response as AssignedForms[]).reduce(
            (count, formItem) =>
              count + (formItem?.admin_final_submit_done === false ? 1 : 0),
            0
          )
        );
      } catch (error) {
        console.log(error);
      }
    };

    const fetchAllForms = async () => {
      try {
        const response = await dispatch2(fetchData(`${admin360AllReview}`));
        setAdminAllReviews(response as AdminAllReviewType[]);
      } catch (error) {
        console.log(error);
      }
    };
    fetchAdminAssignedForms();
    fetchAllForms();

    const fetchPreviousAdminForms = async () => {
      try {
        const response = await dispatch2(
          fetchData(
            `${showPrevious360FormsEmployee}?is_organization_leader=True`
          )
        );
        setPreviousForm(response as FormType[]);

        dispatch(setIsLoading(false));
      } catch (error) {
        console.log(error);
        dispatch(setIsLoading(false));
      }
    };
    fetchPreviousAdminForms();
  }, []);

  return (
    <>
      <Box
        sx={{
          display: "flex",
          height: "100%", // Adjust the height as needed
          width: "98vw",
        }}
      >
        <Box
          sx={{
            minWidth: 150, // Set the width for the left sidebar
            bgcolor: "background.paper",
          }}
        >
          <Tabs
            centered
            value={value}
            onChange={handleChange}
            orientation="vertical"
            textColor="inherit"
            indicatorColor="secondary"
            aria-label="secondary tabs example"
          >
            <Tab
              {...a11yProps(0)}
              label={
                <Badge color="primary" sx={{ p: 0.5 }}>
                  Review All Forms
                </Badge>
              }
            ></Tab>
            <Tab
              {...a11yProps(1)}
              label={
                <Badge
                  color="primary"
                  sx={{ p: 0.5 }}
                  badgeContent={
                    pendingFormsCount !== 0 ? pendingFormsCount : null
                  }
                >
                  Pending Forms
                </Badge>
              }
            />
            <Tab
              {...a11yProps(2)}
              label={
                <Badge color="primary" sx={{ p: 0.5 }}>
                  Previous Forms
                </Badge>
              }
            />
          </Tabs>
        </Box>
        <Box
          sx={{
            flex: 1,
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <CustomTabPanel index={0} value={value}>
            <Paper
              sx={{
                width: "100%",
                display: "flex",
                justifyContent: "center",
              }}
            >
              <DataGrid
                autoHeight
                initialState={{
                  pagination: {
                    paginationModel: {
                      pageSize: 10,
                    },
                  },
                }}
                disableColumnMenu
                sx={{
                  width: "100%",
                  height: "100%",
                  "--DataGrid-overlayHeight": "50vh",
                }}
                pagination
                pageSizeOptions={[5, 10, 15]}
                rows={adminAllReviewRows}
                columns={adminAllReviewColumns}
                disableColumnSelector
                disableDensitySelector
                slots={{
                  toolbar: GridToolbar,
                  noRowsOverlay: CustomNoRowsOverlay,
                  noResultsOverlay: CustomNoRowsOverlay,
                }}
                slotProps={{
                  toolbar: {
                    csvOptions: { disableToolbarButton: true },
                    showQuickFilter: true,
                    printOptions: { disableToolbarButton: true },
                    style: { margin: "0.5rem" },
                  },
                }}
                getRowId={(row) => row.index}
              />
            </Paper>
          </CustomTabPanel>
          <CustomTabPanel index={1} value={value}>
            <Paper
              sx={{
                width: "fit-content",
                display: "flex",
                justifyContent: "center",
              }}
            >
              <DataGrid
                autoHeight
                initialState={{
                  pagination: {
                    paginationModel: {
                      pageSize: 10,
                    },
                  },
                }}
                disableColumnMenu
                sx={{
                  width: "100%",
                  height: "100%",
                  "--DataGrid-overlayHeight": "50vh",
                }}
                pagination
                pageSizeOptions={[5, 10, 15]}
                rows={adminRows}
                columns={adminColumns}
                disableColumnSelector
                disableDensitySelector
                slots={{
                  toolbar: GridToolbar,
                  noRowsOverlay: CustomNoRowsOverlay,
                  noResultsOverlay: CustomNoRowsOverlay,
                }}
                slotProps={{
                  toolbar: {
                    csvOptions: { disableToolbarButton: true },
                    showQuickFilter: true,
                    printOptions: { disableToolbarButton: true },
                    style: { margin: "0.5rem" },
                  },
                }}
                getRowId={(row) => row.team_id}
              />
            </Paper>
          </CustomTabPanel>
          <CustomTabPanel index={2} value={value}>
            <Paper
              sx={{
                width: "fit-content",
                display: "flex",
                justifyContent: "center",
              }}
            >
              <DataGrid
                autoHeight
                initialState={{
                  pagination: {
                    paginationModel: {
                      pageSize: 10,
                    },
                  },
                }}
                disableColumnMenu
                sx={{
                  width: "100%",
                  height: "100%",
                  "--DataGrid-overlayHeight": "50vh",
                }}
                pagination
                pageSizeOptions={[5, 10, 15]}
                rows={adminPreviousFormsRows}
                columns={adminPreviousFormColumns}
                disableColumnSelector
                disableDensitySelector
                slots={{
                  toolbar: GridToolbar,
                  noRowsOverlay: CustomNoRowsOverlay,
                  noResultsOverlay: CustomNoRowsOverlay,
                }}
                slotProps={{
                  toolbar: {
                    csvOptions: { disableToolbarButton: true },
                    showQuickFilter: true,
                    printOptions: { disableToolbarButton: true },
                    style: { margin: "0.5rem" },
                  },
                }}
                getRowId={(row) => row.team_id}
              />
            </Paper>
          </CustomTabPanel>
        </Box>
      </Box>
    </>
  );
};

export default ThreeSixtyAdminDashboard;
