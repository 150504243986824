import { createSlice, PayloadAction } from "@reduxjs/toolkit";

export interface NavState {
	sidebarItems: {
		[key: string]: { label: string; route: string }[];
	};
}

const initialState: NavState = {
	sidebarItems: {
		admin: [
			{ label: "Dashboard", route: "dashboard" },
			{ label: "Employee Information", route: "employeeinformation" },
			{ label: "Reviews", route: "reviews" },
			{ label: "Create team", route: "createteam" },
			{ label: "Permission", route: "permission" },
		],
		employee: [
			{ label: "Dashboard", route: "dashboard" },
			{ label: "Previous Responses", route: "previousresponses" },
		],
		hr: [
			{ label: "Dashboard", route: "dashboard" },
			{ label: "Employee Information", route: "employeeinformation" },
			{ label: "Reviews", route: "reviews" },
			{ label: "Add Form", route: "addForm" },
			{ label: "Create team", route: "createteam" },
			{ label: "Created Forms", route: "createdforms" },
		],
		manager: [
			{ label: "Dashboard", route: "dashboard" },
			{ label: "Fill Form", route: "fillForm" },
			{ label: "Add Review", route: "reviewForm" },
			{ label: "Subordinate", route: "subordinate" },
		],
	},
};

const NavbarSlice = createSlice({
	name: "form",
	initialState,
	reducers: {
		// getItems: (state, action: PayloadAction<string[]>) => {
		//   let items: { label: string; route: string }[] = [];
		//   action.payload.forEach((role) => {
		//     const roleItems = state.sidebarItems[role.toLowerCase()];
		//     if (roleItems) {
		//       items = [...items, ...roleItems];
		//     }
		//   });
		//   return { ...state, items };
		// },
	},
});

export const {} = NavbarSlice.actions;

export default NavbarSlice;
