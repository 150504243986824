import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  FetchDataFailureAction,
  FetchDataSuccessAction,
  fetchData,
} from "../../store/actions";
import { setIsLoading } from "../../store/LoadingSlice";
import { ShowForms, viewEmployeeSelfResponse } from "../../Service/Apis";
import { ThunkDispatch } from "@reduxjs/toolkit";
import { RootState } from "../../store/Store";
import FIllHeiForm from "./FIllHeiForm";
import FillOrSaveAppraisalForm from "./FillOrSaveAppraisalForm";
import ThreeSixtyFormFill from "./ThreeSixtyForm/ThreeSixtyFormFill";
import FeedBackFillFOrm from "./FeedBackForm/FeedBackFormFill";

interface FormMasterType {
  created_by: string;
  creator_email: string;
  creator_name: string;
  employee_deadline: string;
  form_description: string;
  form_id: string;
  form_name: string;
  is_360: boolean;
  is_appraisal: boolean;
  is_hierarchical: boolean;
  is_uploaded: boolean;
  manager_deadline: string;
  teams: [];
}

const AllFillForm = () => {
  const [form, setForm] = useState<FormMasterType[]>([]);

  const formId =
    useSelector((state: any) => state.form.id) ||
    localStorage.getItem("formTeamId");

  const formTeamId =
    useSelector((state: any) => state.form.formTeamId) ||
    localStorage.getItem("formTeamId");

  const dispatch = useDispatch();
  const dispatch2: ThunkDispatch<
    RootState,
    null,
    FetchDataSuccessAction | FetchDataFailureAction
  > = useDispatch();

  useEffect(() => {
    const fetchEmployeeResponse = async () => {
      try {
        dispatch(setIsLoading(true));
        const response = await dispatch2(
          fetchData(`${ShowForms}?form_id=${formId}`)
        );
        setForm(response as FormMasterType[]);
        dispatch(setIsLoading(false));
      } catch (error) {
        console.log(error);
        dispatch(setIsLoading(false));
      }
    };
    fetchEmployeeResponse();
  }, []);

  if (form[0]?.is_hierarchical) {
    return <FillOrSaveAppraisalForm />;
  } else if (form[0]?.is_360) {
    return <ThreeSixtyFormFill />;
  } else if (!form[0]?.is_hierarchical && !form[0]?.is_appraisal) {
    return <FeedBackFillFOrm />;
  }
};

export default AllFillForm;
