import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { setIsLoggedIn } from "./../../store/AuthSlice";
import { useNavigate, useLocation } from "react-router-dom";
import { getSidebarItems } from "../Data/data";
import axios from "axios";

interface LayoutProps {
  children: React.ReactNode;
}

const Layout: React.FC<LayoutProps> = ({ children }) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();
  const sidebarItems = useSelector((state: any) => state.navbar.sidebarItems);
  const isLoggedIn = useSelector((state: any) => state.auth.isLoggedIn);

  useEffect(() => {
    const token = localStorage.getItem("token");
    const storedRole = localStorage.getItem("role");
    const roleArray = storedRole ? JSON.parse(storedRole) : [];
    const isAuthenticated = token !== null; //|| window.location.pathname === "/forgotpassword";
    const isAuthorized = checkAuthorization(roleArray);
    const isLoginPage = location.pathname === "/login";

    dispatch(setIsLoggedIn(isAuthenticated));
    if (window.location.pathname !== "/forgotpassword") {
      if (!isLoginPage) {
        if (!isAuthenticated || !isAuthorized) {
          navigate("/login");
        }
      }
    }
  }, [dispatch, navigate, location]);

  const checkAuthorization = (roleArray: string[]): boolean => {
    const userRoles = roleArray.map((role: string) => role.toLowerCase());
    const authorizedItems = getSidebarItems(userRoles);

    const currentRoute = window.location.pathname.replace("/", "");

    const isAuthorized =
      currentRoute === "dashboard" ||
      currentRoute === "profile" ||
      currentRoute === "viewform" ||
      currentRoute === "fillForm" ||
      currentRoute === "managerreview" ||
      currentRoute === "reviewForm" ||
      currentRoute === "logout" ||
      currentRoute === "editform" ||
      currentRoute === "showresponse" ||
      currentRoute === "showspecificresponse" ||
      currentRoute === "showspecificresponsehr" ||
      currentRoute === "managerreview" ||
      currentRoute === "download" ||
      currentRoute === "downloadadmin" ||
      currentRoute === "employeepreviousresponse" ||
      currentRoute === "addEmployee" ||
      currentRoute === "viewEmployee" ||
      currentRoute === "addTeam" ||
      currentRoute === "editTeam" ||
      currentRoute === "viewTeam" ||
      currentRoute === "forgotpassword" ||
      currentRoute === "showthreesixtyformresponse" ||
      currentRoute === "employee360previousresponse" ||
      currentRoute === "addoreditskills" ||
      currentRoute === "showfeedbackresponse" ||
      currentRoute === "datapoints" ||
      authorizedItems.some((item: any) => item.route === currentRoute);

    return isAuthorized;
  };

  const handleUnauthorizedError = () => {
    navigate("/login");
  };

  useEffect(() => {
    const axiosInstance = axios.create();

    // Register the response error handler interceptor
    const errorInterceptor = axiosInstance.interceptors.response.use(
      (response) => response,
      (error) => {
        if (error.response?.status === 401) {
          handleUnauthorizedError();
        }
        return Promise.reject(error);
      }
    );

    return () => {
      // Cleanup the interceptor when the component unmounts
      axiosInstance.interceptors.response.eject(errorInterceptor);
    };
  }, []);

  return <>{children}</>;
};

export default Layout;
