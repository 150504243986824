import React, { useEffect, useState } from "react";
import {
  Box,
  Card,
  Typography,
  LinearProgress,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  Rating,
  CircularProgress,
} from "@mui/material";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";

import { ThunkDispatch } from "@reduxjs/toolkit";
import { useDispatch, useSelector } from "react-redux";

import {
  ToastVariant,
  useCustomToast,
} from "../../components/Methods/SnackBar";

import { RootState } from "../../store/Store";
import {
  FetchDataSuccessAction,
  FetchDataFailureAction,
  fetchData,
  postData,
  DataPayload,
} from "../../store/actions";

import {
  editEmployeeSkillResponse,
  viewEmployeeSkillResponse,
} from "../../Service/Apis";

import { setInPageLoading } from "../../store/LoadingSlice";
import { EmployeeSkillSetType } from "./Types/SkillTypes";
import NothingToDisplayComponent from "../../components/Data/NothingToShowComponent";
import Loading from "../../components/Loading/Loading";

const label = ["Awareness", "Novice", "Competant", "Professional", "Expert"];

const ratingLabel = [
  { label: "Awareness", color: "#FF5C5C" }, // Red
  { label: "Novice", color: "#5C8CFF" }, // Blue
  { label: "Competent", color: "#5CFF6E" }, // Green
  { label: "Professional", color: "#FF9F5C" }, // Orange/Pink
  { label: "Expert", color: "#B85CFF" }, // Violet
];

const EmployeeSkillSet = () => {
  // State Hooks
  const [data, setData] = useState<EmployeeSkillSetType[]>([]);
  const [selectedSkill, setSelectedSkill] =
    useState<EmployeeSkillSetType | null>(null);
  const [openDialog, setOpenDialog] = useState(false);
  const [count, setCount] = useState<number>(0);

  // Toast Hook
  const showToast = useCustomToast();

  // Redux Hooks
  const dispatch = useDispatch();
  const dispatch2: ThunkDispatch<
    RootState,
    null,
    FetchDataSuccessAction | FetchDataFailureAction
  > = useDispatch();

  // Selector for Loading State
  const isLoading = useSelector((state: any) => state.loading.inPageLoading);

  // Show Toast Helper Function
  const handleShowToast = (
    message: string,
    variant: ToastVariant,
    isCloseable: boolean
  ) => {
    showToast(message, variant, isCloseable);
  };

  const getLabelAndColor = (linearProgressBarValue: any) => {
    let cumulativePercentage = 0;
    for (const { label, color } of ratingLabel) {
      const labelRange = 20; // Each label gets 20% range
      if (linearProgressBarValue <= cumulativePercentage + labelRange) {
        return { label, color };
      }
      cumulativePercentage += labelRange;
    }
  };

  // Calculate Progress Function
  const calculateProgress = (questions: any) => {
    const attemptedCount = questions.filter(
      (que: any) => que.employee_skill_response !== null
    ).length;
    return (attemptedCount / questions.length) * 100;
  };

  // Handle Rating Change Function
  const handleRatingChange = (value: string, selectedQuestionIndex: number) => {
    if (selectedSkill && selectedQuestionIndex !== null) {
      const updatedQuestions = [...selectedSkill.questions];
      updatedQuestions[selectedQuestionIndex].employee_skill_response =
        value === undefined ? null : value;
      setSelectedSkill({ ...selectedSkill, questions: updatedQuestions });
    }
  };

  // Open Dialog Function
  const handleDialogOpen = (skill: EmployeeSkillSetType) => {
    setSelectedSkill(skill);
    setOpenDialog(true);
  };

  // Close Dialog Function
  const handleDialogClose = () => {
    setOpenDialog(false);
  };

  // Save Function
  const handleSave = async () => {
    const { skill_id }: any = selectedSkill;
    const data2 = {
      responses: selectedSkill?.questions,
    };

    try {
      const response = await dispatch2(
        postData(
          `${editEmployeeSkillResponse}${selectedSkill?.skill_id}/`,
          data2
        )
      );
      handleShowToast("Skill Response Updated!", "success", true);
      setSelectedSkill(null);
      setCount(count + 1);
      handleDialogClose();
    } catch (error) {
      console.log(error);
    }
  };

  // Fetch Employee Skill Response Effect
  useEffect(() => {
    const fetchEmployeeSkillResponse = async () => {
      try {
        dispatch(setInPageLoading(true));
        const response = await dispatch2(
          fetchData(`${viewEmployeeSkillResponse}`)
        );
        setData(response as EmployeeSkillSetType[]);
      } catch (error) {
        console.log(error);
      } finally {
        dispatch(setInPageLoading(false));
      }
    };
    fetchEmployeeSkillResponse();
  }, [count]);

  // Loading Spinner Component
  if (isLoading) {
    return (
      <>
        <Loading isLoading={isLoading as boolean} />
      </>
    );
  }

  // Main Component
  return (
    <Box id="main-component">
      {data.length === 0 ? (
        <>
          <Box
            sx={{
              mt: 12,
              left: "50%",
              display: "flex",
              justifyContent: "center",
            }}
          >
            <NothingToDisplayComponent />
          </Box>
        </>
      ) : (
        <>
          <Box
            sx={{
              width: "98%",
              display: "flex",
              mx: 2,
              mt: 2,
            }}
          >
            <Box sx={{ width: "20%" }}></Box>
            <Box sx={{ width: "70%", display: "flex" }}>
              <Box
                sx={{
                  width: "90%",
                  mr: 2.7,
                  ml: 1,
                  display: "flex",
                  justifyContent: "center",
                  textAlign: "center",
                }}
              >
                {ratingLabel.map((data, index) => {
                  return (
                    <Box
                      sx={{
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        width: "20%",
                        // mr: 1.7,
                        // mt: -0.8,
                      }}
                    >
                      <Typography
                        key={index}
                        sx={{ mr: 0.3 }}
                        fontSize={13}
                        fontWeight={"bold"}
                      >
                        {data.label}
                      </Typography>
                    </Box>
                  );
                })}
              </Box>
              <Box sx={{ width: "10%" }}></Box>
            </Box>
            <Box sx={{ width: "10%" }}></Box>
          </Box>
          {data.map((emp, index) => {
            return (
              // Skill Card
              <Card
                key={index}
                id={`skill-card-${index}`}
                sx={{ m: 2, p: 2 }}
                elevation={4}
              >
                <Box
                  id={`skill-card-content-${index}`}
                  sx={{
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center",
                  }}
                >
                  {/* Skill Name */}
                  <Box id={`skill-name-${index}`} sx={{ width: "20%" }}>
                    <Typography
                      variant="h6"
                      color="primary"
                      fontWeight={"bold"}
                    >
                      {emp.skill_name}
                    </Typography>
                  </Box>
                  {/* Progress Bar */}
                  <Box
                    id={`progress-bar-${index}`}
                    sx={{
                      width: "70%",
                      mx: 2,
                      display: "flex",
                      alignItems: "center",
                    }}
                  >
                    <Box sx={{ width: "100%", position: "relative" }}>
                      <LinearProgress
                        variant="determinate"
                        value={parseFloat(emp.percentage)}
                        sx={{
                          flexGrow: 1,
                          mr: 2,
                          // backgroundColor: "#E0E0E0",
                          // "& .MuiLinearProgress-barColorPrimary": {
                          //   backgroundColor: color,
                          // },
                        }}
                      />
                      <div
                        className="skill-info-color"
                        style={{
                          position: "absolute",
                          top: "50%",
                          transform: `translate(-50%, -50%)`,
                          left: `${
                            parseFloat(emp.percentage) > 50
                              ? parseFloat(emp.percentage) - 1.5
                              : parseFloat(emp.percentage)
                          }%`,
                          width: "12px",
                          height: "12px",
                          borderRadius: "50%",
                        }}
                      />
                    </Box>
                    <Typography
                      sx={{ width: "10%" }}
                      variant="h6"
                      fontWeight={"bold"}
                      fontSize={14}
                    >
                      {parseFloat(emp.percentage).toFixed(2)}%
                    </Typography>
                  </Box>
                  {/* Completion Info */}
                  <Box
                    id={`completion-info-${index}`}
                    sx={{
                      display: "flex",
                      alignItems: "center",
                      width: "10%",
                      justifyContent: "right",
                    }}
                  >
                    <Typography
                      variant="h6"
                      color={
                        emp.questions.filter(
                          (que) => que.employee_skill_response !== null
                        ).length === emp.questions.length
                          ? "success"
                          : "error"
                      }
                    >
                      {
                        emp.questions.filter(
                          (que) => que.employee_skill_response !== null
                        ).length
                      }
                      /{emp.questions.length}
                    </Typography>
                    {/* Chevron Icon */}
                    <ChevronRightIcon
                      id={`chevron-icon-${index}`}
                      fontSize="medium"
                      sx={{ cursor: "pointer" }}
                      onClick={() => handleDialogOpen(emp)}
                    />
                  </Box>
                </Box>
                {/* Fullscreen Dialog */}
                <Dialog
                  id={`fullscreen-dialog-${index}`}
                  open={openDialog}
                  onClose={handleDialogClose}
                  fullScreen
                  sx={{ overflow: "auto" }}
                >
                  {selectedSkill && (
                    <>
                      {/* Dialog Title */}
                      <DialogTitle
                        id={`dialog-title-${index}`}
                        sx={{
                          display: "flex",
                          justifyContent: "center",
                          m: 2,
                          p: 2,
                        }}
                      >
                        <Typography
                          id={`dialog-title-typography-${index}`}
                          fontWeight={"bold"}
                          variant="h4"
                          color={"#007300"}
                        >
                          {`Questions for ${selectedSkill.skill_name}`}
                        </Typography>
                      </DialogTitle>
                      {/* Dialog Content */}
                      <DialogContent
                        id={`dialog-content-${index}`}
                        sx={{ ml: 5, mr: 5, mt: 2, p: 2 }}
                      >
                        {selectedSkill.questions.map(
                          (question, questionIndex) => (
                            // Question Card
                            <Card
                              key={question.skill_question_id}
                              id={`question-card-${index}-${questionIndex}`}
                              sx={{
                                mb: 2,
                                p: 2,
                                boxShadow: "0px 0px 10px rgba(0, 0, 0, 0.1)", // Add boxShadow here
                                border: "1px solid #ccc",
                              }}
                              elevation={5}
                            >
                              {/* Question Text */}
                              <Box
                                id={`question-text-${index}-${questionIndex}`}
                              >
                                <Typography fontWeight={"bold"} variant="h6">
                                  {questionIndex + 1 + ") "}
                                  {question.question}
                                </Typography>
                              </Box>
                              {/* Rating */}
                              <Box
                                id={`rating-box-${index}-${questionIndex}`}
                                sx={{
                                  display: "flex",
                                  alignItems: "center",
                                  m: 1.5,
                                  p: 1,
                                }}
                              >
                                <Rating
                                  name={`rating-${index}-${questionIndex}`}
                                  value={parseInt(
                                    question.employee_skill_response || "0"
                                  )}
                                  size="large"
                                  onChange={(event, value) => {
                                    handleRatingChange(
                                      value?.toString() as string,
                                      questionIndex
                                    );
                                  }}
                                  className="rating-color"
                                />
                              </Box>
                            </Card>
                          )
                        )}
                      </DialogContent>
                      {/* Dialog Actions */}
                      <DialogActions
                        id={`dialog-actions-${index}`}
                        sx={{ m: 2, p: 2 }}
                      >
                        <Button
                          id={`save-button-${index}`}
                          variant="contained"
                          onClick={() => {
                            handleSave();
                          }}
                        >
                          Save
                        </Button>
                        <Button
                          id={`close-button-${index}`}
                          variant="outlined"
                          onClick={handleDialogClose}
                        >
                          Close
                        </Button>
                      </DialogActions>
                    </>
                  )}
                </Dialog>
              </Card>
            );
          })}
        </>
      )}
    </Box>
  );
};

export default EmployeeSkillSet;
