import React, { useEffect, useState } from "react";

import {
  Autocomplete,
  Box,
  Button,
  CircularProgress,
  TextField,
  Typography,
} from "@mui/material";

import { ThunkDispatch } from "@reduxjs/toolkit";
import { useDispatch, useSelector } from "react-redux";

import { setInPageLoading, setIsLoading } from "../../store/LoadingSlice";
import { RootState } from "../../store/Store";
import {
  FetchDataFailureAction,
  FetchDataSuccessAction,
  fetchData,
  postData,
} from "../../store/actions";

import { addAttendanceRecord, viewUsers } from "../../Service/Apis";

import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import dayjs, { Dayjs } from "dayjs";
import NothingToDisplayComponent from "../../components/Data/NothingToShowComponent";
import NumberInput from "../../components/NumberInput/NumberInput";
import AttendanceExcelUpload from "../../components/Excel/AttendanceExcelUpload";

import { AttendanceType, initialAttendanceState } from "./Types/AttendaceTypes";
import {
  useCustomToast,
  ToastVariant,
} from "../../components/Methods/SnackBar";
interface Employeetype {
  id: string;
  employee_code: string;
  first_name: string;
  last_name: string;
  gender: string;
  roles: [];
  designation: string;
  date_of_birth: string;
  date_of_joining: string;
  date_of_confirmation: string;
  date_of_exit: string;
  email: string;
  password: string;
}

const AddAttendance = ({ onTabChange }: any) => {
  // State Hooks
  const [employeesData, setEmployeesData] = useState<Employeetype[]>([]);
  const [employee, setEmployee] = useState<Employeetype | null>(null);
  const [date, setDate] = React.useState<Dayjs | null>(
    dayjs(`${new Date().getFullYear()}-${new Date().getMonth() + 1}-01`)
  );
  const [inputValue, setInputValue] = React.useState<number | null>(null);
  const [attendance, setAttendance] = useState<AttendanceType>(
    initialAttendanceState
  );
  const [keyUpdate, setKeyUpdate] = useState(0);

  // Redux Hooks
  const dispatch = useDispatch();
  const dispatch2: ThunkDispatch<
    RootState,
    null,
    FetchDataSuccessAction | FetchDataFailureAction
  > = useDispatch();

  const showToast = useCustomToast();

  const isLoading = useSelector((state: any) => state.loading.inPageLoading);

  const handleShowToast = (
    message: string,
    variant: ToastVariant,
    isCloseable: boolean
  ) => {
    showToast(message, variant, isCloseable);
  };
  //Handle Employee Change Function
  const handleEmployeeChange = (e: any, values: any) => {
    setEmployee(values);
  };

  const handleNumberInputChange = (
    e: any,
    newValue: number | null,
    propertyName: keyof AttendanceType
  ) => {
    const roundedValue = newValue ? parseFloat(newValue?.toFixed(1)) : 0;
    setAttendance({ ...attendance, [propertyName]: roundedValue });
    setKeyUpdate(keyUpdate + 1);
    setInputValue(newValue);
  };

  const handleSubmit = async (e: any) => {
    e.preventDefault();
    const data = {
      user_id: employee ? employee.id : "",
      date: attendance ? attendance.date : "",
      paid_leaves: attendance ? attendance.paid_leaves : "",
      sick_leaves: attendance ? attendance.sick_leaves : "",
      leave_without_pay: attendance ? attendance.leave_without_pay : "",
      present_days: attendance ? attendance.present_days : "",
      regularized_days: attendance ? attendance.regularized_days : "",
    };
    console.log(data);

    const isAllFieldsFilled: boolean = Object.values(data).every(
      (value: string) => value !== ""
    );

    if (!isAllFieldsFilled) {
      handleShowToast("Please fill all fields!", "error", true);
      return;
    }

    try {
      dispatch(setIsLoading(true));
      const response = await dispatch2(
        postData(`${addAttendanceRecord}`, data)
      );
      handleShowToast(
        "Attendance record added successfully !",
        "success",
        true
      );
      onTabChange(0);
    } catch (error) {
      console.log(error);
    } finally {
      dispatch(setIsLoading(false));
    }
  };

  useEffect(() => {
    // API Call get the User Details.
    const fetchUserDetails = async () => {
      try {
        dispatch(setIsLoading(true));
        const response = await dispatch2(fetchData(`${viewUsers}`));
        setEmployeesData(response as Employeetype[]);
      } catch (error) {
        console.log(error);
      } finally {
        dispatch(setIsLoading(false));
      }
    };

    fetchUserDetails();
  }, []);

  // useEffect(() => {
  //   // API call to get the employee skill responses.
  //   const fetchAttendance = async () => {
  //     try {
  //       dispatch(setInPageLoading(true));
  //       // const response = await dispatch2(
  //       //   fetchData(`${viewEmployeeSkillResponse}?user_id=${employee?.id}`)
  //       // );
  //     } catch (error) {
  //       console.log(error);
  //     } finally {
  //       dispatch(setInPageLoading(false));
  //     }
  //   };

  //   // Check whether the employee is selected before making the API call.
  //   if (employee) {
  //     fetchAttendance();
  //   }
  // }, [employee]);

  // Loading Spinner Component
  if (isLoading) {
    return (
      <>
        {/* Fullscreen Overlay */}
        <Box
          id="fullscreen-overlay"
          style={{
            position: "fixed",
            top: 0,
            left: 0,
            width: "100%",
            height: "100%",
            backdropFilter: "blur(1.5px)", // Adjust the blur intensity as needed
            zIndex: 9999,
          }}
        />
        {/* Loader Container */}
        <Box
          id="loader-container"
          sx={{
            position: "fixed", // Use fixed positioning for the loader container
            top: 0,
            left: 0,
            width: "100%",
            height: "100%",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            zIndex: 10000,
          }}
        >
          {/* Loader */}
          <Box
            id="loader"
            sx={{
              position: "relative", // Use relative positioning for the actual loader
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            {isLoading && <CircularProgress size={50} />}
          </Box>
        </Box>
      </>
    );
  }

  // Main Component

  return (
    <>
      <Box
        sx={{
          display: "flex",
          justifyContent: "center",
        }}
      >
        <Box>
          <Box sx={{ width: "100%" }}>
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                m: 1,
                p: 1,
              }}
            >
              <Typography sx={{ mx: 1, width: "15rem" }} fontWeight={"bold"}>
                Employee Name :
              </Typography>
              <Autocomplete
                size="small"
                isOptionEqualToValue={(option, value) => option.id === value.id}
                getOptionLabel={(option) =>
                  option.first_name + " " + option.last_name
                }
                value={employee}
                onChange={handleEmployeeChange}
                id="employee-autocomplete"
                options={employeesData}
                sx={{ width: "20rem" }}
                renderInput={(params) => <TextField {...params} />}
              />
            </Box>
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                m: 1,
                p: 1,
              }}
            >
              <Typography sx={{ mx: 1, width: "15rem" }} fontWeight={"bold"}>
                Employee Code :
              </Typography>
              <TextField
                value={employee ? employee.employee_code : ""}
                size="small"
                sx={{ width: "20rem" }}
                InputProps={{
                  readOnly: true,
                }}
              />
            </Box>
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                m: 1,
                p: 1,
              }}
            >
              <Typography sx={{ mx: 1, width: "15rem" }} fontWeight={"bold"}>
                Month & Year :
              </Typography>
              <LocalizationProvider dateAdapter={AdapterDayjs}>
                <div onKeyDown={(e) => e.preventDefault()}>
                  <DatePicker
                    sx={{
                      ".MuiInputBase-root .MuiInputBase-input": {
                        /* Your styles here */
                        height: "0.55rem",
                      },
                    }}
                    className="monthandyearpicker"
                    shouldDisableDate={(date) => {
                      const currentDate = dayjs();
                      // Check if the date is in the current month and year
                      const isCurrentMonthYear =
                        date.isSame(currentDate, "month") &&
                        date.isSame(currentDate, "year");
                      // Disable the date if it's in the current month and year
                      return isCurrentMonthYear;
                    }}
                    disableFuture
                    views={["month", "year"]}
                    value={dayjs(attendance?.date)}
                    onChange={(newValue) =>
                      setAttendance((prev) => ({
                        ...prev,
                        date: newValue
                          ? newValue.format("YYYY-MM-DD").toString()
                          : "",
                      }))
                    }
                  />
                </div>
              </LocalizationProvider>
            </Box>
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                m: 1,
                p: 1,
              }}
            >
              <Typography sx={{ mx: 1, width: "15rem" }} fontWeight={"bold"}>
                Paid Leaves :
              </Typography>
              <NumberInput
                key={keyUpdate}
                name="paid_leaves"
                min={0}
                aria-label="Demo number input"
                placeholder="Type a number…"
                value={attendance?.paid_leaves}
                step={0.5}
                max={100}
                onChange={(e: any, val: number) => {
                  e.preventDefault();
                  handleNumberInputChange(e, val, "paid_leaves");
                }}
              />
            </Box>
            <Box sx={{ display: "flex", alignItems: "center", m: 1, p: 1 }}>
              <Typography sx={{ mx: 1, width: "15rem" }} fontWeight={"bold"}>
                Sick Leaves :
              </Typography>
              <NumberInput
                key={keyUpdate}
                min={0}
                aria-label="Demo number input"
                placeholder="Type a number…"
                value={attendance?.sick_leaves}
                step={0.5}
                max={100}
                onChange={(e: any, val: number) => {
                  e.preventDefault();
                  handleNumberInputChange(e, val, "sick_leaves");
                }}
              />
            </Box>
            <Box sx={{ display: "flex", alignItems: "center", m: 1, p: 1 }}>
              <Typography sx={{ mx: 1, width: "15rem" }} fontWeight={"bold"}>
                Leave Without Pay :
              </Typography>
              <NumberInput
                key={keyUpdate}
                min={0}
                aria-label="Demo number input"
                placeholder="Type a number…"
                value={attendance?.leave_without_pay}
                step={0.5}
                max={100}
                onChange={(e: any, val: number) => {
                  e.preventDefault();
                  handleNumberInputChange(e, val, "leave_without_pay");
                }}
              />
            </Box>
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                m: 1,
                p: 1,
              }}
            >
              <Typography sx={{ mx: 1, width: "15rem" }} fontWeight={"bold"}>
                Present Days :
              </Typography>
              <NumberInput
                key={keyUpdate}
                min={0}
                aria-label="Demo number input"
                placeholder="Type a number…"
                value={attendance?.present_days}
                step={0.5}
                max={100}
                onChange={(e: any, val: number) => {
                  e.preventDefault();
                  handleNumberInputChange(e, val, "present_days");
                }}
              />
            </Box>
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                m: 1,
                p: 1,
              }}
            >
              <Typography sx={{ mx: 1, width: "15rem" }} fontWeight={"bold"}>
                Regularization Requests :
              </Typography>
              <NumberInput
                key={keyUpdate}
                min={0}
                aria-label="Demo number input"
                placeholder="Type a number…"
                value={attendance?.regularized_days}
                max={100}
                onChange={(e: any, val: number) => {
                  e.preventDefault();
                  handleNumberInputChange(e, val, "regularized_days");
                }}
              />
            </Box>
          </Box>
          <Box>
            <AttendanceExcelUpload />
          </Box>
          <Box sx={{ display: "flex", justifyContent: "right" }}>
            <Button variant="contained" size="small" onClick={handleSubmit}>
              Add
            </Button>
          </Box>
          {/* </Box>
          </Box> */}
        </Box>
      </Box>
    </>
  );
};

export default AddAttendance;
