import {
  Box,
  Paper,
  Rating
} from "@mui/material";
import { useEffect, useMemo, useState } from "react";


import { ThunkDispatch } from "@reduxjs/toolkit";
import { useDispatch, useSelector } from "react-redux";

import { setInPageLoading, setIsLoading } from "../../store/LoadingSlice";
import { RootState } from "../../store/Store";
import {
  FetchDataFailureAction,
  FetchDataSuccessAction,
  fetchData,
} from "../../store/actions";

import {
  allEmployeeSkiillResponses,
  viewUsers
} from "../../Service/Apis";

import {
  DataGrid,
  GridColDef,
  GridToolbar
} from "@mui/x-data-grid";
import CustomNoRowsOverlay from "../../components/Data/NoRowsOverlayComponent";
import NothingToDisplayComponent from "../../components/Data/NothingToShowComponent";
import Loading from "../../components/Loading/Loading";
import { AllEmployeeResponsesType, EmployeeSkillSetType } from "./Types/SkillTypes";

interface Employeetype {
  id: string;
  employee_code: string;
  first_name: string;
  last_name: string;
  gender: string;
  roles: [];
  designation: string;
  date_of_birth: string;
  date_of_joining: string;
  date_of_confirmation: string;
  date_of_exit: string;
  email: string;
  password: string;
}

const DownloadSkillsReview = () => {
  const [employeesData, setEmployeesData] = useState<Employeetype[]>([]);
  const [employee, setEmployee] = useState<Employeetype | null>(null);

  const [data, setData] = useState<AllEmployeeResponsesType[]>([]);
  const [selectedSkill, setSelectedSkill] =
    useState<EmployeeSkillSetType | null>(null);
  const [openDialog, setOpenDialog] = useState(false);

  // Redux Hooks
  const dispatch = useDispatch();
  const dispatch2: ThunkDispatch<
    RootState,
    null,
    FetchDataSuccessAction | FetchDataFailureAction
  > = useDispatch();

  const isLoading = useSelector((state: any) => state.loading.inPageLoading);

  const handleEmployeeChange = (e: any, values: any) => {
    setEmployee(values);
  };

  const rows = useMemo(() => {
    return data.map((question, index) => ({
      ...question,
      questionIndex: index + 1,
    }));
  }, [data]);

  const columns: GridColDef[] = [
    {
      field: "questionIndex",
      headerName: "Sr. No",
      renderHeader: () => (
        <strong
          className="custom-header"
          style={{ fontSize: 17, textAlign: "center" }}
        >
          Sr No.
        </strong>
      ),
      //   flex: 1,
      width: 80,
      align: "center",
      headerAlign: "center",
      headerClassName: "header-background",
    },
    {
      field: "employee_name",
      headerName: "Employee Name",
      renderHeader: () => (
        <strong
          className="custom-header"
          style={{ paddingRight: 2, fontSize: 17, textAlign: "center" }}
        >
          Employee Name
        </strong>
      ),
      //   flex: 1,
      width: 160,
      align: "center",
      headerAlign: "center",
      headerClassName: "header-background",
    },
    {
      field: "skill_name",
      headerName: "Skill Name",
      renderHeader: () => (
        <strong
          className="custom-header"
          style={{ fontSize: 17, textAlign: "center" }}
        >
          Skill Name
        </strong>
      ),
      width: 110,
      //   flex: 1,
      align: "center",
      headerAlign: "center",
      headerClassName: "header-background",
    },
    {
      field: "question",
      headerName: " Question",
      width: 510,
      // flex: 1,
      align: "left",
      headerAlign: "center",
      headerClassName: "header-background",
      renderHeader: () => (
        <strong
          className="custom-header"
          style={{ fontSize: 17, textAlign: "center" }}
        >
          Question
        </strong>
      ),
    },
    {
      field: "employee_skill_response",
      headerName: " Employee Response",
      width: 185,
      //   flex: 1,
      align: "center",
      headerAlign: "center",
      headerClassName: "header-background",
      renderHeader: () => (
        <strong
          className="custom-header"
          style={{ fontSize: 17, textAlign: "center" }}
        >
          Employee Response
        </strong>
      ),
      renderCell: (params: any) => (
        <Rating
          readOnly
          className="rating-color"
          value={parseInt(
            params.row.employee_skill_response
              ? params.row.employee_skill_response
              : "0"
          )}
        />
      ),
    },
  ];

  useEffect(() => {
    // API Call get the User Details.
    const fetchUserDetails = async () => {
      try {
        dispatch(setIsLoading(true));
        const response = await dispatch2(fetchData(`${viewUsers}`));
        setEmployeesData(response as Employeetype[]);
      } catch (error) {
        console.log(error);
      } finally {
        dispatch(setIsLoading(false));
      }
    };

    fetchUserDetails();
  }, []);

  useEffect(() => {
    // API call to get the employee skill responses.
    const fetchEmployeesSkillResponse = async () => {
      try {
        dispatch(setInPageLoading(true));
        const response = await dispatch2(
          fetchData(`${allEmployeeSkiillResponses}`)
        );
        setData(response as AllEmployeeResponsesType[]);
      } catch (error) {
        console.log(error);
      } finally {
        dispatch(setInPageLoading(false));
      }
    };

    // Check whether the employee is selected before making the API call.
    // if (employee) {
    fetchEmployeesSkillResponse();
    // }
  }, []);

  // Loading Spinner Component
  if (isLoading) {
    return (
      <>
        <Loading isLoading={isLoading as boolean} />
      </>
    );
  }

  return (
    <>
      <Box
        sx={{
          display: "flex",
          justifyContent: "center",
          width: "85vw",
        }}
      >
        <Box>
          <Box sx={{ mt: 2, pt: 2 }}>
            {data.length === 0 ? (
              <>
                <Box
                  sx={{
                    mt: 12,
                    left: "50%",
                    // transform: "translate(-50%, -50%)",
                    display: "flex",
                    justifyContent: "center",
                  }}
                >
                  <NothingToDisplayComponent />
                </Box>
              </>
            ) : (
              <>
                {/* {data.map((emp, index) => {
                  return ( */}
                <Box sx={{ m: 1, p: 1, width: "100%" }}>
                  <Paper sx={{ width: "fit-content" }}>
                    <DataGrid
                      autoHeight
                      initialState={{
                        pagination: {
                          paginationModel: {
                            pageSize: 15,
                          },
                        },
                      }}
                      disableColumnMenu
                      sx={{
                        width: "100%",
                        height: "100%",
                        "--DataGrid-overlayHeight": "50vh",
                      }}
                      pagination
                      pageSizeOptions={[5, 10, 15, 20]}
                      rows={rows}
                      columns={columns}
                      //   disableColumnFilter
                      disableColumnSelector
                      //   disableDensitySelector
                      slots={{
                        toolbar: GridToolbar,
                        noRowsOverlay: CustomNoRowsOverlay,
                        noResultsOverlay: CustomNoRowsOverlay,
                      }}
                      slotProps={{
                        toolbar: {
                          //   csvOptions: { disableToolbarButton: true },
                          showQuickFilter: true,
                          //   printOptions: { disableToolbarButton: true },
                          style: { margin: "0.5rem" },
                        },
                      }}
                      getRowId={(row) => row.questionIndex}
                    />
                  </Paper>
                </Box>
                {/* ); */}
              </>
            )}
          </Box>
          {/* )} */}
        </Box>
      </Box>
    </>
  );
};

export default DownloadSkillsReview;
