import { Tabs, Tab, Box } from "@mui/material";
import React from "react";
import { CustomTabPanel, a11yProps } from "../../components/TabPanel/TabPanel";
import ReviewAttendance from "./ReviewAttendance";
import AddAttendance from "./AddAttendance";

const ViewAttendaceRecords = () => {
  const [value, setValue] = React.useState(0);
  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setValue(newValue);
  };
  const roleOfEmployee = localStorage.getItem("role");

  const handleTabChange = (value: number) => {
    setValue(value);
  };

  return (
    <Box
      sx={{
        display: "flex",
        height: "100%", // Adjust the height as needed
        width: "97vw",
      }}
    >
      <Box
        sx={{
          minWidth: 120, // Set the width for the left sidebar
          bgcolor: "background.paper",
        }}
      >
        <Tabs
          centered
          value={value}
          onChange={handleChange}
          orientation="vertical"
          textColor="inherit"
          indicatorColor="secondary"
          aria-label="secondary tabs example"
          sx={{
            "& .MuiTabs-root": {
              color: "green",
              textDecorationColor: "green",
            },
            "& .MuiTabs-indicator": {
              backgroundColor: "green",
              textDecorationColor: "green",
            },
          }}
        >
          <Tab {...a11yProps(0)} label={"Review Attendance"} />
          {roleOfEmployee?.includes("HR") && (
            <Tab {...a11yProps(1)} label={"Add Attendance"} />
          )}
        </Tabs>
      </Box>
      <CustomTabPanel value={value} index={0}>
        <Box sx={{ width: "85vw" }}>
          <ReviewAttendance />
        </Box>
      </CustomTabPanel>
      <CustomTabPanel value={value} index={1}>
        <Box sx={{ width: "85vw" }}>
          <AddAttendance onTabChange={handleTabChange} />
        </Box>
      </CustomTabPanel>
    </Box>
  );
};

export default ViewAttendaceRecords;
