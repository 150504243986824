import Box from "@mui/material/Box";
import Tab from "@mui/material/Tab";
import Tabs from "@mui/material/Tabs";
import * as React from "react";
import Footer from "../../components/Page Component/Footer";
import Navbar from "../../components/Page Component/Navbar";
import { CustomTabPanel, a11yProps } from "../../components/TabPanel/TabPanel";
import {
  ToastVariant,
  useCustomToast,
} from "../../components/Methods/SnackBar";
import Profile from "./Profile";
import ViewAllSkills from "../Skill Set/ViewAllSkills";
import EmployeeSkillSet from "../Skill Set/EmployeeSkillSet";
import ReviewEmployeesSkills from "../Skill Set/ReviewEmployeesSkills";
import ViewAttendaceRecords from "../Attendance/ViewAttendaceRecords";
import ReviewAttendance from "../Attendance/ReviewAttendance";
import EmployeeAttendance from "../Attendance/EmployeeAttendance";
import { useMemo } from "react";

const rolesToCheck = ["HR", "Manager", "Admin"];

const ProfileSectionLayout = () => {
  const [value, setValue] = React.useState(0);

  const showToast = useCustomToast();
  const handleShowToast = (
    message: string,
    variant: ToastVariant,
    isCloseable: boolean
  ) => {
    showToast(message, variant, isCloseable);
  };

  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    const tempElement = document.createElement("div");
    tempElement.innerHTML = event.currentTarget.innerHTML;
    const tabLabel = tempElement.innerText;

    // Clean up the temporary element
    tempElement.remove();

    setValue(newValue);
    handleShowToast(`Switched to ${tabLabel}`, "info", true);
  };

  const roleOfEmployee = localStorage.getItem("role");
  const HR = useMemo(() => {
    return [
      {
        label: "Profile",
        component: <Profile />,
      },
      {
        label: "Your Skills",
        component: <EmployeeSkillSet />,
      },
      { label: "All Skills", component: <ViewAllSkills /> },
      { label: "Your Attendance", component: <EmployeeAttendance /> },
      {
        label: "All Attendance",
        component: roleOfEmployee?.includes("HR") ? (
          <ViewAttendaceRecords />
        ) : (
          <ReviewAttendance />
        ),
      },
    ];
  }, [roleOfEmployee]);

  const Employee = useMemo(() => {
    return [
      {
        label: "Profile",
        component: <Profile />,
      },
      {
        label: "Your Skills",
        component: <EmployeeSkillSet />,
      },
      { label: "Your Attendance", component: <EmployeeAttendance /> },
    ];
  }, [roleOfEmployee]);

  return (
    <Box sx={{ width: "98vw", mt: 10, mb: 10 }}>
      <Box>
        <Navbar />
      </Box>
      <Box>
        <Tabs
          centered
          value={value}
          onChange={handleChange}
          textColor="inherit"
          indicatorColor="secondary"
          aria-label="secondary tabs example"
          sx={{
            "& .MuiTabs-root": {
              color: "green",
              textDecorationColor: "green",
            },
            "& .MuiTabs-indicator": {
              backgroundColor: "green", // This sets the indicator color to green
              textDecorationColor: "green",
            },
          }}
        >
          {rolesToCheck.some((value) => roleOfEmployee?.includes(value))
            ? HR.map((comp, index) => {
                return <Tab {...a11yProps(index)} label={comp.label} />;
              })
            : Employee.map((comp, index) => {
                return <Tab {...a11yProps(index)} label={comp.label} />;
              })}
        </Tabs>
        {rolesToCheck.some((value) => roleOfEmployee?.includes(value))
          ? HR.map((comp, index) => {
              return (
                <CustomTabPanel value={value} index={index}>
                  {comp.component}
                </CustomTabPanel>
              );
            })
          : Employee.map((comp, index) => {
              return (
                <CustomTabPanel value={value} index={index}>
                  {comp.component}
                </CustomTabPanel>
              );
            })}
      </Box>
      <Box>
        <Footer />
      </Box>
    </Box>
  );
};

export default ProfileSectionLayout;
