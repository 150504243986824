import { useEffect, useState } from "react";

import { Box } from "@mui/material";

import { ThunkDispatch } from "@reduxjs/toolkit";
import { useDispatch } from "react-redux";
import { rankEmployees, rankEmployeesHR } from "../../Service/Apis";
import NothingToDisplayImage from "../../asset/NothingToDisplayImage.png";
import EmployeeTable from "../../components/Employee/EmployeeTableRankingHr";
import Footer from "../../components/Page Component/Footer";
import Navbar from "../../components/Page Component/Navbar";
import { RootState } from "../../store/Store";
import {
  DataPayload,
  FetchDataFailureAction,
  FetchDataSuccessAction,
  fetchData,
} from "../../store/actions";
import { Project } from "./Types/RankingTypes";
import NothingToDisplayComponent from "../../components/Data/NothingToShowComponent";

const HrAndAdminRanking = () => {
  const [data, setData] = useState<Project[]>([]);

  const dispatch2: ThunkDispatch<
    RootState,
    null,
    FetchDataSuccessAction | FetchDataFailureAction
  > = useDispatch();
  const token = localStorage.getItem("token");

  useEffect(() => {
    fetchmyData();
  }, []);

  // Getting the table data and the ranks
  const fetchmyData = async () => {
    try {
      const response: DataPayload = await dispatch2(
        fetchData(`${rankEmployeesHR}`)
      );
      setData(response as Project[]);
    } catch (error) {
      console.log(error);
    }
  };
  //displaying the no Data component if there is no data present
  return (
    <>
      <Box>
        <Navbar />
      </Box>
      <Box sx={{ mt: 12, mb: 10 }}>
        {data.length === 0 ? (
          <Box
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              height: "100%",
            }}
          >
            <NothingToDisplayComponent />
          </Box>
        ) : (
          <EmployeeTable data={data} />
        )}
        {/* </Card> */}
      </Box>
      <Box>
        <Footer />
      </Box>
    </>
  );
};

export default HrAndAdminRanking;
