import {
  Box,
  Button,
  Card,
  Container,
  Grid,
  Paper,
  Typography,
} from "@mui/material";
import { useEffect, useMemo, useState } from "react";

import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import NothingToDisplayImage from "../../asset/NothingToDisplayImage.png";
import {
  ShowParticularForms,
  ViewUser,
  showPreviousFormsEmployee,
} from "../../Service/Apis";
import Footer from "../../components/Page Component/Footer";
import Navbar from "../../components/Page Component/Navbar";
import useAxiosInterceptor from "../../components/layout/AxiosInstance";
import { setFormTeamId, setId, setRole } from "../../store/FormSlice";
import { setIsLoading } from "../../store/LoadingSlice";
import { setFields } from "../../store/Personslice";
import {
  FetchDataFailureAction,
  FetchDataSuccessAction,
  fetchData,
  postData,
  putData,
} from "../../store/actions";
import { ThunkDispatch } from "@reduxjs/toolkit";
import { RootState } from "../../store/Store";
import { DataGrid, GridColDef, GridToolbar } from "@mui/x-data-grid";
import CustomNoRowsOverlay from "../../components/Data/NoRowsOverlayComponent";

interface FormType {
  project_name: string;
  team_id: string;
  forms: [
    {
      employee_deadline: string;
      final_submit_done: boolean;
      form_id: string;
      form_team_id: string;
      form_name: string;
    }
  ];
}

const EmployeePreviousResponses = () => {
  const [form, setForm] = useState<FormType[]>([
    {
      project_name: "",
      team_id: "",
      forms: [
        {
          employee_deadline: "",
          final_submit_done: false,
          form_id: "",
          form_team_id: "",
          form_name: "",
        },
      ],
    },
  ]); //storing the blocks of responses
  const dispatch = useDispatch();
  const dispatch2: ThunkDispatch<
    RootState,
    null,
    FetchDataSuccessAction | FetchDataFailureAction
  > = useDispatch();

  const navigate = useNavigate();
  const newToken = useSelector((state: any) => state.auth.token);
  const isLoading = useSelector((state: any) => state.loading.isLoading);
  const emailId = localStorage.getItem("email");

  const handleViewResponse = (e: any, formId: string, formTeamId: string) => {
    e.preventDefault();
    dispatch(setFormTeamId(formTeamId));
    localStorage.setItem("formTeamId", formTeamId);
    dispatch(setId(formId));
    localStorage.setItem("formid", formId);
    navigate("/employeepreviousresponse");
  };

  const flattenedForms = form.flatMap((formItem) =>
    formItem.forms.map((form) => ({
      project_name: formItem.project_name,
      team_id: formItem.team_id,
      ...form,
    }))
  );

  const rows = flattenedForms.map((form, index) => ({
    ...form,
    index: index,
    sr_no: index + 1,
  }));

  const columns: GridColDef[] = useMemo(
    () => [
      {
        headerName: "Sr No.",
        field: "sr_no",
        align: "center",
        headerAlign: "center",
        headerClassName: "header-background",
        width: 125,
        renderHeader: () => (
          <strong className="custom-header" style={{ fontSize: 15 }}>
            Sr No.
          </strong>
        ),
      },
      {
        headerName: "Project Name",
        field: "project_name",
        align: "center",
        headerAlign: "center",
        headerClassName: "header-background",
        width: 165,
        renderHeader: () => (
          <strong className="custom-header" style={{ fontSize: 15 }}>
            Project Name
          </strong>
        ),
      },
      {
        headerName: "Form Name",
        field: "form_name",
        align: "center",
        headerAlign: "center",
        headerClassName: "header-background",
        width: 165,
        renderHeader: () => (
          <strong className="custom-header" style={{ fontSize: 15 }}>
            Form Name
          </strong>
        ),
      },
      {
        headerName: "Employee Deadline",
        field: "employee_deadline",
        align: "center",
        headerAlign: "center",
        headerClassName: "header-background",
        width: 195,
        renderHeader: () => (
          <strong className="custom-header" style={{ fontSize: 15 }}>
            Employee Deadline
          </strong>
        ),
      },
      {
        headerName: "Options",
        field: "options",
        align: "center",
        headerAlign: "center",
        headerClassName: "header-background",
        width: 175,
        renderHeader: () => (
          <strong className="custom-header" style={{ fontSize: 15 }}>
            Options
          </strong>
        ),
        renderCell: (params) => (
          <>
            <Button
              variant="contained"
              size="small"
              
              onClick={(e) => {
                handleViewResponse(
                  e,
                  params.row.form_id,
                  params.row.form_team_id
                );
              }}
            >
              View Response
            </Button>
          </>
        ),
      },
    ],
    [form, rows]
  );

  //setting the person
  useEffect(() => {
    const fetchUserData = async () => {
      try {
        const response = await dispatch2(fetchData(`${ViewUser}${emailId}`));
        const person = response as any;
        dispatch(setFields(person));
        if (person && person.roles) {
          localStorage.setItem("role", JSON.stringify(person.roles));
          dispatch(setRole(person.role[0]));
        }
      } catch (error: any) {
        console.log(error);
      }
    };
    if (emailId && newToken) {
      dispatch(setIsLoading(true));
      fetchUserData();
      dispatch(setIsLoading(false));
    }
  }, [emailId, newToken]);

  //gets the previous responses for that specific employees
  useEffect(() => {
    dispatch(setIsLoading(true));
    const fetchPreviousForms = async () => {
      try {
        const response = await dispatch2(fetchData(showPreviousFormsEmployee));
        setForm(response as FormType[]);

        dispatch(setIsLoading(false));
      } catch (error) {
        console.log(error);
        dispatch(setIsLoading(false));
      }
    };
    fetchPreviousForms();
  }, []);

  return (
    <>
      <Container sx={{ display: "flex", justifyContent: "center" }}>
        <Paper sx={{ m: 2 }} style={{ width: "fit-content" }}>
          <DataGrid
            autoHeight
            loading={isLoading}
            getRowId={(row) => row.form_team_id}
            disableColumnMenu
            sx={{
              width: "100%",
              height: "100%",
              "--DataGrid-overlayHeight": "50vh",
            }}
            initialState={{
              pagination: {
                paginationModel: {
                  pageSize: 10,
                },
              },
            }}
            pagination
            pageSizeOptions={[5, 10, 15]}
            rows={rows}
            columns={columns}
            disableColumnSelector
            disableDensitySelector
            slots={{
              toolbar: GridToolbar,
              noResultsOverlay: CustomNoRowsOverlay,
              noRowsOverlay: CustomNoRowsOverlay,
            }}
            slotProps={{
              toolbar: {
                csvOptions: { disableToolbarButton: true },
                showQuickFilter: true,
                printOptions: { disableToolbarButton: true },
                style: { margin: "0.5rem" },
              },
            }}
          />
        </Paper>
      </Container>
    </>
  );
};

export default EmployeePreviousResponses;
