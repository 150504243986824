import * as React from "react";
import {
  DataGrid,
  GridColDef,
  GridRowModel,
  GridToolbar,
} from "@mui/x-data-grid";
import {
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  Paper,
  Typography,
  TextField,
  Box,
  Fade,
} from "@mui/material";
import axios from "axios";
import { useState } from "react";
import { v4 as uuidv4 } from "uuid";
import { rankEmployeesHR, tableManager } from "../../Service/Apis";
import { ToastVariant, useCustomToast } from "../Methods/SnackBar";

import { ThemeProvider, createTheme, makeStyles } from "@mui/material/styles";
import CustomNoRowsOverlay from "../Data/NoRowsOverlayComponent";

interface Employee {
  employee_id: string;
  employee_name: string;
  rank: number;
  manager_average_rating: string;
}

interface Form {
  form_id: string;
  form_name: string;
  form_description: string;
  employee_rank_list: Employee[];
}

interface Project {
  team_id: string;
  project_name: string;
  forms_list: Form[];
}

interface TableProps {
  data: Project[];
}
interface Employee1 {
  employee_id: string;
  employee_name: string;
  manager_average_rating: string;
  rank: number;
}

interface Form1 {
  form_id: string;
  form_name: string;
  form_description: string;
  employee_rank_list: Employee1[];
}

interface Project1 {
  team_id: string;
  project_name: string;
  forms_list: Form1[];
}

type ApiResponse = Project1[];

const Table: React.FC<TableProps> = ({ data }) => {
  const [selectedTeamId, setSelectedTeamId] = React.useState<string | null>(
    null
  );
  const [selectedFormId, setSelectedFormId] = React.useState<string | null>(
    null
  );

  const [row, setRow] = useState<ApiResponse>([]);

  const [searchTerm, setSearchTerm] = useState<string>("");
  const showToast = useCustomToast();

  const handleTeamSelection = (event: any) => {
    setSelectedTeamId(event.target.value as string);
    //	setSelectedFormId(null);
    setRow([]);
  };

  const handleFormSelection = (event: any) => {
    setSelectedFormId(event.target.value as string);
  };

  const handleShowToast = (
    message: string,
    variant: ToastVariant,
    isCloseable: boolean
  ) => {
    showToast(message, variant, isCloseable);
  };

  const handleSearchChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSearchTerm(event.target.value);
  };

  const columns: GridColDef[] = [
    {
      field: "employee_name",
      headerName: "Employee Name",
      width: 200,
      align: "center",
      headerAlign: "center",
      headerClassName: "header-background",
      renderHeader: () => (
        <strong className="custom-header" style={{ fontSize: 15 }}>
          Employee Name
        </strong>
      ),
    },
    {
      field: "manager_average_rating",
      headerName: "Manager Avg Rating",
      width: 200,
      align: "center",
      headerAlign: "center",
      headerClassName: "header-background",
      renderHeader: () => (
        <strong className="custom-header" style={{ fontSize: 15 }}>
          Manager Avg Rating
        </strong>
      ),
    },
    {
      field: "rank",
      headerName: "Rank",
      width: 150,
      align: "center",
      headerAlign: "center",
      headerClassName: "header-background",
      renderHeader: () => (
        <strong className="custom-header" style={{ fontSize: 15 }}>
          Rank
        </strong>
      ),
    },
  ];

  const filteredRows: GridRowModel[] = row.flatMap((team) =>
    team.forms_list.flatMap((formData) =>
      formData.employee_rank_list.map((employee) => ({
        id: employee.employee_id,
        employee_name: employee.employee_name,
        manager_average_rating: employee.manager_average_rating,
        rank: employee.rank,
      }))
    )
  );

  const rows = searchTerm
    ? filteredRows.filter((row) =>
        Object.values(row).some((value) =>
          value.toString().toLowerCase().includes(searchTerm.toLowerCase())
        )
      )
    : filteredRows;

  const uniqueRows = Array.from(
    new Map(rows.map((row) => [row.id, row])).values()
  );

  const fetchDataFromApi = React.useCallback(async () => {
    if (selectedFormId) {
      try {
        const token = localStorage.getItem("token");
        const response = await axios.get(
          `${rankEmployeesHR}${selectedTeamId}&form_id=${selectedFormId}`,
          {
            headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${token}`,
            },
          }
        );
        setRow(response.data);
        //console.log(response.data);
      } catch (error) {
        console.log("Error fetching data:", error);
        handleShowToast("Error fetching data", "error", true);
      }
    } else {
      // Clear the rows if no form is selected
      setRow([]);
    }
  }, [selectedFormId, selectedTeamId]);

  // Use useEffect to trigger the API call when selectedFormId changes
  React.useEffect(() => {
    fetchDataFromApi();
  }, [fetchDataFromApi]);

  return (
    <>
      <Box sx={{ display: "flex", justifyContent: "center" }}>
        <Box sx={{ mb: 20, width: "75vw" }}>
          <Box sx={{ m: 2, p: 2, backgroundColor: "white" }}>
            <FormControl
              fullWidth
              variant="outlined"
              style={{ marginBottom: "16px" }}
            >
              <Box
                sx={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  m: 1,
                  p: 1,
                }}
              >
                <Typography sx={{ width: "10rem" }}>Team : </Typography>
                <Select
                  size="small"
                  value={selectedTeamId || ""}
                  sx={{ width: "50%" }}
                  onChange={(e) => handleTeamSelection(e)}
                >
                  <MenuItem value="">
                    <em>Select a Team</em>
                  </MenuItem>
                  {data.map((project) => (
                    <MenuItem key={project.team_id} value={project.team_id}>
                      {project.project_name}
                    </MenuItem>
                  ))}
                </Select>
              </Box>
            </FormControl>
            {selectedTeamId && (
              <FormControl
                fullWidth
                variant="outlined"
                // style={{ marginBottom: "16px" }}
              >
                <Box
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    m: 1,
                    p: 1,
                  }}
                >
                  <Typography sx={{ width: "10rem" }}>Form :</Typography>
                  <Select
                    size="small"
                    value={selectedFormId || ""}
                    sx={{ width: "50%" }}
                    onChange={(e) => handleFormSelection(e)}
                  >
                    <MenuItem value="">
                      <em>Select a Form</em>
                    </MenuItem>
                    {data
                      .find((project) => project.team_id === selectedTeamId)
                      ?.forms_list.map((form) => (
                        <MenuItem key={form.form_id} value={form.form_id}>
                          {form.form_name}
                        </MenuItem>
                      ))}
                  </Select>
                </Box>
              </FormControl>
            )}
            {!selectedTeamId && (
              <Typography
                variant="body2"
                style={{ marginTop: "16px" }}
                sx={{ display: "flex", justifyContent: "center" }}
              >
                Select a Team to continue.
              </Typography>
            )}

            {selectedTeamId && !selectedFormId && (
              <Typography
                variant="body2"
                style={{ marginTop: "16px" }}
                sx={{ display: "flex", justifyContent: "center" }}
              >
                Select a Form to view the Employees.
              </Typography>
            )}
          </Box>
          <Box
            sx={{
              m: 2,
              p: 2,
              backgroundColor:
                selectedTeamId && selectedFormId ? "white" : "none",
            }}
          >
            {selectedTeamId && selectedFormId && (
              <Box sx={{ display: "flex", justifyContent: "center" }}>
                <Box
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    m: 1,
                    p: 1,
                    justifyContent: "center",
                  }}
                >
                  {/* <Typography sx={{ width: "10rem" }}>Search:</Typography>
                  <TextField
                    // label="Search"
                    variant="outlined"
                    
                    size="small"
                    // fullWidth
                    sx={{ width: "50%" }}
                    value={searchTerm}
                    onChange={handleSearchChange}
                  /> */}
                </Box>
                <Fade
                  in={true}
                  timeout={{ appear: 1000, enter: 1000, exit: 2000 }}
                >
                  <Box
                    sx={{
                      m: 2,
                      p: 2,
                      display: "flex",
                      justifyContent: "center",
                      width: "fit-content",
                    }}
                  >
                    <Paper>
                      <DataGrid
                        autoHeight
                        disableColumnMenu
                        sx={{
                          width: "100%",
                          height: "100%",
                          "--DataGrid-overlayHeight": "50vh",
                        }}
                        initialState={{
                          pagination: {
                            paginationModel: {
                              pageSize: 10,
                            },
                          },
                        }}
                        pagination
                        pageSizeOptions={[5, 10, 15]}
                        rows={uniqueRows}
                        columns={columns}
                        disableColumnSelector
                        disableDensitySelector
                        slots={{
                          toolbar: GridToolbar,
                          noResultsOverlay: CustomNoRowsOverlay,
                          noRowsOverlay: CustomNoRowsOverlay,
                        }}
                        slotProps={{
                          toolbar: {
                            csvOptions: { disableToolbarButton: true },
                            showQuickFilter: true,
                            printOptions: { disableToolbarButton: true },
                            style: { margin: "0.5rem" },
                          },
                        }}
                        checkboxSelection
                      />
                    </Paper>
                  </Box>
                </Fade>
              </Box>
            )}
          </Box>
        </Box>
      </Box>
    </>
  );
};

export default Table;
