import { Badge, Box, Button, Paper, Tab, Tabs } from "@mui/material";
import { ThunkDispatch } from "@reduxjs/toolkit";
import React, { useEffect, useMemo, useState } from "react";
import { useDispatch } from "react-redux";
import {
  CustomTabPanel,
  a11yProps,
} from "../../../components/TabPanel/TabPanel";
import { RootState } from "../../../store/Store";
import {
  FetchDataFailureAction,
  FetchDataSuccessAction,
  fetchData,
} from "../../../store/actions";
import {
  admin360AllReview,
  hr360AllReview,
  showEmployeeAssigned360Form,
  showManagerAssigned360Form,
  showOrganizationLeaderAssigned360Form,
  showPeerAssigned360Form,
} from "../../../Service/Apis";
import { DataGrid, GridColDef, GridToolbar } from "@mui/x-data-grid";
import CustomNoRowsOverlay from "../../../components/Data/NoRowsOverlayComponent";
import { log } from "util";
import { setFormTeamId, setId } from "../../../store/FormSlice";
import { useNavigate } from "react-router-dom";

interface HR360AllReviewType {
  admin_average_rating: string;
  employee_average_rating: string;
  employee_code: string;
  employee_designation: string;
  employee_name: string;
  form_description: string;
  form_id: string;
  form_name: string;
  hr_edit_permission: boolean;
  hr_id: string;
  hr_name: string;
  manager_average_rating: string;
  manager_name: string;
  response_id: string;
  standard_deviation: string;
  team_name: string;
}

const ThreeSixtyHRDashboard = () => {
  const [hrAllReviews, setHrAllReviews] = useState<HR360AllReviewType[]>([]);

  const navigate = useNavigate();
  const dispatch = useDispatch();
  const dispatch2: ThunkDispatch<
    RootState,
    null,
    FetchDataSuccessAction | FetchDataFailureAction
  > = useDispatch();

  const hrAllReviewRows = hrAllReviews.map((row, index) => ({
    ...row,
    index: index,
    sr_no: index + 1,
  }));

  const hrAllReviewColumns: GridColDef[] = [
    {
      field: "sr_no",
      headerName: "Sr. No",
      renderHeader: () => (
        <strong
          className="custom-header"
          style={{ fontSize: 16, textAlign: "center" }}
        >
          Sr No.
        </strong>
      ),
      width: 85,
      align: "center",
      headerAlign: "center",
      headerClassName: "header-background",
    },
    {
      field: "form_name",
      headerName: "Form Name",
      renderHeader: () => (
        <strong
          className="custom-header"
          style={{ fontSize: 16, textAlign: "center" }}
        >
          Form Name
        </strong>
      ),
      width: 155,
      align: "center",
      headerAlign: "center",
      headerClassName: "header-background",
    },
    {
      field: "employee_name",
      headerName: "Employee Name",
      renderHeader: () => (
        <strong
          className="custom-header"
          style={{ fontSize: 16, textAlign: "center" }}
        >
          Employee Name
        </strong>
      ),
      width: 150,
      align: "center",
      headerAlign: "center",
      headerClassName: "header-background",
    },
    {
      field: "manager_name",
      headerName: "Manager Name",
      renderHeader: () => (
        <strong
          className="custom-header"
          style={{ fontSize: 16, textAlign: "center" }}
        >
          Manager Name
        </strong>
      ),
      width: 140,
      align: "center",
      headerAlign: "center",
      headerClassName: "header-background",
    },
    {
      field: "employee_360_average_rating",
      headerName: "Employee AVG Rating",
      renderHeader: () => (
        <strong
          className="custom-header"
          style={{ fontSize: 16, textAlign: "center" }}
        >
          Employee AVG
        </strong>
      ),
      width: 130,
      align: "center",
      headerAlign: "center",
      headerClassName: "header-background",
    },
    {
      field: "manager_360_average_rating",
      headerName: "Manager AVG Rating",
      renderHeader: () => (
        <strong
          className="custom-header"
          style={{ fontSize: 16, textAlign: "center" }}
        >
          Manager AVG
        </strong>
      ),
      width: 120,
      align: "center",
      headerAlign: "center",
      headerClassName: "header-background",
    },
    {
      field: "organization_leader_360_average_rating",
      headerName: "Peers AVG Rating",
      renderHeader: () => (
        <strong
          className="custom-header"
          style={{ fontSize: 16, textAlign: "center" }}
        >
          Company Leader AVG
        </strong>
      ),
      width: 215,
      align: "center",
      headerAlign: "center",
      headerClassName: "header-background",
    },
    {
      field: "peer_360_average_rating",
      headerName: "Peers AVG Rating",
      renderHeader: () => (
        <strong
          className="custom-header"
          style={{ fontSize: 16, textAlign: "center" }}
        >
          Peers AVG
        </strong>
      ),
      width: 100,
      align: "center",
      headerAlign: "center",
      headerClassName: "header-background",
    },
    {
      field: "action",
      headerName: "Actions",
      renderHeader: () => (
        <strong
          className="custom-header"
          style={{ fontSize: 16, textAlign: "center" }}
        >
          Actions
        </strong>
      ),
      width: 155,
      align: "center",
      headerAlign: "center",
      headerClassName: "header-background",
      renderCell: (params: any) => (
        <Button
          size="small"
          variant="contained"
          onClick={() => {
            localStorage.setItem("responseId", params.row.response_id);
            localStorage.setItem("teamId", params.row.team_id);
            navigate("/showthreesixtyformresponse");
          }}
        >
          Go To Review
        </Button>
      ),
    },
  ];

  useEffect(() => {
    const fetchAllForms = async () => {
      try {
        const response = await dispatch2(fetchData(`${admin360AllReview}`));
        setHrAllReviews(response as HR360AllReviewType[]);
      } catch (error) {
        console.log(error);
      }
    };
    fetchAllForms();
  }, []);

  return (
    <Box sx={{ display: "flex", justifyContent: "center", width: "98vw" }}>
      <Paper
        sx={{
          width: "fit-content",
          display: "flex",
          justifyContent: "center",
        }}
      >
        <DataGrid
          autoHeight
          initialState={{
            pagination: {
              paginationModel: {
                pageSize: 10,
              },
            },
          }}
          disableColumnMenu
          sx={{
            width: "100%",
            height: "100%",
            "--DataGrid-overlayHeight": "50vh",
          }}
          pagination
          pageSizeOptions={[5, 10, 15]}
          rows={hrAllReviewRows}
          columns={hrAllReviewColumns}
          disableColumnSelector
          disableDensitySelector
          slots={{
            toolbar: GridToolbar,
            noRowsOverlay: CustomNoRowsOverlay,
            noResultsOverlay: CustomNoRowsOverlay,
          }}
          slotProps={{
            toolbar: {
              csvOptions: { disableToolbarButton: true },
              showQuickFilter: true,
              printOptions: { disableToolbarButton: true },
              style: { margin: "0.5rem" },
            },
          }}
          getRowId={(row) => row.index}
        />
      </Paper>
    </Box>
  );
};

export default ThreeSixtyHRDashboard;
