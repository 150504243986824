import React, { useEffect, useState } from "react";
import {
  Box,
  Button,
  Card,
  Chip,
  Container,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
  InputLabel,
  MenuItem,
  Radio,
  Select,
  TextField,
  Typography,
} from "@mui/material";
import DeleteIcon from "@mui/icons-material/Delete";
import CloseIcon from "@mui/icons-material/Close";

import { enqueueSnackbar } from "notistack";
import {
  CreateAppraisalForm,
  CreateFormUrl,
  deleteCategoryAPI,
  deleteOptionAPI,
  deleteQuestionAPI,
  deleteSectionAPI,
  edit360Form,
  editForm,
  editHierarchicalForm,
  view360Forms,
  viewForm,
  viewHierarchicalForm,
} from "../../../Service/Apis";
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { DemoContainer } from "@mui/x-date-pickers/internals/demo";
import dayjs, { Dayjs } from "dayjs";
import { date } from "../../Form Add/AddForm";
import { format } from "date-fns";
import { Navigate, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { Category } from "@mui/icons-material";
import { setIsLoading } from "../../../store/LoadingSlice";
import Skeletons from "../../../components/Skeleton/Skeletons";
import {
  ToastVariant,
  useCustomToast,
} from "../../../components/Methods/SnackBar";
import {
  FetchDataFailureAction,
  FetchDataSuccessAction,
  deleteData,
  fetchData,
  patchData,
  postData,
  putData,
} from "../../../store/actions";
import { ThunkDispatch } from "@reduxjs/toolkit";
import { RootState } from "../../../store/Store";
import { log } from "util";
import { postDataSuccess } from "../../../store/AxiosSlice";
import useAxiosInterceptor from "../../../components/layout/AxiosInstance";
import Navbar from "../../../components/Page Component/Navbar";
import Footer from "../../../components/Page Component/Footer";
import NumberInput from "../../../components/NumberInput/NumberInput";

interface ThreeSixtyForm {
  categories: {
    category: string;
    category_id: string;
    questions: {
      question_id: string;
      question: string;
      answer_type: string;
      answer_choices: {
        answer_value: string;
        answer_point: number;
        option_id: string;
      }[];
    }[];
  }[];
}

interface OptionValue {
  [categoryIndex: number]: {
    [questionIndex: number]: {
      value: string;
    };
  };
}

interface AnswerWeight {
  [categoryIndex: number]: {
    [questionIndex: number]: {
      value: number;
    };
  };
}

interface AnswerChoicesError {
  [categoryIndex: number]: {
    [questionIndex: number]: {
      value: boolean;
    };
  };
}

const initialOptionValue: OptionValue = {};
const initialAnswerWeight: AnswerWeight = {};
const initialAnswerChoice: AnswerChoicesError = {};

const initialState: ThreeSixtyForm = {
  categories: [
    {
      category: "",
      category_id: "",
      questions: [
        {
          question: "",
          question_id: "",
          answer_type: "multichoice",
          answer_choices: [],
        },
      ],
    },
  ],
};

const EditThreeSixtyForm = () => {
  const [formContent, setFormContent] = useState<ThreeSixtyForm>(initialState);
  const [sectionId, setSectionId] = useState<string>("");
  const [formName, setFormName] = useState<string>("");
  const [formDescription, setFormDescription] = useState<string>("");
  const [answerChoicesError, setAnswerChoicesError] =
    useState<AnswerChoicesError>(initialAnswerChoice);
  const [optionValue, setOptionValue] =
    useState<OptionValue>(initialOptionValue);
  const [answerWeight, setAnswerWeight] =
    useState<AnswerWeight>(initialAnswerWeight);
  const [forceUpdateKey, setForceUpdateKey] = useState(0);

  const [flag, setFlag] = useState<boolean>(false);

  const [empDate, setEmpDate] = useState<Dayjs | null>(dayjs(`${date}`));
  const [managerDate, setManagerDate] = useState<Dayjs | null>(
    empDate?.add(1, "day") as Dayjs
  );

  const [deleteSectionIs, setDeleteSectionIs] = useState<number | null>(null);
  const [deleteCategoryIs, setDeleteCategoryIs] = useState<{
    categoryIndex: number | null;
  }>({ categoryIndex: null });
  const [deleteQuestionIs, setDeleteQuestionIs] = useState<{
    categoryIndex: number | null;
    questionIndex: number | null;
  }>({ categoryIndex: null, questionIndex: null });

  const axiosInstance = useAxiosInterceptor();

  // const success = useSelector((state: any) => state.http.data);

  const token = localStorage.getItem("token");

  const Navigate = useNavigate();
  const showToast = useCustomToast();
  const dispatch = useDispatch();

  const isloading = useSelector((state: any) => state.loading.isLoading);

  const handleShowToast = (
    message: string,
    variant: ToastVariant,
    isCloseable: boolean
  ) => {
    showToast(message, variant, isCloseable);
  };
  const formId =
    useSelector((state: any) => state.form.id) ||
    localStorage.getItem("formid");

  const dispatch2: ThunkDispatch<
    RootState,
    null,
    FetchDataSuccessAction | FetchDataFailureAction
  > = useDispatch();

  const handleOpenCategoryDelete = (categoryIndex: number) => {
    setDeleteCategoryIs({
      categoryIndex: categoryIndex,
    });
  };

  const handleCloseCategoryDelete = () => {
    setDeleteCategoryIs({ categoryIndex: null });
  };

  const handleOpenQuestionDelete = (
    categoryIndex: number,
    questionIndex: number
  ) => {
    setDeleteQuestionIs({
      categoryIndex: categoryIndex,
      questionIndex: questionIndex,
    });
  };

  const handleCloseQuestionDeleter = () => {
    setDeleteQuestionIs({
      categoryIndex: null,
      questionIndex: null,
    });
  };

  const addCategory = () => {
    const newCategory = {
      category: "",
      category_id: "",
      questions: [
        {
          question: "",
          question_id: "",
          answer_type: "multichoice",
          answer_choices: [],
        },
      ],
    };

    // Create a copy of the current state
    const updatedFormContent = { ...formContent };

    // Add the new category to the copy
    updatedFormContent.categories.push(newCategory);

    // Update the state with the new form content
    setFormContent(updatedFormContent);
  };

  const addQuestion = (
    categoryIndex: number
    // questionType: string
  ) => {
    const updatedCategory = {
      ...formContent.categories[categoryIndex],
    };
    const newQuestion = {
      question_id: "",
      question: "",
      answer_type: "multichoice",
      answer_choices: [], // Remove the initial null option
    };

    updatedCategory.questions.push(newQuestion);

    const updatedFormContent = { ...formContent };
    updatedFormContent.categories[categoryIndex] = updatedCategory;

    setFormContent(updatedFormContent);
  };

  const deleteQuestion = (
    categoryIndex: number,
    questionIndex: number,
    question_id: string
  ) => {
    const totalQuestions =
      formContent.categories[categoryIndex].questions.length;

    if (questionIndex > -1 && totalQuestions > 1) {
      const updatedFormContent = { ...formContent };
      const updatedCategory = {
        ...formContent.categories[categoryIndex],
      };

      if (question_id !== "") {
        try {
          const response = dispatch2(
            deleteData(`${deleteQuestionAPI}${question_id}`)
          )
            .then((response) => {
              handleShowToast("Question Deleted", "success", true);
              updatedCategory.questions.splice(questionIndex, 1);
              updatedFormContent.categories[categoryIndex] = updatedCategory;
              setFormContent(updatedFormContent);
            })
            .catch((error) => {
              console.log(error);
            });
        } catch (error) {
          console.log(error);
        }
      } else if (question_id === "") {
        updatedCategory.questions.splice(questionIndex, 1);
        updatedFormContent.categories[categoryIndex] = updatedCategory;
        setFormContent(updatedFormContent);
      }
    } else if (totalQuestions <= 1) {
      handleShowToast("Cannot Delete Last Question.", "info", true);
    }
  };

  const editQuestionType = (
    categoryIndex: number,
    questionIndex: number,
    questionType: string
  ): void => {
    const updatedFormContent = { ...formContent };
    const question =
      updatedFormContent.categories[categoryIndex].questions[questionIndex];
    question.answer_type = questionType;

    setFormContent(updatedFormContent);
  };

  const handleSetOptionValue = (
    categoryIndex: number,
    questionIndex: number,
    value: string
  ) => {
    setOptionValue((prevOptionValue) => {
      // Create a copy of the current state to avoid modifying it directly
      const updatedOptionValue = { ...prevOptionValue };

      // Check if the sectionIndex exists in the state

      // Check if the categoryIndex exists in the state
      if (!updatedOptionValue.hasOwnProperty(categoryIndex)) {
        updatedOptionValue[categoryIndex] = {};
      }

      // Update the value for the specified questionIndex
      updatedOptionValue[categoryIndex][questionIndex] = {
        value: value,
      };

      // Return the updated state
      return updatedOptionValue;
    });
  };

  const handleAnswerChoice = (
    categoryIndex: number,
    questionIndex: number,
    value: number
  ) => {
    setAnswerChoicesError((prevAnswerError) => {
      const updatedAnswerError = { ...answerChoicesError };
      const categoryError = updatedAnswerError[categoryIndex] || {};
      const questionError = categoryError[questionIndex] || {
        value: false,
      };

      questionError.value = value < 2;

      categoryError[questionIndex] = questionError;
      updatedAnswerError[categoryIndex] = categoryError;

      return updatedAnswerError;
    });
  };

  const addOption = (categoryIndex: number, questionIndex: number) => {
    const updatedFormContent = { ...formContent };
    const question =
      updatedFormContent.categories[categoryIndex].questions[questionIndex];
    const isDuplicateValue = question.answer_choices.some(
      (choice) =>
        choice.answer_point ===
        answerWeight?.[categoryIndex]?.[questionIndex]?.value
    );

    if (isDuplicateValue) {
      // Handle duplicate answerWeight value error (e.g., display an error message)
      handleShowToast("Please enter a different value", "error", true);
      return;
    }
    const newOption = {
      answer_value: optionValue?.[categoryIndex]?.[questionIndex]?.value,
      option_id: "", // Add the necessary logic to generate a unique option ID
      answer_point:
        answerWeight?.[categoryIndex]?.[questionIndex]?.value === undefined
          ? 0
          : answerWeight?.[categoryIndex]?.[questionIndex]?.value,
    };
    question.answer_choices.push(newOption);
    setFormContent(updatedFormContent);
    handleSetOptionValue(categoryIndex, questionIndex, "");
    handleAnswerChoice(
      categoryIndex,
      questionIndex,
      question.answer_choices.length
    );
  };

  const deleteOption = (
    categoryIndex: number,
    questionIndex: number,
    optionIndex: number,
    option_id: string
  ) => {
    const updatedFormContent = { ...formContent };
    const question =
      updatedFormContent.categories[categoryIndex].questions[questionIndex];
    if (option_id !== "") {
      try {
        const response = dispatch2(deleteData(`${deleteOptionAPI}${option_id}`))
          .then((response) => {
            handleShowToast("Option Deleted", "success", true);
            question.answer_choices.splice(optionIndex, 1);
            setFormContent(updatedFormContent);
            handleAnswerChoice(
              categoryIndex,
              questionIndex,
              question.answer_choices.length
            );
          })
          .catch((error) => {
            console.log(error);
          });
      } catch (error) {
        console.log(error);
      }
    } else if (option_id === "") {
      handleAnswerChoice(
        categoryIndex,
        questionIndex,
        question.answer_choices.length
      );
      question.answer_choices.splice(optionIndex, 1);
      setFormContent(updatedFormContent);
    }
  };

  const deleteCategory = (categoryIndex: number, category_id: string) => {
    const totalCategories = formContent.categories.length;
    if (categoryIndex > -1 && totalCategories > 1) {
      const updatedFormContent = { ...formContent };

      if (category_id !== "") {
        try {
          const response = dispatch2(
            deleteData(`${deleteCategoryAPI}${category_id}`)
          )
            .then((response) => {
              handleShowToast("Category Deleted", "success", true);
              updatedFormContent.categories.splice(categoryIndex, 1);
              setFormContent(updatedFormContent);
            })
            .catch((error) => {
              console.log(error);
            });
        } catch (error) {
          console.log(error);
        }
      } else if (category_id === "") {
        updatedFormContent.categories.splice(categoryIndex, 1);
        setFormContent(updatedFormContent);
      }
      // return;
    } else if (totalCategories <= 1) {
      handleShowToast("Cannot Delete Last Category.", "info", true);
    }
  };

  const handleCategoryNameChange = (
    categoryIndex: number,
    value: string
  ): void => {
    const updatedFormContent = { ...formContent };
    updatedFormContent.categories[categoryIndex].category = value;

    setFormContent(updatedFormContent);
  };

  const handleQuestionChange = (
    categoryIndex: number,
    questionIndex: number,
    value: string
  ): void => {
    const updatedFormContent = { ...formContent };
    updatedFormContent.categories[categoryIndex].questions[
      questionIndex
    ].question = value;

    setFormContent(updatedFormContent);
  };

  const handleSetAnswerChoiceWeight = (
    categoryIndex: number,
    questionIndex: number,
    value: number
  ) => {
    setAnswerWeight((prevOptionValue) => {
      return {
        ...prevOptionValue,
        [categoryIndex]: {
          ...prevOptionValue[categoryIndex],
          [questionIndex]: {
            value: value,
          },
        },
      };
    });
  };

  const handleSubmit = (e: any) => {
    e.preventDefault();
    // let isAnyAnswerChoiceError;

    // Object.values(answerChoicesError).some((categories) => {
    //   Object.values(categories).some((questions: any) => {
    //     Object.values(questions).some((error: any) => {
    //       if (error.value) {
    //         isAnyAnswerChoiceError = true;
    //       }
    //     });
    //   });
    // });

    // if (isAnyAnswerChoiceError) {
    //   setFlag(true);
    //   return;
    // }

    const { categories } = formContent;

    var data = {
      form_name: formName,
      form_description: formDescription,
      employee_deadline: empDate ? format(empDate.toDate(), "yyyy-MM-dd") : "",
      manager_deadline: managerDate
        ? format(managerDate.toDate(), "yyyy-MM-dd")
        : "",
      teams: [],
      is_hierarchical: false,
      is_360: true,
      // manager_fields_visible: managerResponseVisibility,
      sections: [
        {
          section_id: sectionId,
          section: null,
          categories,
        },
      ],
    };

    // API Call for editing the form.
    try {
      const response = dispatch2(patchData(`${edit360Form}${formId}`, data))
        .then((response) => {
          handleShowToast(
            `${formName} Updated Successfully !`,
            "success",
            true
          );
          dispatch(postDataSuccess(null));

          Navigate("/createdforms");
        })
        .catch((error) => {
          console.log(error);
        });
    } catch (error) {
      console.log(error);
    }
  };

  // API call for getting the particular form.
  useEffect(() => {
    const response = axiosInstance
      .get(`${view360Forms}${formId}`, {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      })
      .then((res) => {
        setSectionId(res.data[0].sections[0].section_id);
        setFormContent({
          ...formContent,
          categories: res.data[0].sections[0].categories.map(
            (category: any) => ({
              category_id: category.category_id,
              category: category.category,
              questions: category.questions.map((question: any) => ({
                question_id: question.question_id,
                question: question.question,
                answer_type: question.answer_type,
                answer_choices: question.answer_choices.map((choice: any) => ({
                  answer_value: choice.answer_value,
                  option_id: choice.option_id,
                  answer_point: choice.answer_point,
                })),
              })),
            })
          ),
        });
        setFormName(res.data[0].form_name);
        setFormDescription(res.data[0].form_description);
        setEmpDate(dayjs(res.data[0].employee_deadline));
        setManagerDate(dayjs(res.data[0].manager_deadline));
        dispatch(setIsLoading(false));
      })
      .catch((error) => {
        dispatch(setIsLoading(false));
        console.log(error);
      });
  }, []);

  if (isloading) {
    return (
      <>
        <Skeletons />
      </>
    );
  }

  return (
    <>
      <Box>
        <Navbar />
      </Box>
      <Container sx={{ mt: 12, mb: 10 }}>
        <Box sx={{ display: "flex", justifyContent: "center" }}>
          <Box component="form" onSubmit={handleSubmit}>
            <Box
              sx={{
                display: "flex",
                justifyContent: "right",
                m: 2,
                // width: "100%",
              }}
            ></Box>
            <Box sx={{ width: "75vw" }}>
              <Card
                elevation={4}
                sx={{
                  m: 2,
                  p: 2,
                  display: "flex",
                  justifyContent: "center",
                  boxShadow: "0px 0px 10px rgba(0, 0, 0, 0.1)", // Add boxShadow here
                  border: "1px solid #ccc",
                }}
              >
                <Box sx={{ width: "90%" }}>
                  <TextField
                    id="form_name"
                    name="form_name"
                    required
                    fullWidth
                    multiline
                    maxRows={4}
                    sx={{ overflow: "auto", p: 1 }}
                    variant="standard"
                    size="small"
                    placeholder="Form Heading"
                    value={formName}
                    onChange={(e) => {
                      setFormName(e.target.value);
                    }}
                  />
                  <TextField
                    id="description"
                    fullWidth
                    required
                    variant="standard"
                    size="small"
                    placeholder="Form Description"
                    multiline
                    maxRows={4}
                    minRows={2}
                    sx={{ overflow: "auto", p: 1 }}
                    value={formDescription}
                    onChange={(e) => {
                      setFormDescription(e.target.value);
                    }}
                  />
                </Box>
              </Card>
            </Box>
            <Box>
              <Box>
                {formContent.categories.map((category, categoryIndex) => {
                  return (
                    <Card
                      key={categoryIndex}
                      elevation={4}
                      sx={{
                        m: 2,
                        p: 2,
                        flex: 1,
                        flexShrink: 1,
                        boxShadow: "0px 0px 10px rgba(0, 0, 0, 0.1)", // Add boxShadow here
                        border: "1px solid #ccc",
                      }}
                    >
                      <Box sx={{ m: 4 }}>
                        <Box
                          sx={{
                            display: "flex",
                            alignItems: "center",
                            p: 0,
                          }}
                        >
                          <InputLabel
                            sx={{ width: "15rem", fontWeight: "bold" }}
                            htmlFor="category_name"
                          >
                            Category{" "}
                            {String.fromCharCode(
                              categoryIndex + "A".charCodeAt(0)
                            )}{" "}
                            :
                            {/* {String.fromCharCode(
                    categoryIndex + "a".charCodeAt(0)
                  ) + ")"}{" "} */}
                          </InputLabel>
                          <TextField
                            name="category_name"
                            fullWidth
                            required
                            multiline
                            maxRows={4}
                            sx={{ overflow: "auto" }}
                            value={category.category}
                            variant="standard"
                            size="small"
                            onChange={(e) => {
                              handleCategoryNameChange(
                                categoryIndex,
                                e.target.value as string
                              );
                            }}
                          />
                          <IconButton
                            onClick={() => {
                              handleOpenCategoryDelete(categoryIndex);
                            }}
                          >
                            <DeleteIcon
                              id="delete-category"
                              fontSize="medium"
                              color="primary"
                            />
                          </IconButton>
                          <Dialog
                            open={
                              deleteCategoryIs.categoryIndex === categoryIndex
                            }
                            onClose={handleCloseCategoryDelete}
                            // style={{width:"45rem"}}
                            sx={{
                              m: 1,
                              "& .MuiDialog-paper": {
                                width: "30rem",
                              },
                            }}
                          >
                            <Box
                              sx={{
                                display: "flex",
                                justifyContent: "flex-end",
                                alignItems: "flex-end",
                              }}
                            >
                              <IconButton
                                onClick={() => handleCloseCategoryDelete()}
                              >
                                <CloseIcon />
                              </IconButton>
                            </Box>
                            <DialogTitle>Confirmation</DialogTitle>
                            <DialogContent>
                              <Typography>Do you want to delete?</Typography>
                            </DialogContent>
                            <DialogActions>
                              <Button
                                id="no"
                                onClick={handleCloseCategoryDelete}
                              >
                                No
                              </Button>
                              <Button
                                id="yes"
                                variant="contained"
                                onClick={() => {
                                  deleteCategory(
                                    categoryIndex,
                                    category.category_id
                                  );
                                  handleCloseCategoryDelete();
                                }}
                              >
                                Yes
                              </Button>
                            </DialogActions>
                          </Dialog>
                        </Box>
                        <Box>
                          {category.questions.map((question, questionIndex) => {
                            return (
                              <Box sx={{ m: 4 }}>
                                <Box
                                  sx={{
                                    display: "flex",
                                    alignItems: "center",
                                  }}
                                >
                                  <Box
                                    sx={{
                                      display: "flex",
                                      alignItems: "center",
                                      width: "70%",
                                    }}
                                  >
                                    <InputLabel
                                      sx={{
                                        width: "10rem",
                                        fontWeight: "bold",
                                      }}
                                    >
                                      Question {questionIndex + 1} :
                                    </InputLabel>
                                    <TextField
                                      id={`question-${questionIndex}`}
                                      size="small"
                                      fullWidth
                                      multiline
                                      maxRows={4}
                                      sx={{ overflow: "auto" }}
                                      value={question.question}
                                      required
                                      variant="standard"
                                      onChange={(e) => {
                                        handleQuestionChange(
                                          categoryIndex,
                                          questionIndex,
                                          e.target.value as string
                                        );
                                      }}
                                    />
                                  </Box>
                                  <Box sx={{ m: 2 }}>
                                    <Select
                                      id="question-type"
                                      disabled
                                      size="small"
                                      value={question.answer_type}
                                      sx={{
                                        height: "2.5rem",
                                        width: "10.5rem",
                                      }}
                                      onChange={(e) => {
                                        editQuestionType(
                                          categoryIndex,
                                          questionIndex,
                                          e.target.value as string
                                        );
                                      }}
                                    >
                                      <MenuItem
                                        id="short-answer"
                                        value="short_answer"
                                      >
                                        Short Answer
                                      </MenuItem>
                                      <MenuItem
                                        id="multichoice"
                                        value="multichoice"
                                      >
                                        Multichoice
                                      </MenuItem>
                                    </Select>
                                    <IconButton
                                      onClick={() => {
                                        handleOpenQuestionDelete(
                                          categoryIndex,
                                          questionIndex
                                        );
                                      }}
                                    >
                                      <DeleteIcon
                                        fontSize="medium"
                                        color="primary"
                                      />
                                    </IconButton>
                                    <Dialog
                                      open={
                                        deleteQuestionIs.categoryIndex ===
                                          categoryIndex &&
                                        deleteQuestionIs.questionIndex ===
                                          questionIndex
                                      }
                                      onClose={handleCloseQuestionDeleter}
                                      // style={{width:"45rem"}}
                                      sx={{
                                        m: 1,
                                        "& .MuiDialog-paper": {
                                          width: "30rem",
                                        },
                                      }}
                                    >
                                      <Box
                                        sx={{
                                          display: "flex",
                                          justifyContent: "flex-end",
                                          alignItems: "flex-end",
                                        }}
                                      >
                                        <IconButton
                                          onClick={() =>
                                            handleCloseQuestionDeleter()
                                          }
                                        >
                                          <CloseIcon />
                                        </IconButton>
                                      </Box>
                                      <DialogTitle>Confirmation</DialogTitle>
                                      <DialogContent>
                                        <Typography>
                                          Do you want to delete?
                                        </Typography>
                                      </DialogContent>
                                      <DialogActions>
                                        <Button
                                          id="no"
                                          onClick={handleCloseQuestionDeleter}
                                        >
                                          No
                                        </Button>
                                        <Button
                                          id="yes"
                                          variant="contained"
                                          onClick={() => {
                                            deleteQuestion(
                                              categoryIndex,
                                              questionIndex,
                                              question.question_id
                                            );
                                            handleCloseQuestionDeleter();
                                          }}
                                        >
                                          Yes
                                        </Button>
                                      </DialogActions>
                                    </Dialog>
                                  </Box>
                                </Box>
                                <Box>
                                  {question.answer_type === "multichoice" && (
                                    <Container sx={{ p: 1 }}>
                                      {question.answer_choices.map(
                                        (answerChoice, index) => (
                                          <Box
                                            sx={{
                                              display: "flex",
                                              alignItems: "center",
                                            }}
                                          >
                                            <Radio size="small" disabled />
                                            <TextField
                                              id={`answer_choices-category${categoryIndex}-question${questionIndex}-${index}`}
                                              label="Standard"
                                              fullWidth
                                              multiline
                                              maxRows={4}
                                              sx={{
                                                overflow: "auto",
                                                m: 1,
                                              }}
                                              size="small"
                                              variant="standard"
                                              value={answerChoice.answer_value}
                                              disabled
                                            />
                                            <Chip
                                              label={`${answerChoice.answer_point}`}
                                              variant="filled"
                                            />
                                            <Button
                                              id={`deleteOption-${index}`}
                                              onClick={() =>
                                                deleteOption(
                                                  categoryIndex,
                                                  questionIndex,
                                                  index,
                                                  answerChoice.option_id
                                                )
                                              }
                                            >
                                              <DeleteIcon />
                                            </Button>
                                          </Box>
                                        )
                                      )}
                                      <Box sx={{ display: "flex" }}>
                                        <TextField
                                          id={`answer_value-catrgory-${categoryIndex}-question-${questionIndex}`}
                                          name="answer_value"
                                          variant="standard"
                                          size="small"
                                          multiline
                                          maxRows={4}
                                          sx={{
                                            overflow: "auto",
                                            m: 1,
                                            width: "40%",
                                          }}
                                          value={
                                            optionValue[categoryIndex]?.[
                                              questionIndex
                                            ]?.value
                                          }
                                          onChange={(e) => {
                                            handleSetOptionValue(
                                              categoryIndex,
                                              questionIndex,
                                              e.target.value
                                            );
                                          }}
                                          required={
                                            question.answer_type ===
                                              "multichoice" &&
                                            question.answer_choices.length === 0
                                          }
                                          placeholder="Add an option"
                                          helperText={
                                            answerChoicesError?.[
                                              categoryIndex
                                            ]?.[questionIndex]?.value &&
                                            flag && (
                                              <Typography
                                                color={"error"}
                                                id="minimum-two-options-error"
                                              >
                                                Please give minimum two options.
                                              </Typography>
                                            )
                                          }
                                        />
                                        <NumberInput
                                          key={forceUpdateKey}
                                          aria-label="Demo number input"
                                          placeholder="Type a number…"
                                          value={
                                            answerWeight[categoryIndex]?.[
                                              questionIndex
                                            ]?.value
                                          }
                                          min={0}
                                          max={100}
                                          onChange={(e: any, val: number) => {
                                            e.preventDefault();
                                            handleSetAnswerChoiceWeight(
                                              categoryIndex,
                                              questionIndex,
                                              val as number
                                            );
                                          }}
                                        />
                                        <Button
                                          id={`add-option`}
                                          size="small"
                                          onClick={() =>
                                            addOption(
                                              categoryIndex,
                                              questionIndex
                                            )
                                          }
                                          sx={{ m: 1 }}
                                        >
                                          Add Option
                                        </Button>
                                      </Box>
                                    </Container>
                                  )}
                                </Box>
                              </Box>
                            );
                          })}
                          <Box
                            sx={{
                              display: "flex",
                              justifyContent: "right",
                              m: 1,
                            }}
                          >
                            <Button
                              id="addQuestion"
                              variant="outlined"
                              onClick={() => addQuestion(categoryIndex)}
                              size="small"
                            >
                              {/* <AddCircleOutlineIcon /> */}
                              Add Question
                            </Button>
                          </Box>
                        </Box>
                      </Box>
                    </Card>
                  );
                })}
                <Box
                  sx={{
                    display: "flex",
                    justifyContent: "right",
                    m: 2,
                  }}
                >
                  <Button
                    id="addCategory"
                    variant="contained"
                    size="small"
                    onClick={(e) => {
                      addCategory();
                    }}
                  >
                    Add Category
                  </Button>
                </Box>
              </Box>
            </Box>

            <Box
              sx={{
                display: { xs: "block", sm: "block", md: "flex" },
                justifyContent: "center",
              }}
            >
              <Box id="employee_date_picker" sx={{ m: 1, p: 1 }}>
                <LocalizationProvider dateAdapter={AdapterDayjs}>
                  <DatePicker
                    data-testid="employee_date_pick"
                    className="emp_date_picker"
                    label="Employee DeadLine"
                    value={empDate}
                    sx={{ color: "green" }}
                    disablePast
                    format="YYYY-MM-DD"
                    onChange={(newValue) => {
                      setEmpDate(newValue);
                      setManagerDate(newValue?.add(1, "day") as Dayjs);
                    }}
                  />
                </LocalizationProvider>
              </Box>
            </Box>
            <Box sx={{ display: "flex", justifyContent: "center" }}>
              <Button id="CreateFormButton" variant="contained" type="submit">
                Update Form
              </Button>
            </Box>
          </Box>
        </Box>
      </Container>
      <Box>
        <Footer />
      </Box>
    </>
  );
};

export default EditThreeSixtyForm;
