import { enqueueSnackbar } from "notistack";
import { useSelector } from "react-redux";

const sidebarItems: {
  [key: string]: { label: string; route: string }[];
} = {
  admin: [
    { label: "Dashboard", route: "dashboard" },
    { label: "Employee Information", route: "employeeinformation" },
    { label: "Create team", route: "createteam" },
    { label: "Ranking", route: "HARanking" },
    { label: "Permission", route: "permission" },
    { label: "Data Points", route: "datapoints" },
  ],
  employee: [
    { label: "Dashboard", route: "dashboard" },
    { label: "Previous Responses", route: "previousresponses" },
    { label: "Data Points", route: "datapoints" },
  ],
  hr: [
    { label: "Dashboard", route: "dashboard" },
    { label: "Employee Information", route: "employeeinformation" },
    { label: "Add Form", route: "addForm" },
    { label: "Create team", route: "createteam" },
    { label: "Ranking", route: "HARanking" },
    { label: "Created Forms", route: "createdforms" },
    { label: "Data Points", route: "datapoints" },
  ],
  manager: [
    { label: "Dashboard", route: "dashboard" },
    { label: "Subordinate", route: "subordinate" },
    { label: "Ranking", route: "ranking" },
    { label: "Data Points", route: "datapoints" },
  ],
};

export const getSidebarItems = (
  roleArray: string[]
): { label: string; route: string }[] => {
  let items: { label: string; route: string }[] = [];

  roleArray.forEach((role) => {
    const roleItems = sidebarItems[role.toLowerCase()];
    if (roleItems) {
      items = [...items, ...roleItems];
    }
  });

  return items;
};

export const getItems = (roles: string): { label: string; route: string }[] => {
  let items: { label: string; route: string }[] = [];
  switch (roles?.toLowerCase()) {
    case "admin":
      items = [...items, ...sidebarItems.admin];
      break;
    case "employee":
      items = [...items, ...sidebarItems.employee];
      break;
    case "hr":
      items = [...items, ...sidebarItems.hr];
      break;
    case "manager":
      items = [...items, ...sidebarItems.manager];
      break;
    default:
      break;
  }
  return items;
};
