import {
  Badge,
  Box,
  Button,
  Card,
  Container,
  Grid,
  Paper,
  Tab,
  Tabs,
  Typography,
} from "@mui/material";
import { useEffect, useMemo, useState } from "react";

import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import NothingToDisplayImage from "../../asset/NothingToDisplayImage.png";
import {
  ShowParticularForms,
  ViewUser,
  showPrevious360FormsEmployee,
  showPreviousFormsEmployee,
} from "../../../Service/Apis";
import Footer from "../../../components/Page Component/Footer";
import Navbar from "../../../components/Page Component/Navbar";
import useAxiosInterceptor from "../../../components/layout/AxiosInstance";
import { setFormTeamId, setId, setRole } from "../../../store/FormSlice";
import { setIsLoading } from "../../../store/LoadingSlice";
import { setFields } from "../../../store/Personslice";
import {
  FetchDataFailureAction,
  FetchDataSuccessAction,
  fetchData,
  postData,
  putData,
} from "../../../store/actions";
import { ThunkDispatch } from "@reduxjs/toolkit";
import { RootState } from "../../../store/Store";
import { DataGrid, GridColDef, GridToolbar } from "@mui/x-data-grid";
import CustomNoRowsOverlay from "../../../components/Data/NoRowsOverlayComponent";
import {
  CustomTabPanel,
  a11yProps,
} from "../../../components/TabPanel/TabPanel";

interface FormType {
  project_name: string;
  team_id: string;

  employee_deadline: string;
  final_submit_done: boolean;
  form_id: string;
  form_team_id: string;
  form_name: string;
}

const ThreeSixtyEmployeePreviousResponses = () => {
  const [peerForm, setPeerForm] = useState<FormType[]>([
    {
      project_name: "",
      team_id: "",
      employee_deadline: "",
      final_submit_done: false,
      form_id: "",
      form_team_id: "",
      form_name: "",
    },
  ]); //storing the blocks of responses
  const [employeeForm, setEmployeeForm] = useState<FormType[]>([
    {
      project_name: "",
      team_id: "",
      employee_deadline: "",
      final_submit_done: false,
      form_id: "",
      form_team_id: "",
      form_name: "",
    },
  ]); //storing the blocks of responses
  const [value, setValue] = useState(0);

  const dispatch = useDispatch();
  const dispatch2: ThunkDispatch<
    RootState,
    null,
    FetchDataSuccessAction | FetchDataFailureAction
  > = useDispatch();

  const navigate = useNavigate();
  const newToken = useSelector((state: any) => state.auth.token);
  const isLoading = useSelector((state: any) => state.loading.isLoading);
  const emailId = localStorage.getItem("email");

  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setValue(newValue);
  };

  const handleViewResponse = (e: any, formId: string, formTeamId: string) => {
    e.preventDefault();
    dispatch(setFormTeamId(formTeamId));
    localStorage.setItem("formTeamId", formTeamId);
    dispatch(setId(formId));
    localStorage.setItem("formid", formId);
    navigate("/employeepreviousresponse");
  };

  // const flattenedForms = form?.flatMap((formItem) =>
  //   formItem.forms?.map((form) => ({
  //     project_name: formItem.project_name,
  //     team_id: formItem.team_id,
  //     ...form,
  //   }))
  // );

  const peerRows = peerForm?.map((form, index) => ({
    ...form,
    index: index,
    sr_no: index + 1,
  }));

  const employeeRows = employeeForm?.map((form, index) => ({
    ...form,
    index: index,
    sr_no: index + 1,
  }));

  const peerColumns: GridColDef[] = useMemo(
    () => [
      {
        headerName: "Sr No.",
        field: "sr_no",
        align: "center",
        headerAlign: "center",
        headerClassName: "header-background",
        width: 125,
        renderHeader: () => (
          <strong className="custom-header" style={{ fontSize: 15 }}>
            Sr No.
          </strong>
        ),
      },
      {
        headerName: "Manager Name",
        field: "manager",
        align: "center",
        headerAlign: "center",
        headerClassName: "header-background",
        width: 165,
        renderHeader: () => (
          <strong className="custom-header" style={{ fontSize: 15 }}>
            Manager Name
          </strong>
        ),
      },
      {
        headerName: "Form Name",
        field: "form_name",
        align: "center",
        headerAlign: "center",
        headerClassName: "header-background",
        width: 165,
        renderHeader: () => (
          <strong className="custom-header" style={{ fontSize: 15 }}>
            Form Name
          </strong>
        ),
      },
      {
        headerName: "Employee Deadline",
        field: "employee_deadline",
        align: "center",
        headerAlign: "center",
        headerClassName: "header-background",
        width: 195,
        renderHeader: () => (
          <strong className="custom-header" style={{ fontSize: 15 }}>
            Employee Deadline
          </strong>
        ),
      },
      {
        headerName: "Options",
        field: "options",
        align: "center",
        headerAlign: "center",
        headerClassName: "header-background",
        width: 175,
        renderHeader: () => (
          <strong className="custom-header" style={{ fontSize: 15 }}>
            Options
          </strong>
        ),
        renderCell: (params) => (
          <>
            <Button
              variant="contained"
              size="small"
              onClick={(e) => {
                dispatch(setFormTeamId(params.row.form_team_id));
                localStorage.setItem("formTeamId", params.row.form_team_id);
                dispatch(setId(params.row.form_id));
                localStorage.setItem("responseId", params.row.response_id);
                localStorage.setItem("formid", params.row.form_id);
                localStorage.setItem("peerNumber", params.row.peer_number);
                localStorage.setItem("teamId", params.row.team_id);
                localStorage.setItem(
                  "Role360",
                  JSON.stringify({
                    isEmployee: false,
                    isManager: false,
                    isOrganizationLeader: false,
                    isPeer: true,
                  })
                );
                navigate("/employee360previousresponse");
              }}
            >
              View Response
            </Button>
          </>
        ),
      },
    ],
    [peerForm, peerRows]
  );

  const employeeColumns: GridColDef[] = useMemo(
    () => [
      {
        headerName: "Sr No.",
        field: "sr_no",
        align: "center",
        headerAlign: "center",
        headerClassName: "header-background",
        width: 125,
        renderHeader: () => (
          <strong className="custom-header" style={{ fontSize: 15 }}>
            Sr No.
          </strong>
        ),
      },
      {
        headerName: "Manager Name",
        field: "manager",
        align: "center",
        headerAlign: "center",
        headerClassName: "header-background",
        width: 165,
        renderHeader: () => (
          <strong className="custom-header" style={{ fontSize: 15 }}>
            Manager Name
          </strong>
        ),
      },
      {
        headerName: "Form Name",
        field: "form_name",
        align: "center",
        headerAlign: "center",
        headerClassName: "header-background",
        width: 165,
        renderHeader: () => (
          <strong className="custom-header" style={{ fontSize: 15 }}>
            Form Name
          </strong>
        ),
      },
      {
        headerName: "Employee Deadline",
        field: "employee_deadline",
        align: "center",
        headerAlign: "center",
        headerClassName: "header-background",
        width: 195,
        renderHeader: () => (
          <strong className="custom-header" style={{ fontSize: 15 }}>
            Employee Deadline
          </strong>
        ),
      },
      {
        headerName: "Options",
        field: "options",
        align: "center",
        headerAlign: "center",
        headerClassName: "header-background",
        width: 175,
        renderHeader: () => (
          <strong className="custom-header" style={{ fontSize: 15 }}>
            Options
          </strong>
        ),
        renderCell: (params) => (
          <>
            <Button
              variant="contained"
              size="small"
              onClick={(e) => {
                dispatch(setFormTeamId(params.row.form_team_id));
                localStorage.setItem("formTeamId", params.row.form_team_id);
                localStorage.setItem("teamId", params.row.team_id);
                dispatch(setId(params.row.form_id));
                localStorage.setItem("responseId", params.row.response_id);
                localStorage.setItem("formid", params.row.form_id);
                localStorage.setItem(
                  "Role360",
                  JSON.stringify({
                    isEmployee: true,
                    isManager: false,
                    isOrganizationLeader: false,
                    isPeer: false,
                  })
                );
                navigate("/employee360previousresponse");
              }}
            >
              View Response
            </Button>
          </>
        ),
      },
    ],
    [employeeForm, employeeRows]
  );

  //setting the person
  useEffect(() => {
    const fetchUserData = async () => {
      try {
        const response = await dispatch2(fetchData(`${ViewUser}${emailId}`));
        const person = response as any;
        dispatch(setFields(person));
        if (person && person.roles) {
          localStorage.setItem("role", JSON.stringify(person.roles));
          dispatch(setRole(person.role[0]));
        }
      } catch (error: any) {
        console.log(error);
      }
    };
    if (emailId && newToken) {
      dispatch(setIsLoading(true));
      fetchUserData();
      dispatch(setIsLoading(false));
    }
  }, [emailId, newToken]);

  //gets the previous responses for that specific employees
  useEffect(() => {
    dispatch(setIsLoading(true));
    const fetchPreviousEmployeeForms = async () => {
      try {
        const response = await dispatch2(
          fetchData(`${showPrevious360FormsEmployee}?is_employee_360=True`)
        );
        setEmployeeForm(response as FormType[]);

        dispatch(setIsLoading(false));
      } catch (error) {
        console.log(error);
        dispatch(setIsLoading(false));
      }
    };
    fetchPreviousEmployeeForms();

    const fetchPreviousPeerForms = async () => {
      try {
        const response = await dispatch2(
          fetchData(`${showPrevious360FormsEmployee}?is_peer=True`)
        );
        setPeerForm(response as FormType[]);

        dispatch(setIsLoading(false));
      } catch (error) {
        console.log(error);
        dispatch(setIsLoading(false));
      }
    };
    fetchPreviousPeerForms();
  }, []);

  return (
    <>
      <Box
        sx={{
          display: "flex",
          height: "100%", // Adjust the height as needed
        }}
      >
        <Box
          sx={{
            minWidth: 150, // Set the width for the left sidebar
            bgcolor: "background.paper",
          }}
        >
          <Tabs
            centered
            value={value}
            onChange={handleChange}
            orientation="vertical"
            textColor="inherit"
            indicatorColor="secondary"
            aria-label="secondary tabs example"
          >
            <Tab
              {...a11yProps(0)}
              label={
                <Badge
                  sx={{ p: 0.5 }}
                  // badgeContent={
                  //   employeeFormCount !== 0 ? employeeFormCount : null
                  // }
                >
                  Self Evaluation
                </Badge>
              }
            ></Tab>
            <Tab
              {...a11yProps(1)}
              label={
                <Badge
                  sx={{ p: 0.5 }}
                  // badgeContent={peerFormCount !== 0 ? peerFormCount : null}
                >
                  Peer Review
                </Badge>
              }
            />
          </Tabs>
        </Box>
        <Box
          sx={{
            flex: 1,
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <CustomTabPanel index={0} value={value}>
            <Paper sx={{ m: 2 }} style={{ width: "fit-content" }}>
              <DataGrid
                autoHeight
                loading={isLoading}
                getRowId={(row) => row.index}
                disableColumnMenu
                sx={{
                  width: "100%",
                  height: "100%",
                  "--DataGrid-overlayHeight": "50vh",
                }}
                initialState={{
                  pagination: {
                    paginationModel: {
                      pageSize: 10,
                    },
                  },
                }}
                pagination
                pageSizeOptions={[5, 10, 15]}
                rows={employeeRows}
                columns={employeeColumns}
                disableColumnSelector
                disableDensitySelector
                slots={{
                  toolbar: GridToolbar,
                  noResultsOverlay: CustomNoRowsOverlay,
                  noRowsOverlay: CustomNoRowsOverlay,
                }}
                slotProps={{
                  toolbar: {
                    csvOptions: { disableToolbarButton: true },
                    showQuickFilter: true,
                    printOptions: { disableToolbarButton: true },
                    style: { margin: "0.5rem" },
                  },
                }}
              />
            </Paper>
          </CustomTabPanel>
          <CustomTabPanel index={1} value={value}>
            <Paper
              sx={{
                width: "100%",
                display: "flex",
                justifyContent: "center",
              }}
            >
              <DataGrid
                autoHeight
                initialState={{
                  pagination: {
                    paginationModel: {
                      pageSize: 10,
                    },
                  },
                }}
                disableColumnMenu
                sx={{
                  width: "100%",
                  height: "100%",
                  "--DataGrid-overlayHeight": "50vh",
                }}
                pagination
                pageSizeOptions={[5, 10, 15]}
                rows={peerRows}
                columns={peerColumns}
                disableColumnFilter
                disableColumnSelector
                disableDensitySelector
                slots={{
                  toolbar: GridToolbar,
                  noRowsOverlay: CustomNoRowsOverlay,
                  noResultsOverlay: CustomNoRowsOverlay,
                }}
                slotProps={{
                  toolbar: {
                    csvOptions: { disableToolbarButton: true },
                    showQuickFilter: true,
                    printOptions: { disableToolbarButton: true },
                    style: { margin: "0.5rem" },
                  },
                }}
                getRowId={(row) => row.team_id}
              />
            </Paper>
          </CustomTabPanel>
        </Box>
      </Box>
    </>
  );
};

export default ThreeSixtyEmployeePreviousResponses;
