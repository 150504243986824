import { BrowserRouter } from "react-router-dom";
import "./App.css";
import Sidebar from "./components/Page Component/Sidebar";
import { Route, Routes } from "react-router-dom";
import { Link } from "./Routes/Link";

import { useDispatch, useSelector } from "react-redux";
import { useEffect } from "react";
import { setIsLoggedIn } from "./store/AuthSlice";
import { createTheme, ThemeProvider } from "@mui/material";
import { ChakraProvider } from "@chakra-ui/react";

const theme = createTheme({
  typography: {
    fontFamily: ["Signika Negative"].join(","),
    // fontSize: "1rem",
  },
  palette: {
    primary: {
      main: "#007300",
      contrastText: "#ffff",
      //button text white instead of black
    },
    background: {
      default: "#007300",
    },
    success: {
      main: "#007300",
    },
  },

  components: {
    MuiIconButton: {
      styleOverrides: {
        colorPrimary: "#007300",
      },
    },
    MuiBadge: {
      styleOverrides: {
        colorPrimary: "#007300",
      },
    },
    MuiAvatar: {
      styleOverrides: {
        root: {
          backgroundColor: "#007300",
          color: "#ffffff",
        },
      },
    },
    MuiButton: {
      styleOverrides: {
        containedInfo: {
          backgroundColor: "#007300", // Override background color for contained success button
          "&:hover": {
            backgroundColor: "#007300", // Override hover color for contained success button
          },
        },
      },
    },
    MuiMenuItem: {
      styleOverrides: {
        root: {
          color: "#007300", // Change text color for MuiMenuItem
        },
      },
    },
    MuiTypography: {
      styleOverrides: {
        h1: {
          color: "#007300", // Override text color for success typography
        },
        h2: {
          color: "#007300", // Override text color for success typography
        },
        h3: {
          color: "#007300", // Override text color for success typography
        },
        h4: {
          color: "#007300", // Override text color for success typography
        },
        h5: {
          color: "#007300", // Override text color for success typography
        },
        h6: {
          color: "#007300", // Override text color for success typography
        },
      },
    },
    // Add more component overrides as needed
  },
});
function App() {
  // const checkToken=():boolean => {
  // 	if(token&&islogged){return true}
  // 	return false
  // }
  // console.log(process.env.REACT_APP_API_ENDPOINT_PROD);
  // console.log(process.env.REACT_APP_API_ENDPOINT_LOCAL);

  return (
    <>
      <ThemeProvider theme={theme}>
        <Link />
      </ThemeProvider>
    </>
  );
}

export default App;
