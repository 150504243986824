import { Box, Tab, Tabs } from "@mui/material";
import React from "react";
import { CustomTabPanel, a11yProps } from "../../components/TabPanel/TabPanel";
import DownloadSkillsReview from "./DownloadSkillsReview";
import ReviewEmployeesSkills from "./ReviewEmployeesSkills";
import SkillsView from "./SkillsView";
import SkillHistory from "./SkillHistory";

const ViewAllSkills = () => {
  const [value, setValue] = React.useState(0);
  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setValue(newValue);
  };
  const roleOfEmployee = localStorage.getItem("role");

  return (
    <Box
      sx={{
        display: "flex",
        height: "100%", // Adjust the height as needed
        width: "97vw",
      }}
    >
      <Box
        sx={{
          minWidth: 120, // Set the width for the left sidebar
          bgcolor: "background.paper",
        }}
      >
        <Tabs
          centered
          value={value}
          onChange={handleChange}
          orientation="vertical"
          textColor="inherit"
          indicatorColor="secondary"
          aria-label="secondary tabs example"
        >
          <Tab {...a11yProps(0)} label={"Review Skills"} />

          <Tab {...a11yProps(1)} label={"Download Review"} />
          <Tab {...a11yProps(2)} label={"History"} />
          {roleOfEmployee?.includes("HR") && (
            <Tab {...a11yProps(3)} label={"Add Skills"} />
          )}
        </Tabs>
      </Box>
      <CustomTabPanel value={value} index={0}>
        <Box sx={{ width: "85vw" }}>
          <ReviewEmployeesSkills />
        </Box>
      </CustomTabPanel>
      <CustomTabPanel value={value} index={1}>
        <DownloadSkillsReview />
      </CustomTabPanel>
      <CustomTabPanel value={value} index={2}>
        <SkillHistory />
      </CustomTabPanel>
      <CustomTabPanel value={value} index={3}>
        {roleOfEmployee?.includes("HR") && <SkillsView />}
      </CustomTabPanel>
    </Box>
  );
};

export default ViewAllSkills;
