import { Box } from "@mui/material";
import React, { useEffect, useState } from "react";
import useAxiosInterceptor from "../../components/layout/AxiosInstance";
import { getResponseId } from "../../Service/Apis";
import { useDispatch, useSelector } from "react-redux";
import FIllHeiForm from "./FIllHeiForm";
import SaveFillForm from "./SaveFillForm";
import { setResponseId } from "../../store/FormSlice";
import {
  FetchDataFailureAction,
  FetchDataSuccessAction,
  fetchData,
  postData,
  putData,
} from "../../store/actions";
import { ThunkDispatch } from "@reduxjs/toolkit";
import { RootState } from "../../store/Store";

interface ResponseId {
  response_id: string;
}

const FillOrSaveAppraisalForm = () => {
  const [responseIds, setResponseIds] = useState<ResponseId>({
    response_id: "",
  });

  const token = localStorage.getItem("token");

  const formId =
    useSelector((state: any) => state.form.id) ||
    localStorage.getItem("formid");

  const formTeamId =
    useSelector((state: any) => state.form.formTeamId) ||
    localStorage.getItem("formTeamId");

  const axiosInstance = useAxiosInterceptor();
  const dispatch = useDispatch();
  const dispatch2: ThunkDispatch<
    RootState,
    null,
    FetchDataSuccessAction | FetchDataFailureAction
  > = useDispatch();

  const responseId = useSelector((state: any) => state.form.response_id);

  // Check for the response id is generated or not if response id is generated then go to Save and submit form else go to save form.
  useEffect(() => {
    const fetchResponseId = async () => {
      try {
        const response = await dispatch2(
          fetchData(`${getResponseId}${formTeamId}`)
        );

        var response_id = response as any;
        setResponseIds(response as ResponseId);
        dispatch(setResponseId(response_id.response_id));
      } catch (error) {
        console.log(error);
      }
    };

    fetchResponseId();
  }, []);

  return (
    <>{responseIds.response_id !== null ? <SaveFillForm /> : <FIllHeiForm />}</>
  );
};

export default FillOrSaveAppraisalForm;
