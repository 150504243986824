import React from "react";

import { Box, Tab, Tabs } from "@mui/material";

import Navbar from "../../components/Page Component/Navbar";
import Footer from "../../components/Page Component/Footer";
import Sidebar from "../../components/Page Component/Sidebar";
import ReviewForm from "./ReviewForm";
import { CustomTabPanel, a11yProps } from "../../components/TabPanel/TabPanel";
import ThreeSixtyManagerDashboard from "./ThreeSixtyForm/ThreeSixtyManagerDashboard";
import {
  ToastVariant,
  useCustomToast,
} from "../../components/Methods/SnackBar";
import FeedBackFormManagerDashboard from "./FeedBackForm/FeedBackFormManagerDashboard";

const ManagerDashboaard = () => {
  const [value, setValue] = React.useState(0);

  const showToast = useCustomToast();
  const handleShowToast = (
    message: string,
    variant: ToastVariant,
    isCloseable: boolean
  ) => {
    showToast(message, variant, isCloseable);
  };

  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    const tempElement = document.createElement("div");
    tempElement.innerHTML = event.currentTarget.innerHTML;
    const tabLabel = tempElement.innerText;

    // Clean up the temporary element
    tempElement.remove();
    setValue(newValue);
    handleShowToast(`Switched to ${tabLabel}`, "info", true);
  };
  return (
    <>
      <Box>
        <Navbar />
      </Box>
      <Box sx={{ mt: 12, mb: 10 }}>
        <Tabs
          centered
          value={value}
          onChange={handleChange}
          textColor="inherit"
          indicatorColor="secondary"
          aria-label="secondary tabs example"
        >
          <Tab {...a11yProps(0)} label="Appraisal"></Tab>
          <Tab {...a11yProps(1)} label="360 &deg;" />
          <Tab {...a11yProps(2)} label="Feedback Form" />
        </Tabs>
        <CustomTabPanel value={value} index={0}>
          <ReviewForm />
        </CustomTabPanel>
        <CustomTabPanel value={value} index={1}>
          <ThreeSixtyManagerDashboard />
        </CustomTabPanel>
        <CustomTabPanel value={value} index={2}>
          <FeedBackFormManagerDashboard />
        </CustomTabPanel>
      </Box>
      <Box>
        <Footer />
      </Box>
    </>
  );
};

export default ManagerDashboaard;
