import { createSlice, PayloadAction } from "@reduxjs/toolkit";

export interface AuthState {
	token: string | null;
	isLoggedIn: boolean;
}

const initialState: AuthState = {
	token: null,
	isLoggedIn: localStorage.getItem("token") ? true : false,
};

const authSlice = createSlice({
	name: "auth",
	initialState,
	reducers: {
		setToken: (state, action: PayloadAction<string>) => {
			state.token = action.payload;
		},
		clearToken: (state) => {
			state.token = null;
		},
		setIsLoggedIn: (state, action: PayloadAction<boolean>) => {
			state.isLoggedIn = action.payload;
		},
	},
});

export const { setToken, clearToken, setIsLoggedIn } = authSlice.actions;

export default authSlice;
