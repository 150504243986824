import React, { useEffect, useState } from "react";

import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Card,
  Container,
  Grid,
  List,
  ListItem,
  Typography,
} from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";

import axios from "axios";
import useAxiosInterceptor from "../../components/layout/AxiosInstance";
import { useDispatch, useSelector } from "react-redux";

import { enqueueSnackbar } from "notistack";

import { subordinateDetails } from "../../Service/Apis";
import Navbar from "../../components/Page Component/Navbar";
import Footer from "../../components/Page Component/Footer";
import NothingToDisplayImage from "../../asset/NothingToDisplayImage.png";
import {
  ToastVariant,
  useCustomToast,
} from "../../components/Methods/SnackBar";
import { SubOrdinateType } from "./Types/SubordinateTypes";
import {
  FetchDataFailureAction,
  FetchDataSuccessAction,
  fetchData,
  patchData,
  postData,
  putData,
} from "../../store/actions";
import { ThunkDispatch } from "@reduxjs/toolkit";
import { RootState } from "../../store/Store";
import { setIsLoading } from "../../store/LoadingSlice";
import Skeletons from "../../components/Skeleton/Skeletons";
import NothingToDisplayComponent from "../../components/Data/NothingToShowComponent";

const Subordinates = () => {
  const [subordinate, setSubordinate] = useState<SubOrdinateType[]>([]); //getting details of the teams and employees
  const token = localStorage.getItem("token"); //getting token from local storage

  const axiosInstance = useAxiosInterceptor();
  const dispatch = useDispatch();
  const dispatch2: ThunkDispatch<
    RootState,
    null,
    FetchDataSuccessAction | FetchDataFailureAction
  > = useDispatch();

  const showToast = useCustomToast();
  const handleShowToast = (
    message: string,
    variant: ToastVariant,
    isCloseable: boolean
  ) => {
    showToast(message, variant, isCloseable);
  };

  const isLoading = useSelector((state: any) => state.loading.isLoading);

  //getting the team he is managing and seeting the state variables
  useEffect(() => {
    const fetchSubordinates = async () => {
      try {
        dispatch(setIsLoading(true));
        const response = await dispatch2(fetchData(subordinateDetails));
        setSubordinate(response as SubOrdinateType[]);
        dispatch(setIsLoading(false));
      } catch (error) {
        console.log(error);
        dispatch(setIsLoading(false));
      }
    };
    fetchSubordinates();
    const response = axiosInstance
      .get(subordinateDetails, {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      })
      .then((res) => {
        setSubordinate(res.data);
      })
      .catch((error) => {
        console.log(error);
        handleShowToast(error.response.data, "success", true);
      });
  }, []);

  if (isLoading) {
    return <Skeletons />;
  }

  if (subordinate.length === 0) {
    return (
      <>
        <Box>
          <Navbar />
        </Box>
        <Box sx={{ mt: 12, mb: 10 }}>
          <Grid
            item
            xs={12}
            sm={4}
            md={6}
            sx={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              p: 2,
            }}
          >
            <Box
            // sx={{
            // 	justifyContent: "center",
            // 	display: "flex",
            // 	width: "fit-content",
            // }}
            >
              <NothingToDisplayComponent />
            </Box>
          </Grid>
        </Box>
        <Box>
          <Footer />
        </Box>
      </>
    );
  }
  return (
    <>
      <Box>
        <Navbar />
      </Box>
      <Container sx={{ mt: 12, mb: 10 }}>
        <Box sx={{ display: "flex", justifyContent: "center" }}>
          <Box sx={{ m: 1, width: "40%" }}>
            {/*  accordian displaying the employees of the manager  */}
            {subordinate.map((data) => {
              return (
                <Accordion
                  elevation={11}
                  sx={{
                    m: 2,
                  }}
                >
                  <AccordionSummary
                    expandIcon={<ExpandMoreIcon className="expandIcon" />}
                  >
                    <Box>
                      <Typography variant="h5" fontWeight={"bold"}>
                        {data.project_name}
                      </Typography>
                    </Box>
                  </AccordionSummary>
                  <AccordionDetails>
                    <Box
                      className="header-background"
                      sx={{
                        display: "flex",
                        justifyContent: "center",
                      }}
                    >
                      <Typography paragraph fontWeight={"bold"}>
                        Team Details
                      </Typography>
                    </Box>
                    <Box sx={{ display: "flex", justifyContent: "center" }}>
                      <List>
                        {data.reporting_team_members.map((details, index) => {
                          return (
                            <ListItem id={details.employee_detail.first_name}>
                              <Typography>
                                {index + 1 + ")"}{" "}
                                {details.employee_detail.first_name}{" "}
                                {details.employee_detail.last_name}{" "}
                              </Typography>
                            </ListItem>
                          );
                        })}
                      </List>
                    </Box>
                  </AccordionDetails>
                </Accordion>
              );
            })}
          </Box>
        </Box>
      </Container>
      <Box>
        <Footer />
      </Box>
    </>
  );
};

export default Subordinates;
