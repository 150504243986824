import React, { useEffect, useMemo, useState } from "react";

import {
  Box,
  Button,
  Paper,
  Tab,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Tabs,
  Typography,
} from "@mui/material";

import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";

import { ThunkDispatch } from "@reduxjs/toolkit";
import { managerReview, viewResponseManager } from "../../Service/Apis";
import NothingToDisplayImage from "../../asset/NothingToDisplayImage.png";
import {
  ToastVariant,
  useCustomToast,
} from "../../components/Methods/SnackBar";
import Navbar from "../../components/Page Component/Navbar";
import useAxiosInterceptor from "../../components/layout/AxiosInstance";
import { postDataSuccess } from "../../store/AxiosSlice";
import { setResId } from "../../store/FormSlice";
import { RootState } from "../../store/Store";
import {
  FetchDataFailureAction,
  FetchDataSuccessAction,
  fetchData,
  putData,
} from "../../store/actions";
import { CustomTabPanel, a11yProps } from "../../components/TabPanel/TabPanel";
import { DataGrid, GridColDef, GridToolbar } from "@mui/x-data-grid";
import CustomNoRowsOverlay from "../../components/Data/NoRowsOverlayComponent";

interface TabPanelProps {
  children?: React.ReactNode;
  index: number;
  value: number;
}

export const labels: { [index: string]: string } = {
  0.5: "Poor",
  1: "Poor+",
  1.5: "Satisfactory",
  2: "Satisfactory+",
  2.5: "Good",
  3: "Good+",
  3.5: "Very Good",
  4: "Very Good+",
  4.5: "Excellent",
  5: "Excellent+",
};

interface ManagerReview {
  team_id: string;
  project_name: string;
  forms: [
    {
      form_id: string;
      form_name: string;
      project_name: string;
      team_id: string;
      responses: {
        response_id: string;
        employee_id: string;
        employee_name: string;
        manager_final_submit_done: boolean;
        response: [
          {
            answer_type: string;
            response_question_id: string;
            question_id: string;
            question_name: string;
            employee_response: string;
            employee_rating: string;
            employee_comment: string;
            manager_response: string;
            manager_comment: string;
          }
        ];
      };
    }
  ];
}

interface ManagerResponse {
  manager_comment: string;
  manager_response: number;
}

const style = {
  position: "absolute" as "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "fit-content",

  bgcolor: "background.paper",
  // border: "2px solid #000",
  // boxShadow: 24,
  overflow: "auto",
  maxHeight: "100%",
  pt: 2,
  px: 4,
  pb: 3,

  boxShadow: "0px 0px 10px 2px rgba(0, 100, 0, 0.5)",
};
const initialState = {
  manager_comment: "",
  manager_response: 0,
};
const ReviewForm = () => {
  const [response, setResponse] = useState<ManagerReview[]>([]);
  const [valuetab, setValuetab] = React.useState(0);
  const [count, setCount] = useState(0);

  const dispatch = useDispatch();
  const Navigate = useNavigate();

  const dispatch2: ThunkDispatch<
    RootState,
    null,
    FetchDataSuccessAction | FetchDataFailureAction
  > = useDispatch();

  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setValuetab(newValue);
  };

  const flattenedRows = response.flatMap((managerReview) =>
    managerReview.forms.map((form) => ({
      team_id: managerReview.team_id,
      project_name: managerReview.project_name,
      form_id: form.form_id,
      form_name: form.form_name,
      response_id: form.responses.response_id,
      employee_id: form.responses.employee_id,
      employee_name: form.responses.employee_name,
      manager_final_submit_done: form.responses.manager_final_submit_done, // Keep the inner responses array intact
    }))
  );

  const managerFinalSubmitDoneRows = flattenedRows
    .filter((row) => row.manager_final_submit_done)
    .map((row, index) => ({
      ...row,
      index: index,
      sr_no: index + 1,
    }));

  const managerFinalSubmitNotDoneRows = flattenedRows
    .filter((row) => !row.manager_final_submit_done)
    .map((row, index) => ({
      ...row,
      index: index,
      sr_no: index + 1,
    }));

  const managerFinalSubmitNotDoneColumns: GridColDef[] = useMemo(
    () => [
      {
        headerName: "Sr No.",
        field: "sr_no",
        align: "center",
        headerAlign: "center",
        headerClassName: "header-background",
        width: 125,
        renderHeader: () => (
          <strong className="custom-header" style={{ fontSize: 15 }}>
            Sr No.
          </strong>
        ),
      },
      {
        headerName: "Form Name",
        field: "form_name",
        align: "center",
        headerAlign: "center",
        headerClassName: "header-background",
        width: 165,
        renderHeader: () => (
          <strong className="custom-header" style={{ fontSize: 15 }}>
            Form Name
          </strong>
        ),
      },
      {
        headerName: "Employee Name",
        field: "employee_name",
        align: "center",
        headerAlign: "center",
        headerClassName: "header-background",
        width: 165,
        renderHeader: () => (
          <strong className="custom-header" style={{ fontSize: 15 }}>
            Employee Name
          </strong>
        ),
      },
      {
        headerName: "Project Name",
        field: "project_name",
        align: "center",
        headerAlign: "center",
        headerClassName: "header-background",
        width: 195,
        renderHeader: () => (
          <strong className="custom-header" style={{ fontSize: 15 }}>
            Project Name
          </strong>
        ),
      },
      {
        headerName: "Options",
        field: "options",
        align: "center",
        headerAlign: "center",
        headerClassName: "header-background",
        width: 175,
        renderHeader: () => (
          <strong className="custom-header" style={{ fontSize: 15 }}>
            Options
          </strong>
        ),
        renderCell: (params) => (
          <>
            <Box>
              <Button
                id={`view_form_${params.row.index + 1}`}
                size="small"
                onClick={() => {
                  // handleOpen(index);
                  Navigate("/managerreview");
                  dispatch(setResId(params.row.response_id));
                }}
              >
                view
              </Button>
            </Box>
          </>
        ),
      },
    ],
    [response, flattenedRows]
  );

  const managerFinalSubmitDoneColumns: GridColDef[] = useMemo(
    () => [
      {
        headerName: "Sr No.",
        field: "sr_no",
        align: "center",
        headerAlign: "center",
        headerClassName: "header-background",
        width: 125,
        renderHeader: () => (
          <strong className="custom-header" style={{ fontSize: 15 }}>
            Sr No.
          </strong>
        ),
      },
      {
        headerName: "Form Name",
        field: "form_name",
        align: "center",
        headerAlign: "center",
        headerClassName: "header-background",
        width: 165,
        renderHeader: () => (
          <strong className="custom-header" style={{ fontSize: 15 }}>
            Form Name
          </strong>
        ),
      },
      {
        headerName: "Employee Name",
        field: "employee_name",
        align: "center",
        headerAlign: "center",
        headerClassName: "header-background",
        width: 165,
        renderHeader: () => (
          <strong className="custom-header" style={{ fontSize: 15 }}>
            Employee Name
          </strong>
        ),
      },
      {
        headerName: "Project Name",
        field: "project_name",
        align: "center",
        headerAlign: "center",
        headerClassName: "header-background",
        width: 195,
        renderHeader: () => (
          <strong className="custom-header" style={{ fontSize: 15 }}>
            Project Name
          </strong>
        ),
      },
      {
        headerName: "Options",
        field: "options",
        align: "center",
        headerAlign: "center",
        headerClassName: "header-background",
        width: 175,
        renderHeader: () => (
          <strong className="custom-header" style={{ fontSize: 15 }}>
            Options
          </strong>
        ),
        renderCell: (params) => (
          <>
            <Box>
              <Button
                id={`view_form_${params.row.index + 1}`}
                size="small"
                onClick={() => {
                  // handleOpen(index);
                  Navigate("/managerreview");
                  dispatch(setResId(params.row.response_id));
                }}
              >
                Edit
              </Button>
            </Box>
          </>
        ),
      },
    ],
    [response, flattenedRows]
  );
  // Get Employee Responses.
  useEffect(() => {
    const fetchManagerResponse = async () => {
      try {
        const response = await dispatch2(fetchData(viewResponseManager));
        setResponse(response as ManagerReview[]);
      } catch (error) {
        console.log(error);
      }
    };
    fetchManagerResponse();
  }, [count]);

  return (
    <>
      <Box
        sx={{
          display: "flex",
          height: "100%", // Adjust the height as needed
        }}
      >
        <Box
          sx={{
            // display: "flex",
            // justifyContent: "center",
            // alignItems: "center",
            minWidth: 150, // Set the width for the left sidebar
            bgcolor: "background.paper",
          }}
        >
          <Tabs
            centered
            value={valuetab}
            orientation="vertical"
            onChange={handleChange}
            aria-label="basic tabs example"
            textColor="inherit"
          >
            <Tab label="Pending Forms" {...a11yProps(0)} />
            <Tab label="submitted forms" {...a11yProps(1)} />
          </Tabs>
        </Box>
        <Box
          sx={{
            flex: 1,
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <CustomTabPanel value={valuetab} index={0}>
            <Box sx={{ mb: 0 }}>
              <Box
                sx={{ display: "flex", justifyContent: "center", m: 2, p: 2 }}
              >
                <Typography variant="h4">Your Team Member Responses</Typography>
              </Box>
              <Box
                sx={{ display: "flex", justifyContent: "center", m: 2, p: 2 }}
              >
                <Box>
                  <Paper sx={{ m: 2 }} style={{ width: "fit-content" }}>
                    <DataGrid
                      autoHeight
                      getRowId={(row) => row.index}
                      disableColumnMenu
                      sx={{
                        width: "100%",
                        height: "100%",
                        "--DataGrid-overlayHeight": "50vh",
                      }}
                      initialState={{
                        pagination: {
                          paginationModel: {
                            pageSize: 10,
                          },
                        },
                      }}
                      pagination
                      pageSizeOptions={[5, 10, 15]}
                      rows={managerFinalSubmitNotDoneRows}
                      columns={managerFinalSubmitNotDoneColumns}
                      disableColumnSelector
                      disableDensitySelector
                      slots={{
                        toolbar: GridToolbar,
                        noResultsOverlay: CustomNoRowsOverlay,
                        noRowsOverlay: CustomNoRowsOverlay,
                      }}
                      slotProps={{
                        toolbar: {
                          csvOptions: { disableToolbarButton: true },
                          showQuickFilter: true,
                          printOptions: { disableToolbarButton: true },
                          style: { margin: "0.5rem" },
                        },
                      }}
                    />
                  </Paper>
                </Box>
              </Box>
            </Box>
          </CustomTabPanel>
          <CustomTabPanel value={valuetab} index={1}>
            <Box sx={{ mb: 10 }}>
              <Box
                sx={{ display: "flex", justifyContent: "center", m: 2, p: 2 }}
              >
                <Typography variant="h4">Your Responses</Typography>
              </Box>
              <Box
                sx={{ display: "flex", justifyContent: "center", m: 2, p: 2 }}
              >
                <Box>
                  <Paper sx={{ m: 2 }} style={{ width: "fit-content" }}>
                    <DataGrid
                      autoHeight
                      getRowId={(row) => row.index}
                      disableColumnMenu
                      sx={{
                        width: "100%",
                        height: "100%",
                        "--DataGrid-overlayHeight": "50vh",
                      }}
                      initialState={{
                        pagination: {
                          paginationModel: {
                            pageSize: 10,
                          },
                        },
                      }}
                      pagination
                      pageSizeOptions={[5, 10, 15]}
                      rows={managerFinalSubmitDoneRows}
                      columns={managerFinalSubmitDoneColumns}
                      disableColumnSelector
                      disableDensitySelector
                      slots={{
                        toolbar: GridToolbar,
                        noResultsOverlay: CustomNoRowsOverlay,
                        noRowsOverlay: CustomNoRowsOverlay,
                      }}
                      slotProps={{
                        toolbar: {
                          csvOptions: { disableToolbarButton: true },
                          showQuickFilter: true,
                          printOptions: { disableToolbarButton: true },
                          style: { margin: "0.5rem" },
                        },
                      }}
                    />
                  </Paper>
                </Box>
              </Box>
            </Box>
          </CustomTabPanel>
        </Box>
      </Box>
    </>
  );
};

export default ReviewForm;
