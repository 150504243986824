import { useEffect, useState } from "react";

import { Box, Button, Card, Container, Grid, Typography } from "@mui/material";

import useAxiosInterceptor from "../../components/layout/AxiosInstance";

import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";

import { ViewAllResponses } from "../../Service/Apis";
import {
  ToastVariant,
  useCustomToast,
} from "../../components/Methods/SnackBar";
import Footer from "../../components/Page Component/Footer";
import Navbar from "../../components/Page Component/Navbar";
import Skeletons from "../../components/Skeleton/Skeletons";
import { setId } from "../../store/FormSlice";
import { setIsLoading } from "../../store/LoadingSlice";
import {
  FetchDataFailureAction,
  FetchDataSuccessAction,
  fetchData,
  postData,
  putData,
} from "../../store/actions";
import { ThunkDispatch } from "@reduxjs/toolkit";
import { RootState } from "../../store/Store";

const Dashboard = () => {
  const [form, setForm] = useState([]);
  // const [reviewForm, setReviewForm] = useState([]);
  const dispatch = useDispatch();
  const Navigate = useNavigate();
  const dispatch2: ThunkDispatch<
    RootState,
    null,
    FetchDataSuccessAction | FetchDataFailureAction
  > = useDispatch();

  const axiosInstance = useAxiosInterceptor();

  const formId = useSelector((state: any) => state.form.id);
  const isloading = useSelector((state: any) => state.loading.isLoading);

  const showToast = useCustomToast();
  const handleShowToast = (
    message: string,
    variant: ToastVariant,
    isCloseable: boolean
  ) => {
    showToast(message, variant, isCloseable);
  };

  const handleSubmit = (formid: string) => {
    dispatch(setId(formid));

    Navigate("/final");
  };
  //getting reponses from employees for specific employees he manages
  useEffect(() => {
    const fetchAllResponses = async () => {
      try {
        dispatch(setIsLoading(true));
        const response = await dispatch2(fetchData(ViewAllResponses));
        setForm(response as any);
        dispatch(setIsLoading(false));
      } catch (error) {
        console.log(error);
        dispatch(setIsLoading(false));
      }
    };
    fetchAllResponses();

  }, []);

  // useEffect(() => {
  //   const response = axiosInstance
  //     .get(`http://localhost:8000/?form_id=${formId}`, {
  //       headers: {
  //         "Content-Type": "application/json",
  //       },
  //     })
  //     .then((res) => {
  //       setReviewForm(res.data);
  //     });
  // }, []);
  if (isloading) {
    return (
      <>
        <Skeletons />
      </>
    );
  }
  return (
    <>
      <Box>
        <Navbar />
      </Box>

      <Box sx={{ justifyContent: "space-around", display: "flex", mt: 12 }}>
        <Container
          sx={{
            display: "contents",
            // alignItems: "center",
            justifycontent: "right",
          }}
        >
          <Box
            sx={{
              display: "flex",
              justifyContent: "center",
              width: { xs: "75%", sm: "75%", md: "55%" },

              mb: 12,
            }}
          >
            <Grid
              container
              spacing={{ xs: 2, md: 3 }}
              columns={{ xs: 4, sm: 8, md: 12 }}
              sx={{
                display: { xs: "block", sm: "block", md: "flex" },
                justifyContent: "center",
              }}
            >
              {form.map((data: any) => {
                return (
                  <Grid item xs={12} sm={4} md={6} sx={{}}>
                    <Box>
                      <Card
                        elevation={11}
                        sx={{
                          m: 1,
                          p: 1,
                          "&:hover": { boxShadow: 20 },
                          display: "flex",
                          flexDirection: "column",
                          height: "100%",
                          boxShadow: "0px 0px 10px 2px rgba(0, 100, 0, 0.5)",
                        }}
                      >
                        <Box
                          sx={{
                            display: "flex",
                            justifyContent: "center",
                            m: 1,
                            p: 1,
                          }}
                        >
                          <Typography
                            color={"green"}
                            fontWeight={"bold"}
                            variant="h6"
                          >
                            {data.form_name}
                          </Typography>
                        </Box>
                        <Box sx={{ m: 1, p: 1 }}>
                          <Typography paragraph>
                            Manager Deadline : {data.manager_deadline}
                          </Typography>
                          <Typography paragraph>
                            Employee Deadline : {data.employee_deadline}
                          </Typography>
                        </Box>
                        <Box
                          sx={{
                            display: "flex",
                            justifyContent: "center",
                            m: 1,
                            p: 1,
                          }}
                        >
                          <Button
                            variant="contained"
                            size="small"
                            
                            onClick={() => {
                              handleSubmit(data.form_id);
                            }}
                          >
                            Show Reviews
                          </Button>
                        </Box>
                      </Card>
                    </Box>
                  </Grid>
                );
              })}
            </Grid>
          </Box>
        </Container>
      </Box>

      <Footer></Footer>
    </>
  );
};

export default Dashboard;
