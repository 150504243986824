import { Badge, Box, Button, Paper, Tab, Tabs } from "@mui/material";
import { ThunkDispatch } from "@reduxjs/toolkit";
import React, { useEffect, useMemo, useState } from "react";
import { useDispatch } from "react-redux";
import {
  CustomTabPanel,
  a11yProps,
} from "../../../components/TabPanel/TabPanel";
import { RootState } from "../../../store/Store";
import {
  FetchDataFailureAction,
  FetchDataSuccessAction,
  fetchData,
} from "../../../store/actions";
import {
  showEmployeeAssigned360Form,
  showPeerAssigned360Form,
} from "../../../Service/Apis";
import { DataGrid, GridColDef, GridToolbar } from "@mui/x-data-grid";
import CustomNoRowsOverlay from "../../../components/Data/NoRowsOverlayComponent";
import { setFormTeamId, setId } from "../../../store/FormSlice";
import { useNavigate } from "react-router-dom";

interface AssignedForms {
  team_id: string;
  project_name: string;
  manager_name: string;
  manager: string;
  form_team_id: string;
  peer_number: string;
  form_id: string;
  form_name: string;
  response: [
    {
      section_id: string;
      form_id: string;
      section: null;
      categories: [
        {
          category_id: string;
          section_id: string;
          category: string;
          is_rating_required: boolean;
          questions: [
            {
              question_id: string;
              category_id: string;
              form_id: string;
              question: string;
              answer_type: string;
              answer_choices: [
                {
                  option_id: string;
                  answer_value: string;
                }
              ];
            }
          ];
        }
      ];
    }
  ];
  questions_old_format: [];
  employee_deadline: string;
  has_submitted: boolean;
  final_submit_done: boolean;
  peer_submit_done: boolean;
  is_appraisal: boolean;
  is_hierarchical: boolean;
  is_360: boolean;
}

const ThreeSixtyEmployeeDashboard = () => {
  const [value, setValue] = React.useState(0);
  const [peerAssignedForms, setPeerAssignedForms] = useState<AssignedForms[]>(
    []
  );
  const [employeeAssignedForms, setEmployeeAssignedForms] = useState<
    AssignedForms[]
  >([]);

  const [peerFormCount, setPeerFormCount] = useState<number | null>(null);
  const [employeeFormCount, setEmployeeFormCount] = useState<number | null>(
    null
  );

  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setValue(newValue);
  };

  const navigate = useNavigate();
  const dispatch = useDispatch();
  const dispatch2: ThunkDispatch<
    RootState,
    null,
    FetchDataSuccessAction | FetchDataFailureAction
  > = useDispatch();

  // const mapPeerForms = peerAssignedForms.flatMap((peerForms) =>
  //   peerForms.forms.flatMap((form: any) => ({
  //     team_id: peerForms.team_id,
  //     project_name: peerForms.project_name,
  //     manager_name: peerForms.manager_name,
  //     manager: peerForms.manager,
  //     form_team_id: form.form_team_id,
  //     form_id: form.form_id,
  //     form_name: form.form_name,
  //     employee_deadline: form.employee_deadline,
  //     has_submitted: form.has_submitted,
  //     final_submit_done: form.final_submit_done,
  //     is_appraisal: form.is_appraisal,
  //     is_hierarchical: form.is_hierarchical,
  //     is_360: form.is_360,
  //   }))
  // );

  // const mapEmployeeForms = employeeAssignedForms.flatMap((peerForms) =>
  //   peerForms.forms.flatMap((form) => ({
  //     team_id: peerForms.team_id,
  //     project_name: peerForms.project_name,
  //     manager_name: peerForms.manager_name,
  //     manager: peerForms.manager,
  //     form_team_id: peerForms.form_team_id,
  //     form_id: form.form_id,
  //     form_name: form.form_name,
  //     employee_deadline: form.employee_deadline,
  //     has_submitted: form.has_submitted,
  //     final_submit_done: form.final_submit_done,
  //     is_appraisal: form.is_appraisal,
  //     is_hierarchical: form.is_hierarchical,
  //     is_360: form.is_360,
  //   }))
  // );

  const peerRows = peerAssignedForms.map((row, index) => ({
    ...row,
    index: index,
    sr_no: index + 1,
  }));

  const employeeRows = employeeAssignedForms.map((row, index) => ({
    ...row,
    index: index,
    sr_no: index + 1,
  }));

  const peerColumns: GridColDef[] = [
    {
      field: "sr_no",
      headerName: "Sr. No",
      renderHeader: () => (
        <strong
          className="custom-header"
          style={{ fontSize: 16, textAlign: "center" }}
        >
          Sr No.
        </strong>
      ),
      width: 155,
      align: "center",
      headerAlign: "center",
      headerClassName: "header-background",
    },
    {
      field: "form_name",
      headerName: "Form Name",
      renderHeader: () => (
        <strong
          className="custom-header"
          style={{ fontSize: 16, textAlign: "center" }}
        >
          Form Name
        </strong>
      ),
      width: 155,
      align: "center",
      headerAlign: "center",
      headerClassName: "header-background",
    },
    {
      field: "employee_name",
      headerName: "Employee Name",
      renderHeader: () => (
        <strong
          className="custom-header"
          style={{ fontSize: 16, textAlign: "center" }}
        >
          Employee Name
        </strong>
      ),
      width: 155,
      align: "center",
      headerAlign: "center",
      headerClassName: "header-background",
    },
    {
      field: "manager",
      headerName: "Manager Name",
      renderHeader: () => (
        <strong
          className="custom-header"
          style={{ fontSize: 16, textAlign: "center" }}
        >
          Manager Name
        </strong>
      ),
      width: 155,
      align: "center",
      headerAlign: "center",
      headerClassName: "header-background",
    },
    {
      field: "employee_deadline",
      headerName: "Deadline",
      renderHeader: () => (
        <strong
          className="custom-header"
          style={{ fontSize: 16, textAlign: "center" }}
        >
          Deadline
        </strong>
      ),
      width: 155,
      align: "center",
      headerAlign: "center",
      headerClassName: "header-background",
    },
    {
      field: "action",
      headerName: "Actions",
      renderHeader: () => (
        <strong
          className="custom-header"
          style={{ fontSize: 16, textAlign: "center" }}
        >
          Actions
        </strong>
      ),
      width: 155,
      align: "center",
      headerAlign: "center",
      headerClassName: "header-background",
      renderCell: (params: any) => (
        <Button
          size="small"
          
          onClick={() => {
            dispatch(setFormTeamId(params.row.form_team_id));
            localStorage.setItem("formTeamId", params.row.form_team_id);
            localStorage.setItem("teamId", params.row.team_id);
            dispatch(setId(params.row.form_id));
            localStorage.setItem("formid", params.row.form_id);
            // dispatch(setId(params.row.response_id));
            localStorage.setItem("responseId", params.row.response_id);
            localStorage.setItem("peerNumber", params.row.peer_number);
            localStorage.setItem(
              "Role360",
              JSON.stringify({
                isEmployee: false,
                isManager: false,
                isOrganizationLeader: false,
                isPeer: true,
              })
            );
            localStorage.setItem(
              "feedbackRole",
              JSON.stringify({
                is_employee_feedback: false,
                is_manager_feedback: false,
              })
            );
            navigate("/fillForm");
          }}
        >
          Go To Form
        </Button>
      ),
    },
  ];

  const employeeColumns: GridColDef[] = [
    {
      field: "sr_no",
      headerName: "Sr. No",
      renderHeader: () => (
        <strong
          className="custom-header"
          style={{ fontSize: 16, textAlign: "center" }}
        >
          Sr No.
        </strong>
      ),
      width: 155,
      align: "center",
      headerAlign: "center",
      headerClassName: "header-background",
    },
    {
      field: "form_name",
      headerName: "Form Name",
      renderHeader: () => (
        <strong
          className="custom-header"
          style={{ fontSize: 16, textAlign: "center" }}
        >
          Form Name
        </strong>
      ),
      width: 155,
      align: "center",
      headerAlign: "center",
      headerClassName: "header-background",
    },
    {
      field: "manager",
      headerName: "Manager Name",
      renderHeader: () => (
        <strong
          className="custom-header"
          style={{ fontSize: 16, textAlign: "center" }}
        >
          Manager Name
        </strong>
      ),
      width: 155,
      align: "center",
      headerAlign: "center",
      headerClassName: "header-background",
    },
    {
      field: "employee_deadline",
      headerName: "Deadline",
      renderHeader: () => (
        <strong
          className="custom-header"
          style={{ fontSize: 16, textAlign: "center" }}
        >
          Deadline
        </strong>
      ),
      width: 155,
      align: "center",
      headerAlign: "center",
      headerClassName: "header-background",
    },
    {
      field: "action",
      headerName: "Actions",
      renderHeader: () => (
        <strong
          className="custom-header"
          style={{ fontSize: 16, textAlign: "center" }}
        >
          Actions
        </strong>
      ),
      width: 155,
      align: "center",
      headerAlign: "center",
      headerClassName: "header-background",
      renderCell: (params: any) => (
        <Button
          size="small"
          onClick={() => {
            dispatch(setFormTeamId(params.row.form_team_id));
            localStorage.setItem("formTeamId", params.row.form_team_id);
            dispatch(setId(params.row.form_id));
            localStorage.setItem("responseId", params.row.response_id);
            localStorage.setItem("formid", params.row.form_id);
            localStorage.setItem(
              "Role360",
              JSON.stringify({
                isEmployee: true,
                isManager: false,
                isOrganizationLeader: false,
                isPeer: false,
              })
            );
            localStorage.setItem(
              "feedbackRole",
              JSON.stringify({
                is_employee_feedback: false,
                is_manager_feedback: false,
              })
            );
            navigate("/fillForm");
          }}
        >
          Go To Form
        </Button>
      ),
    },
  ];

  useEffect(() => {
    const fetchForms = async () => {
      try {
        const response = await dispatch2(
          fetchData(`${showPeerAssigned360Form}`)
        );
        setPeerFormCount(
          (response as AssignedForms[]).reduce(
            (count, formItem) =>
              count + (formItem.peer_submit_done === false ? 1 : 0),
            0
          )
        );
        setPeerAssignedForms(response as AssignedForms[]);
      } catch (error) {
        console.log(error);
      }
    };
    const fetchEmployeeForms = async () => {
      try {
        const response = await dispatch2(
          fetchData(`${showEmployeeAssigned360Form}`)
        );
        setEmployeeFormCount(
          (response as AssignedForms[]).reduce(
            (count, formItem) =>
              count + (formItem.final_submit_done === false ? 1 : 0),
            0
          )
        );
        setEmployeeAssignedForms(response as AssignedForms[]);
      } catch (error) {
        console.log(error);
      }
    };
    fetchForms();
    fetchEmployeeForms();
  }, []);

  return (
    <>
      <Box
        sx={{
          display: "flex",
          height: "100%", // Adjust the height as needed
        }}
      >
        <Box
          sx={{
            minWidth: 150, // Set the width for the left sidebar
            bgcolor: "background.paper",
          }}
        >
          <Tabs
            centered
            value={value}
            onChange={handleChange}
            orientation="vertical"
            textColor="inherit"
            indicatorColor="secondary"
            aria-label="secondary tabs example"
          >
            <Tab
              {...a11yProps(0)}
              label={
                <Badge
                  color="primary"
                  sx={{ p: 0.5 }}
                  badgeContent={
                    employeeFormCount !== 0 ? employeeFormCount : null
                  }
                >
                  Self Evaluation
                </Badge>
              }
            ></Tab>
            <Tab
              {...a11yProps(1)}
              label={
                <Badge
                  color="primary"
                  sx={{ p: 0.5 }}
                  badgeContent={peerFormCount !== 0 ? peerFormCount : null}
                >
                  Peer Review
                </Badge>
              }
            />
          </Tabs>
        </Box>
        <Box
          sx={{
            flex: 1,
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <CustomTabPanel index={0} value={value}>
            <Paper
              sx={{
                width: "100%",
                display: "flex",
                justifyContent: "center",
              }}
            >
              <DataGrid
                autoHeight
                initialState={{
                  pagination: {
                    paginationModel: {
                      pageSize: 10,
                    },
                  },
                }}
                disableColumnMenu
                sx={{
                  width: "100%",
                  height: "100%",
                  "--DataGrid-overlayHeight": "50vh",
                }}
                pagination
                pageSizeOptions={[5, 10, 15]}
                rows={employeeRows}
                columns={employeeColumns}
                disableColumnFilter
                disableColumnSelector
                disableDensitySelector
                slots={{
                  toolbar: GridToolbar,
                  noRowsOverlay: CustomNoRowsOverlay,
                  noResultsOverlay: CustomNoRowsOverlay,
                }}
                slotProps={{
                  toolbar: {
                    csvOptions: { disableToolbarButton: true },
                    showQuickFilter: true,
                    printOptions: { disableToolbarButton: true },
                    style: { margin: "0.5rem" },
                  },
                }}
                getRowId={(row) => row.index}
              />
            </Paper>
          </CustomTabPanel>
          <CustomTabPanel index={1} value={value}>
            <Paper
              sx={{
                width: "100%",
                display: "flex",
                justifyContent: "center",
              }}
            >
              <DataGrid
                autoHeight
                initialState={{
                  pagination: {
                    paginationModel: {
                      pageSize: 10,
                    },
                  },
                }}
                disableColumnMenu
                sx={{
                  width: "100%",
                  height: "100%",
                  "--DataGrid-overlayHeight": "50vh",
                }}
                pagination
                rows={peerRows}
                columns={peerColumns}
                disableColumnFilter
                disableColumnSelector
                disableDensitySelector
                slots={{
                  toolbar: GridToolbar,
                  noRowsOverlay: CustomNoRowsOverlay,
                  noResultsOverlay: CustomNoRowsOverlay,
                }}
                slotProps={{
                  toolbar: {
                    csvOptions: { disableToolbarButton: true },
                    showQuickFilter: true,
                    printOptions: { disableToolbarButton: true },
                    style: { margin: "0.5rem" },
                  },
                }}
                getRowId={(row) => row.index}
              />
            </Paper>
          </CustomTabPanel>
        </Box>
      </Box>
    </>
  );
};

export default ThreeSixtyEmployeeDashboard;
