import { createSlice, PayloadAction } from "@reduxjs/toolkit";

type PerState = {
	first_name: string;
	last_name: string;
	roles: string[];
	email: string;
	employee_code: string;
	designation: string;
	id: string;
	teams: [
		{
			team_id: string;
			project_name: string;
		}
	];
};

export interface PersonState {
	perstate: PerState;
}

const initialState: PersonState = {
	perstate: {
		id: "",
		first_name: "",
		last_name: "",
		roles: [],
		email: "",
		employee_code: "",
		designation: "",
		teams: [
			{
				team_id: "",
				project_name: "",
			},
		],
	},
};

const personSlice = createSlice({
	name: "person",
	initialState,
	reducers: {
		setFields: (state, action: PayloadAction<PerState>) => {
			state.perstate = action.payload;
			localStorage.setItem("id", action.payload.id);
			localStorage.setItem("first_name", action.payload.first_name);
			localStorage.setItem("last_name", action.payload.last_name);
			localStorage.setItem("designation", action.payload.designation);
			localStorage.setItem("employee_code", action.payload.employee_code);
		},
	},
});

export const { setFields } = personSlice.actions;

export default personSlice;
