import React, { useState } from "react";

import { useDispatch, useSelector } from "react-redux";

import Button from "@mui/material/Button";
import { TextField, Link, CircularProgress } from "@mui/material";
import { Box } from "@mui/material";

import axios from "axios";
import useAxiosInterceptor from "../layout/AxiosInstance";

import { saveAs } from "file-saver";
import * as XLSX from "xlsx";

import { enqueueSnackbar } from "notistack";

import { setisOpen } from "../../store/ModalSlice";
import { addBulkAttendanceExcel, addBulkEmployee } from "../../Service/Apis";
import { setInPageLoading } from "../../store/LoadingSlice";
import { ToastVariant, useCustomToast } from "../Methods/SnackBar";
import { Navigate, useNavigate } from "react-router-dom";

const headers = [
  "employee_code",
  "month_year",
  "present_days",
  "paid_leaves",
  "sick_leaves",
  "leave_without_pay",
  "regularized_days",
];

const AttendanceExcelUpload = () => {
  const [selectedFile, setSelectedFile] = useState<File | null>(null);
  const [error, setError] = useState<Boolean>(true);

  const dispatch = useDispatch();
  const navigate = useNavigate();
  const ispageloading = useSelector(
    (state: any) => state.loading.inPageLoading
  );

  const showToast = useCustomToast();
  const token = localStorage.getItem("token");
  const axiosInstance = useAxiosInterceptor();

  const handleShowToast = (
    message: string,
    variant: ToastVariant,
    isCloseable: boolean
  ) => {
    showToast(message, variant, isCloseable);
  };

  //checking the extension of the file
  const handleFileChange = (event: any) => {
    const file = event.target.files[0];
    const allowedExtensions = /(\.xlsx)$/i;
    if (file && !allowedExtensions.test(file.name)) {
      handleShowToast(
        "Invalid file format. Only XLSX files are allowed.",
        "error",
        true
      );
      setSelectedFile(null);
      setError(true);
    } else {
      if (
        file &&
        (file.type === "application/vnd.ms-excel" ||
          file.type ===
            "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet")
      ) {
        setSelectedFile(file);
        setError(false);
      } else {
        // setSelectedFile(null);
        setError(true);
      }
    }
  };

  const handleDownload = (e: any) => {
    // dispatch(setInPageLoading(true));

    const worksheet = XLSX.utils.aoa_to_sheet([headers]);

    const workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workbook, worksheet, "Sheet1");

    const excelBuffer = XLSX.write(workbook, {
      type: "array",
      bookType: "xlsx",
    });

    const data = new Blob([excelBuffer], {
      type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
    });
    saveAs(data, "attendance_bulk_upload.xlsx");
    // dispatch(setInPageLoading(false));
  };

  //only upload if the file is not in xlsx format
  const handleUpload = () => {
    dispatch(setInPageLoading(true));

    if (selectedFile) {
      if (
        selectedFile &&
        selectedFile.type !== "application/vnd.ms-excel" &&
        selectedFile.type !==
          "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
      ) {
        // setSelectedFile(null);
        handleShowToast(
          "Please select a valid Excel file (.xls or .xlsx)",
          "error",
          true
        );
        setError(true);

        return; // Return early if file type is not valid
      }

      const reader = new FileReader();
      reader.onload = (event) => {
        const data = new Uint8Array(event.target!.result as ArrayBuffer);
        const workbook = XLSX.read(data, { type: "array" });
        const worksheet = workbook.Sheets[workbook.SheetNames[0]];
        const jsonData = XLSX.utils.sheet_to_json(worksheet, { header: 1 });

        const formData = new FormData();
        formData.append("file", selectedFile);
        formData.append("data", JSON.stringify(jsonData));
        // Example: You can use the Fetch API or any other library to make an API request

        axiosInstance
          .post(addBulkAttendanceExcel, formData, {
            headers: {
              "Content-Type": "multipart/form-data",
              Authorization: `Bearer ${token}`,
            },
          })
          .then((res) => {
            handleShowToast("Data Updated Successfully!", "success", true);
            dispatch(setInPageLoading(false));
            dispatch(setisOpen(true));
            // navigate("/employeeinformation");
          })
          .catch((error) => {
            console.log(error);

            handleShowToast(
              `${error.response.data.message ?? "Error Occured !"}`,
              "error",
              true
            );
            dispatch(setInPageLoading(false));
          });
      };

      reader.readAsArrayBuffer(selectedFile);
    }
  };

  // if (ispageloading) {
  //   return (
  //     <>
  //       <CircularProgress
  //         size={100}
  //
  //         // sx={{ top: 300, left: "50%", position: "relative" }}
  //       />
  //     </>
  //   );
  // }

  return (
    <Box sx={{ m: 1, p: 1 }}>
      <Box></Box>
      <Link
        onClick={handleDownload}
        sx={{ "&:hover": { cursor: "pointer" }, m: 1 }}
      >
        {" "}
        Click to download Format to Upload
      </Link>
      <Box>
        <TextField
          sx={{ m: 1 }}
          size="small"
          type="file"
          inputProps={{
            accept: ".xls,.xlsx",
          }}
          onChange={handleFileChange}
        />
        {error ? (
          <Button
            variant="contained"
            // onClick={handleUpload}
            disabled
            size="small"
            sx={{ m: 2 }}
          >
            Upload
          </Button>
        ) : (
          <Button
            variant="contained"
            onClick={handleUpload}
            size="small"
            sx={{ m: 2 }}
          >
            Upload
          </Button>
        )}
      </Box>
    </Box>
  );
};

export default AttendanceExcelUpload;
