import { createSlice, PayloadAction } from "@reduxjs/toolkit";

interface SkillsSliceState {
  skill_id: string;
  skill_name: string;
  questions: {
    skill_question_id: string;
    question: string;
  }[];
}

const initialState: SkillsSliceState = {
  skill_id: "",
  skill_name: "",
  questions: [
    {
      skill_question_id: "",
      question: "",
    },
  ],
};

const skillsSlice = createSlice({
  name: "skills",
  initialState,
  reducers: {
    setSkillData: (state, action: PayloadAction<SkillsSliceState>) => {
      return {
        ...state,
        ...action.payload,
      };
    },
    setSkillId: (state, action: PayloadAction<string>) => {
      return {
        ...state,
        skillId: action.payload,
      };
    },

    // Add other reducers as needed
  },
});

export const { setSkillData, setSkillId } = skillsSlice.actions;

export default skillsSlice;
