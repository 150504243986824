import { useEffect, useState } from "react";

import { Box, Button, Card, Container, Typography } from "@mui/material";

import useAxiosInterceptor from "../../components/layout/AxiosInstance";

import { ThunkDispatch } from "@reduxjs/toolkit";
import { useDispatch, useSelector } from "react-redux";
import {
  ViewUser,
  adminPermission,
  allowDenyAccess,
  allowEditAccess,
  denyEditAccess,
} from "../../Service/Apis";
import NothingToDisplayImage from "../../asset/NothingToDisplayImage.png";
import {
  ToastVariant,
  useCustomToast,
} from "../../components/Methods/SnackBar";
import Footer from "../../components/Page Component/Footer";
import Navbar from "../../components/Page Component/Navbar";
import { RootState } from "../../store/Store";
import {
  DataPayload,
  FetchDataFailureAction,
  FetchDataSuccessAction,
  fetchData,
  patchData,
} from "../../store/actions";
import { postDataSuccess } from "../../store/AxiosSlice";
import NothingToDisplayComponent from "../../components/Data/NothingToShowComponent";

interface Permission {
  response_id: string;
  hr_id: string;
  hr_name: string;
  form_name: string;
  team_name: string;
  employee_name: string;
}

const Permission = () => {
  const [permission, setPermission] = useState<Permission[]>([]);
  const [count, setCount] = useState(0);

  const axiosInstance = useAxiosInterceptor();
  const dispatch = useDispatch();
  // const success = useSelector((state: any) => state.http.data);

  const showToast = useCustomToast();

  const dispatch2: ThunkDispatch<
    RootState,
    null,
    FetchDataSuccessAction | FetchDataFailureAction
  > = useDispatch();

  const email = localStorage.getItem("email");

  const handleShowToast = (
    message: string,
    variant: ToastVariant,
    isCloseable: boolean
  ) => {
    showToast(message, variant, isCloseable);
  };

  //giving permission
  const handleAllowAccess = (e: any, hr_id: string, response_id: string) => {
    e.preventDefault();

    var data = {
      hr_edit_permission: true,
    };

    try {
      const response = dispatch2(
        patchData(`${allowEditAccess}${response_id}&hr_id=${hr_id}`, data)
      )
        .then((response) => {
          handleShowToast("Allowed Access", "success", true);
          dispatch(postDataSuccess(null));
          setCount(count + 1);
        })
        .catch((err) => {
          console.error(err);
        });
    } catch (error) {
      console.log(error);
    }
  };

  const handleDeny = (e: any, hr_id: string, response_id: string) => {
    e.preventDefault();

    var data = {
      hr_edit_permission: false,
    };

    //for allowing and denying permission
    try {
      const response = dispatch2(
        patchData(`${denyEditAccess}${response_id}&hr_id=${hr_id}`, data)
      )
        .then((response) => {
          handleShowToast("Allowed Access", "success", true);
          dispatch(postDataSuccess(null));
          setCount(count + 1);
        })
        .catch((error) => {
          console.error(error);
        });
    } catch (error) {
      console.log(error);
    }
  };

  //getting permissions
  useEffect(() => {
    const fetchMyData = async () => {
      try {
        const token = localStorage.getItem("token");
        const response: DataPayload = await dispatch2(
          fetchData(`${adminPermission}`)
        );

        setPermission(response as Permission[]);
      } catch (error) {
        //console.log("Error:", error);
      }
    };
    fetchMyData();
  }, [count]);

  return (
    <>
      <Box>
        <Navbar />
      </Box>
      <Box sx={{ mb: 10, mt: 12 }}>
        <Box sx={{ display: "flex", justifyContent: "center", m: 1, p: 1 }}>
          <Typography variant="h3">Permissions</Typography>
        </Box>
        <Container>
          {permission.length !== 0 ? (
            permission.map((data, index) => {
              return (
                <Card
                  elevation={11}
                  sx={{
                    ml: "5rem",
                    m: 2,
                    p: 2,
                    //   display: "flex",
                    //   justifyContent: "center",
                    //   width: {
                    //     xs: "75%",
                    //     sm: "fit-content",
                    //     md: "85%",
                    //   },
                    overflow: "auto",
                    height: {
                      xs: "fit-content",
                      sm: "fit-content",
                      md: "85%",
                    },
                    borderRadius: "5px",
                    boxShadow: "0px 0px 10px 2px rgba(0, 100, 0, 0.5)",
                  }}
                >
                  <Box sx={{ display: "flex", justifyContent: "center" }}>
                    <Typography variant="h6">
                      {data.hr_name} has requested to edit {data.employee_name}
                      's {data.form_name} from team {data.team_name}.
                    </Typography>
                  </Box>
                  <Box sx={{ display: "flex", justifyContent: "right", mt: 2 }}>
                    <Button
                      size="small"
                      variant="contained"
                      sx={{ m: 0.5 }}
                      onClick={(e) => {
                        handleAllowAccess(e, data.hr_id, data.response_id);
                      }}
                    >
                      Allow
                    </Button>
                    <Button
                      size="small"
                      variant="text"
                      sx={{ m: 0.5 }}
                      onClick={(e) => {
                        handleDeny(e, data.hr_id, data.response_id);
                      }}
                    >
                      Deny
                    </Button>
                  </Box>
                </Card>
              );
            })
          ) : (
            <Box
              sx={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                p: 2,
              }}
            >
              <Box sx={{ m: 10 }}>
                <NothingToDisplayComponent />
              </Box>
            </Box>
          )}
        </Container>
      </Box>
      <Box>
        <Footer />
      </Box>
    </>
  );
};

export default Permission;
