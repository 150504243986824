import DeleteIcon from "@mui/icons-material/Delete";
import {
  Box,
  Button,
  Card,
  CircularProgress,
  Container,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
  InputLabel,
  MenuItem,
  Paper,
  Radio,
  Select,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TextField,
  Typography,
} from "@mui/material";
import FormControlLabel from "@mui/material/FormControlLabel";
import Switch, { SwitchProps } from "@mui/material/Switch";
import { styled } from "@mui/material/styles";
import { useEffect, useState } from "react";

import CloseIcon from "@mui/icons-material/Close";
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { DemoContainer } from "@mui/x-date-pickers/internals/demo";
import { ThunkDispatch } from "@reduxjs/toolkit";
import { format } from "date-fns";
import dayjs, { Dayjs } from "dayjs";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import {
  CreateAppraisalForm,
  ShowForms,
  viewHierarchicalForm,
} from "../../Service/Apis";
import {
  ToastVariant,
  useCustomToast,
} from "../../components/Methods/SnackBar";
import Footer from "../../components/Page Component/Footer";
import Navbar from "../../components/Page Component/Navbar";
import useAxiosInterceptor from "../../components/layout/AxiosInstance";
import { setInPageLoading, setIsLoading } from "../../store/LoadingSlice";
import { RootState } from "../../store/Store";
import {
  FetchDataFailureAction,
  FetchDataSuccessAction,
  fetchData,
  postData,
} from "../../store/actions";
import { date } from "./AddForm";
import Loading from "../../components/Loading/Loading";

interface AppraisalForm {
  sections: {
    section: string;
    categories: {
      category: string;
      questions: {
        // question_id: string;
        question: string;
        answer_type: string;
        answer_choices: {
          answer_value: string;
          // option_id: string;
        }[];
      }[];
    }[];
  }[];
}

interface OptionValue {
  [sectionIndex: number]: {
    [categoryIndex: number]: {
      [questionIndex: number]: {
        value: string;
      };
    };
  };
}

interface AnswerChoicesError {
  [sectionIndex: number]: {
    [categoryIndex: number]: {
      [questionIndex: number]: {
        value: boolean;
      };
    };
  };
}

const initialOptionValue: OptionValue = {};
const initialAnswerChoice: AnswerChoicesError = {};

const initialState: AppraisalForm = {
  sections: [
    {
      section: "",
      categories: [
        {
          category: "",
          questions: [
            {
              question: "",
              answer_type: "short_answer",
              answer_choices: [],
            },
          ],
        },
      ],
    },
  ],
};

const IOSSwitch = styled((props: SwitchProps) => (
  <Switch focusVisibleClassName=".Mui-focusVisible" disableRipple {...props} />
))(({ theme }) => ({
  width: 42,
  height: 26,
  padding: 0,
  "& .MuiSwitch-switchBase": {
    padding: 0,
    margin: 2,
    transitionDuration: "300ms",
    "&.Mui-checked": {
      transform: "translateX(16px)",
      color: "#fff",
      "& + .MuiSwitch-track": {
        backgroundColor:
          theme.palette.mode === "dark" ? "#2ECA45" : theme.palette.primary,
        opacity: 1,
        border: 0,
      },
      "&.Mui-disabled + .MuiSwitch-track": {
        opacity: 0.5,
      },
    },
    "&.Mui-focusVisible .MuiSwitch-thumb": {
      color: "#33cf4d",
      border: "6px solid #fff",
    },
    "&.Mui-disabled .MuiSwitch-thumb": {
      color:
        theme.palette.mode === "light"
          ? theme.palette.grey[100]
          : theme.palette.grey[600],
    },
    "&.Mui-disabled + .MuiSwitch-track": {
      opacity: theme.palette.mode === "light" ? 0.7 : 0.3,
    },
  },
  "& .MuiSwitch-thumb": {
    boxSizing: "border-box",
    width: 22,
    height: 22,
  },
  "& .MuiSwitch-track": {
    borderRadius: 26 / 2,
    backgroundColor: theme.palette.mode === "light" ? "#E9E9EA" : "#39393D",
    opacity: 1,
    transition: theme.transitions.create(["background-color"], {
      duration: 500,
    }),
  },
}));

const AppraisalForm = () => {
  const [appraisalFormContent, setAppraisalFormContent] =
    useState<AppraisalForm>(initialState); //To manage the appraisal form content.

  const [importForm, setImportForm] = useState<boolean>(false);
  const [form, setForm] = useState([]);
  const [formId, setFormId] = useState<string>("");

  const [formName, setFormName] = useState<string>("");
  const [formDescription, setFormDescription] = useState<string>("");
  const [managerResponseVisibility, setManagerResponseVisibility] =
    useState<boolean>(true);

  const [optionValue, setOptionValue] =
    useState<OptionValue>(initialOptionValue);

  const [empDate, setEmpDate] = useState<Dayjs | null>(dayjs(`${date}`));
  const [managerDate, setManagerDate] = useState<Dayjs | null>(
    dayjs(`${date}`)
  );
  const [deleteSectionIs, setDeleteSectionIs] = useState<number | null>(null); //To open confirmation box for deleting section.
  const [deleteCategoryIs, setDeleteCategoryIs] = useState<{
    sectionIndex: number | null;
    categoryIndex: number | null;
  }>({ sectionIndex: null, categoryIndex: null }); //To open confirmation box for deleting category.
  const [deleteQuestionIs, setDeleteQuestionIs] = useState<{
    sectionIndex: number | null;
    categoryIndex: number | null;
    questionIndex: number | null;
  }>({ sectionIndex: null, categoryIndex: null, questionIndex: null }); //To open confirmation box for deleting question.

  const [answerChoicesError, setAnswerChoicesError] =
    useState<AnswerChoicesError>(initialAnswerChoice);

  const [flag, setFlag] = useState<boolean>(false); //To display message for rating validation after the form submission.

  const axiosInstance = useAxiosInterceptor();
  const showToast = useCustomToast();

  const dispatch = useDispatch();

  const isloading = useSelector((state: any) => state.loading.isLoading);

  const token = localStorage.getItem("token");

  const Navigate = useNavigate();

  const dispatch2: ThunkDispatch<
    RootState,
    null,
    FetchDataSuccessAction | FetchDataFailureAction
  > = useDispatch();

  const handleOpenImportForm = (e: any) => {
    setImportForm(true);
  };

  const handleShowToast = (
    message: string,
    variant: ToastVariant,
    isCloseable: boolean
  ) => {
    showToast(message, variant, isCloseable);
  };

  const handleCloseImportForm = () => {
    setImportForm(false);
  };

  const handleOpenSectionDelete = (sectionIndex: number) => {
    setDeleteSectionIs(sectionIndex);
  };

  const handleCloseSectionDelete = () => {
    setDeleteSectionIs(null);
  };

  const handleOpenCategoryDelete = (
    sectionIndex: number,
    categoryIndex: number
  ) => {
    setDeleteCategoryIs({
      sectionIndex: sectionIndex,
      categoryIndex: categoryIndex,
    });
  };

  const handleCloseCategoryDelete = () => {
    setDeleteCategoryIs({ sectionIndex: null, categoryIndex: null });
  };

  const handleOpenQuestionDelete = (
    sectionIndex: number,
    categoryIndex: number,
    questionIndex: number
  ) => {
    setDeleteQuestionIs({
      sectionIndex: sectionIndex,
      categoryIndex: categoryIndex,
      questionIndex: questionIndex,
    });
  };

  const handleCloseQuestionDeleter = () => {
    setDeleteQuestionIs({
      sectionIndex: null,
      categoryIndex: null,
      questionIndex: null,
    });
  };

  const addSection = () => {
    const newSection = {
      section: "",
      categories: [
        {
          category: "",
          questions: [
            {
              question: "",
              answer_type: "short_answer",
              answer_choices: [
                // {
                //   answer_value: "",
                //   option_id: "",
                // },
              ],
            },
          ],
        },
      ],
    };

    const updatedFormContent = {
      sections: [...appraisalFormContent.sections, newSection],
    };

    setAppraisalFormContent(updatedFormContent);
  };

  const addCategory = (sectionIndex: number) => {
    const updatedSection = { ...appraisalFormContent.sections[sectionIndex] };
    updatedSection.categories.push({
      category: "",
      questions: [
        {
          question: "",
          answer_type: "short_answer",
          answer_choices: [
            // {
            //   answer_value: "",
            //   option_id: "",
            // },
          ],
        },
      ],
    });

    const updatedFormContent = { ...appraisalFormContent };
    updatedFormContent.sections[sectionIndex] = updatedSection;

    setAppraisalFormContent(updatedFormContent);
  };

  const addQuestion = (
    sectionIndex: number,
    categoryIndex: number
    // questionType: string
  ) => {
    const updatedCategory = {
      ...appraisalFormContent.sections[sectionIndex].categories[categoryIndex],
    };
    const newQuestion = {
      //   question_id: "",
      question: "",
      answer_type: "short_answer",
      answer_choices: [], // Remove the initial null option
    };

    updatedCategory.questions.push(newQuestion);

    const updatedFormContent = { ...appraisalFormContent };
    updatedFormContent.sections[sectionIndex].categories[categoryIndex] =
      updatedCategory;

    setAppraisalFormContent(updatedFormContent);
  };

  const deleteQuestion = (
    sectionIndex: number,
    categoryIndex: number,
    questionIndex: number
  ) => {
    const totalQuestions =
      appraisalFormContent.sections[sectionIndex].categories[categoryIndex]
        .questions.length;
    if (questionIndex > -1 && totalQuestions > 1) {
      const updatedCategory = {
        ...appraisalFormContent.sections[sectionIndex].categories[
          categoryIndex
        ],
      };

      updatedCategory.questions.splice(questionIndex, 1);

      const updatedFormContent = { ...appraisalFormContent };
      updatedFormContent.sections[sectionIndex].categories[categoryIndex] =
        updatedCategory;

      setAppraisalFormContent(updatedFormContent);
    } else if (totalQuestions <= 1) {
      handleShowToast("Cannot Delete Last Question.", "info", true);
    }
  };

  const editQuestionType = (
    sectionIndex: number,
    categoryIndex: number,
    questionIndex: number,
    questionType: string
  ): void => {
    const updatedFormContent = { ...appraisalFormContent };
    const question =
      updatedFormContent.sections[sectionIndex].categories[categoryIndex]
        .questions[questionIndex];
    question.answer_type = questionType;

    setAppraisalFormContent(updatedFormContent);
  };

  const handleSetOptionValue = (
    sectionIndex: number,
    categoryIndex: number,
    questionIndex: number,
    value: string
  ) => {
    setOptionValue((prevOptionValue) => {
      // Create a copy of the current state to avoid modifying it directly
      const updatedOptionValue = { ...prevOptionValue };

      // Check if the sectionIndex exists in the state
      if (!updatedOptionValue.hasOwnProperty(sectionIndex)) {
        updatedOptionValue[sectionIndex] = {};
      }

      // Check if the categoryIndex exists in the state
      if (!updatedOptionValue[sectionIndex].hasOwnProperty(categoryIndex)) {
        updatedOptionValue[sectionIndex][categoryIndex] = {};
      }

      // Update the value for the specified questionIndex
      updatedOptionValue[sectionIndex][categoryIndex][questionIndex] = {
        value: value,
      };

      // Return the updated state
      return updatedOptionValue;
    });
  };

  //To Keep Minimum 2 fields for one mulltiple choice question validation.
  const handleAnswerChoice = (
    sectionIndex: number,
    categoryIndex: number,
    questionIndex: number,
    value: number
  ) => {
    setAnswerChoicesError((prevAnswerError) => {
      const updatedAnswerError = { ...answerChoicesError };
      const sectionError = updatedAnswerError[sectionIndex] || {};
      const categoryError = sectionError[categoryIndex] || {};
      const questionError = categoryError[questionIndex] || {
        value: false,
      };

      questionError.value = value < 2;

      categoryError[questionIndex] = questionError;
      sectionError[categoryIndex] = categoryError;
      updatedAnswerError[sectionIndex] = sectionError;

      return updatedAnswerError;
    });
  };

  const addOption = (
    sectionIndex: number,
    categoryIndex: number,
    questionIndex: number
  ) => {
    const updatedFormContent = { ...appraisalFormContent };
    const question =
      updatedFormContent.sections[sectionIndex].categories[categoryIndex]
        .questions[questionIndex];
    const newOption = {
      answer_value:
        optionValue?.[sectionIndex]?.[categoryIndex]?.[questionIndex]?.value, //newOptionValue,
      option_id: "", // Add the necessary logic to generate a unique option ID
    };
    question.answer_choices.push(newOption);
    setAppraisalFormContent(updatedFormContent);
    handleSetOptionValue(sectionIndex, categoryIndex, questionIndex, "");
    handleAnswerChoice(
      sectionIndex,
      categoryIndex,
      questionIndex,
      question.answer_choices.length
    );
  };

  const deleteOption = (
    sectionIndex: number,
    categoryIndex: number,
    questionIndex: number,
    optionIndex: number
  ) => {
    const updatedFormContent = { ...appraisalFormContent };
    const question =
      updatedFormContent.sections[sectionIndex].categories[categoryIndex]
        .questions[questionIndex];
    question.answer_choices.splice(optionIndex, 1);
    setAppraisalFormContent(updatedFormContent);
    handleAnswerChoice(
      sectionIndex,
      categoryIndex,
      questionIndex,
      question.answer_choices.length
    );
  };

  const deleteCategory = (sectionIndex: number, categoryIndex: number) => {
    const totalCategories =
      appraisalFormContent.sections[sectionIndex].categories.length;
    if (categoryIndex > -1 && totalCategories > 1) {
      const updatedFormContent = { ...appraisalFormContent };
      updatedFormContent.sections[sectionIndex].categories.splice(
        categoryIndex,
        1
      );
      setAppraisalFormContent(updatedFormContent);
    } else if (totalCategories <= 1) {
      handleShowToast("Cannot Delete Last Category", "info", true);
    }
  };

  const deleteSection = (sectionIndex: number) => {
    const totalSections = appraisalFormContent.sections.length;
    if (sectionIndex > -1 && totalSections > 1) {
      const updatedFormContent = { ...appraisalFormContent };
      updatedFormContent.sections.splice(sectionIndex, 1);
      setAppraisalFormContent(updatedFormContent);
    } else if (totalSections <= 1) {
      handleShowToast("Cannot Delete Last Section.", "info", true);
    }
  };

  const handleCategoryNameChange = (
    sectionIndex: number,
    categoryIndex: number,
    value: string
  ): void => {
    const updatedFormContent = { ...appraisalFormContent };
    updatedFormContent.sections[sectionIndex].categories[
      categoryIndex
    ].category = value;

    setAppraisalFormContent(updatedFormContent);
  };

  const handleQuestionChange = (
    sectionIndex: number,
    categoryIndex: number,
    questionIndex: number,
    value: string
  ): void => {
    const updatedFormContent = { ...appraisalFormContent };
    updatedFormContent.sections[sectionIndex].categories[
      categoryIndex
    ].questions[questionIndex].question = value;

    setAppraisalFormContent(updatedFormContent);
  };

  //To create a form.
  const handleSubmit = async (e: any) => {
    e.preventDefault();

    let isAnyAnswerChoiceError = false;

    //Check for an error for answer choices have minimum 2 options show error and return.
    Object.values(answerChoicesError).forEach((categories) => {
      Object.values(categories).forEach((questions: any) => {
        Object.values(questions).forEach((error: any) => {
          if (error.value) {
            isAnyAnswerChoiceError = true;
          }
        });
      });
    });

    if (isAnyAnswerChoiceError) {
      setFlag(true);
      return;
    }

    const { sections } = appraisalFormContent;
    var data = {
      form_name: formName,
      form_description: formDescription,
      employee_deadline: empDate ? format(empDate.toDate(), "yyyy-MM-dd") : "",
      manager_deadline: managerDate
        ? format(managerDate.toDate(), "yyyy-MM-dd")
        : "",
      manager_fields_visible: managerResponseVisibility,
      sections,
    };

    // API call for creating a form.
    try {
      const response = await dispatch2(postData(`${CreateAppraisalForm}`, data))
        .then((response) => {
          setAppraisalFormContent(initialState);
          handleShowToast(
            `${formName} Created Successfully !`,
            "success",
            true
          );
          // dispatch(postDataSuccess(null));
          Navigate("/createdforms");
        })
        .catch((error) => {
          console.log(error);
        });
    } catch (error) {
      console.log(error);
    }
  };

  //Import form
  // get list of all forms.
  useEffect(() => {
    const fetchForms = async () => {
      try {
        dispatch(setIsLoading(true));
        const response = await dispatch2(
          fetchData(`${ShowForms}?is_hierarchical=True`)
        );
        setForm(response as any);

        dispatch(setIsLoading(false));
      } catch (error) {
        console.log(error);
      }
    };
    fetchForms();
  }, []);

  //View particular form.
  useEffect(() => {
    dispatch(setIsLoading(true));
    const response = axiosInstance
      .get(`${viewHierarchicalForm}${formId}`, {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      })
      .then((res) => {
        if (formId !== "") {
          setAppraisalFormContent({
            ...appraisalFormContent,
            sections: res.data[0].sections.map((section: any) => ({
              section: section.section,
              section_id: section.section_id,
              categories: section.categories.map((category: any) => ({
                category_id: category.category_id,
                category: category.category,
                questions: category.questions.map((question: any) => ({
                  question_id: question.question_id,
                  question: question.question,
                  answer_type: question.answer_type,
                  answer_choices: question.answer_choices.map(
                    (choice: any) => ({
                      answer_value: choice.answer_value,
                      option_id: choice.option_id,
                    })
                  ),
                })),
              })),
            })),
          });
          setFormName(res.data[0].form_name);
          setFormDescription(res.data[0].form_description);
        }
        dispatch(setIsLoading(false));
      })
      .catch((error) => {
        dispatch(setIsLoading(false));
        console.log(error);
      });
  }, [formId]);

  if (isloading) {
    return (
      <>
        <Loading isLoading={isloading as boolean} />
      </>
    );
  }

  return (
    <>
      <Container sx={{}}>
        <Box sx={{ display: "flex", justifyContent: "center" }}>
          <Box component="form" onSubmit={handleSubmit}>
            <Box
              sx={{
                display: "flex",
                justifyContent: "space-between",
                m: 2,
                // width: "100%",
              }}
            >
              <Box>
                <FormControlLabel
                  control={
                    <IOSSwitch
                      sx={{ m: 1 }}
                      checked={managerResponseVisibility}
                      onChange={(e) =>
                        setManagerResponseVisibility(e.target.checked)
                      }
                    />
                  }
                  label="Manager Response Visibility"
                />
              </Box>
              <Box sx={{ display: "flex", justifyContent: "right" }}>
                <Button
                  id="import"
                  size="small"
                  variant="contained"
                  onClick={handleOpenImportForm}
                  sx={{ m: 0.5 }}
                >
                  Import
                </Button>
                {formId !== "" && (
                  <Button
                    id="reset"
                    size="small"
                    sx={{ m: 0.5 }}
                    onClick={() => {
                      dispatch(setInPageLoading(true));
                      setAppraisalFormContent(initialState);
                      setFormName("");
                      setFormDescription("");
                      setFormId("");
                      dispatch(setInPageLoading(false));
                    }}
                  >
                    Reset
                  </Button>
                )}
              </Box>
              <Dialog
                open={importForm}
                fullScreen
                onClose={handleCloseImportForm}
              >
                <DialogTitle
                  sx={{ display: "flex", justifyContent: "center", m: 2 }}
                >
                  <Typography variant="h4">Existing Forms </Typography>
                </DialogTitle>
                <DialogContent>
                  <TableContainer component={Paper}>
                    <Table>
                      <TableHead className="header-background">
                        <TableCell
                          sx={{ textAlign: "center", fontWeight: "bold" }}
                        >
                          Sr. No.
                        </TableCell>
                        <TableCell
                          sx={{ textAlign: "center", fontWeight: "bold" }}
                        >
                          Form Name
                        </TableCell>
                        <TableCell
                          sx={{ textAlign: "center", fontWeight: "bold" }}
                        >
                          Employee Deadline
                        </TableCell>
                        <TableCell
                          sx={{ textAlign: "center", fontWeight: "bold" }}
                        >
                          Manager Deadline
                        </TableCell>
                        <TableCell
                          sx={{ textAlign: "center", fontWeight: "bold" }}
                        >
                          Action
                        </TableCell>
                      </TableHead>
                      {form.map((form: any, index) => {
                        return (
                          <TableBody>
                            <TableCell sx={{ textAlign: "center" }}>
                              {index + 1 + "."}
                            </TableCell>
                            <TableCell sx={{ textAlign: "center" }}>
                              {form.form_name}
                            </TableCell>
                            <TableCell sx={{ textAlign: "center" }}>
                              {form.employee_deadline}
                            </TableCell>
                            <TableCell sx={{ textAlign: "center" }}>
                              {form.manager_deadline}
                            </TableCell>
                            <TableCell sx={{ textAlign: "center" }}>
                              <Box
                                sx={{
                                  display: "flex",
                                  justifyContent: "center",
                                }}
                              >
                                <Button
                                  size="small"
                                  onClick={(e) => {
                                    setFormId(form.form_id);
                                    handleCloseImportForm();
                                  }}
                                >
                                  Select
                                </Button>
                              </Box>
                            </TableCell>
                          </TableBody>
                        );
                      })}
                    </Table>
                  </TableContainer>
                </DialogContent>
                <DialogActions sx={{ p: 2, m: 2 }}>
                  <Box sx={{ display: "flex", justifyContent: "right" }}>
                    <Button
                      variant="outlined"
                      size="small"
                      onClick={handleCloseImportForm}
                    >
                      Close
                    </Button>
                  </Box>
                </DialogActions>
              </Dialog>
            </Box>
            <Box sx={{ width: "75vw" }}>
              <Card
                elevation={11}
                sx={{
                  m: 2,
                  p: 2,
                  display: "flex",
                  justifyContent: "center",
                  // width: {
                  //   xs: "fit-content",
                  //   sm: "fit-content",
                  //   md: "100%",
                  // },
                  // borderRadius: "5px",
                  // boxShadow: "0px 0px 10px 2px rgba(0, 100, 0, 0.5)",
                  boxShadow: "0px 0px 10px rgba(0, 0, 0, 0.1)", // Add boxShadow here
                  border: "1px solid #ccc",
                }}
              >
                <Box sx={{ width: "90%" }}>
                  <TextField
                    id="form_name"
                    name="form_name"
                    required
                    fullWidth
                    multiline
                    maxRows={4}
                    sx={{ overflow: "auto", p: 1 }}
                    variant="standard"
                    size="small"
                    placeholder="Form Heading"
                    value={formName}
                    onChange={(e) => {
                      setFormName(e.target.value);
                    }}
                  />
                  <TextField
                    id="description"
                    fullWidth
                    required
                    variant="standard"
                    size="small"
                    placeholder="Form Description"
                    multiline
                    maxRows={4}
                    minRows={2}
                    sx={{ overflow: "auto", p: 1 }}
                    value={formDescription}
                    onChange={(e) => {
                      setFormDescription(e.target.value);
                    }}
                  />
                </Box>
              </Card>
            </Box>
            <Box>
              <Box>
                {appraisalFormContent.sections.map((section, sectionIndex) => (
                  <Card
                    key={sectionIndex}
                    elevation={11}
                    sx={{
                      m: 2,
                      p: 2,
                      flex: 1,
                      flexShrink: 1,
                      // width: {
                      //   xs: "fit-content",
                      //   sm: "fit-content",
                      //   md: "100%",
                      // },
                      boxShadow: "0px 0px 10px rgba(0, 0, 0, 0.1)", // Add boxShadow here
                      border: "1px solid #ccc",
                    }}
                  >
                    <Box sx={{ m: 2, p: 0 }}>
                      <Box sx={{ display: "flex", alignItems: "center" }}>
                        <InputLabel
                          sx={{ width: "10rem", fontWeight: "bold" }}
                          htmlFor="section_name"
                        >
                          Section{" "}
                          {String.fromCharCode(
                            sectionIndex + "A".charCodeAt(0)
                          )}{" "}
                          :
                        </InputLabel>
                        <TextField
                          name="section_name"
                          variant="standard"
                          fullWidth
                          multiline
                          maxRows={4}
                          sx={{ overflow: "auto" }}
                          required
                          value={section.section}
                          onChange={(e) => {
                            const updatedFormContent = {
                              ...appraisalFormContent,
                            };
                            updatedFormContent.sections[sectionIndex].section =
                              e.target.value;
                            setAppraisalFormContent(updatedFormContent);
                          }}
                        />
                        <IconButton
                          onClick={() => {
                            // deleteSection(sectionIndex);
                            handleOpenSectionDelete(sectionIndex);
                          }}
                        >
                          <DeleteIcon
                            id="delete-section "
                            fontSize="medium"
                            color="primary"
                          />
                        </IconButton>
                        <Dialog
                          open={deleteSectionIs === sectionIndex}
                          onClose={handleCloseSectionDelete}
                          // style={{width:"45rem"}}
                          sx={{
                            m: 1,
                            "& .MuiDialog-paper": {
                              width: "30rem",
                            },
                          }}
                        >
                          <Box
                            sx={{
                              display: "flex",
                              justifyContent: "flex-end",
                              alignItems: "flex-end",
                            }}
                          >
                            <IconButton
                              onClick={() => handleCloseSectionDelete()}
                            >
                              <CloseIcon />
                            </IconButton>
                          </Box>
                          <DialogTitle>Confirmation</DialogTitle>
                          <DialogContent>
                            <Typography>Do you want to delete?</Typography>
                          </DialogContent>
                          <DialogActions>
                            <Button id="no" onClick={handleCloseSectionDelete}>
                              No
                            </Button>
                            <Button
                              id="yes"
                              variant="contained"
                              onClick={() => {
                                deleteSection(sectionIndex);
                                handleCloseSectionDelete();
                              }}
                            >
                              Yes
                            </Button>
                          </DialogActions>
                        </Dialog>
                      </Box>
                    </Box>
                    {section.categories.map((category, categoryIndex) => {
                      return (
                        <Box sx={{ m: 4 }} key={categoryIndex}>
                          <Box
                            sx={{
                              display: "flex",
                              alignItems: "center",
                              p: 0,
                            }}
                          >
                            <InputLabel
                              sx={{ width: "15rem", fontWeight: "bold" }}
                              htmlFor="category_name"
                            >
                              Category{" "}
                              {String.fromCharCode(
                                categoryIndex + "a".charCodeAt(0)
                              )}{" "}
                              :
                              {/* {String.fromCharCode(
                      categoryIndex + "a".charCodeAt(0)
                    ) + ")"}{" "} */}
                            </InputLabel>
                            <TextField
                              name="category_name"
                              fullWidth
                              required
                              multiline
                              maxRows={4}
                              sx={{ overflow: "auto" }}
                              value={category.category}
                              variant="standard"
                              size="small"
                              onChange={(e) => {
                                handleCategoryNameChange(
                                  sectionIndex,
                                  categoryIndex,
                                  e.target.value as string
                                );
                              }}
                            />
                            <IconButton
                              onClick={() => {
                                handleOpenCategoryDelete(
                                  sectionIndex,
                                  categoryIndex
                                );
                              }}
                            >
                              <DeleteIcon
                                id="delete-category"
                                fontSize="medium"
                                color="primary"
                              />
                            </IconButton>
                            <Dialog
                              open={
                                deleteCategoryIs.sectionIndex ===
                                  sectionIndex &&
                                deleteCategoryIs.categoryIndex === categoryIndex
                              }
                              onClose={handleCloseCategoryDelete}
                              // style={{width:"45rem"}}
                              sx={{
                                m: 1,
                                "& .MuiDialog-paper": {
                                  width: "30rem",
                                },
                              }}
                            >
                              <Box
                                sx={{
                                  display: "flex",
                                  justifyContent: "flex-end",
                                  alignItems: "flex-end",
                                }}
                              >
                                <IconButton
                                  onClick={() => handleCloseCategoryDelete()}
                                >
                                  <CloseIcon />
                                </IconButton>
                              </Box>
                              <DialogTitle>Confirmation</DialogTitle>
                              <DialogContent>
                                <Typography>Do you want to delete?</Typography>
                              </DialogContent>
                              <DialogActions>
                                <Button
                                  id="no"
                                  onClick={handleCloseCategoryDelete}
                                >
                                  No
                                </Button>
                                <Button
                                  id="yes"
                                  variant="contained"
                                  onClick={() => {
                                    deleteCategory(sectionIndex, categoryIndex);
                                    handleCloseCategoryDelete();
                                  }}
                                >
                                  Yes
                                </Button>
                              </DialogActions>
                            </Dialog>
                          </Box>
                          <Box>
                            {category.questions.map(
                              (question, questionIndex) => {
                                return (
                                  <Box sx={{ m: 4 }} key={questionIndex}>
                                    <Box
                                      sx={{
                                        display: "flex",
                                        alignItems: "center",
                                      }}
                                    >
                                      <Box
                                        sx={{
                                          display: "flex",
                                          alignItems: "center",
                                          width: "70%",
                                        }}
                                      >
                                        <InputLabel
                                          sx={{
                                            width: "10rem",
                                            fontWeight: "bold",
                                          }}
                                        >
                                          Question {questionIndex + 1} :
                                        </InputLabel>
                                        <TextField
                                          id={`question-${questionIndex}`}
                                          size="small"
                                          fullWidth
                                          multiline
                                          maxRows={4}
                                          sx={{ overflow: "auto" }}
                                          value={question.question}
                                          required
                                          variant="standard"
                                          onChange={(e) => {
                                            handleQuestionChange(
                                              sectionIndex,
                                              categoryIndex,
                                              questionIndex,
                                              e.target.value as string
                                            );
                                          }}
                                        />
                                      </Box>
                                      <Box sx={{ m: 2 }}>
                                        <Select
                                          id="question-type"
                                          size="small"
                                          value={question.answer_type}
                                          sx={{
                                            height: "2.5rem",
                                            width: "10.5rem",
                                          }}
                                          onChange={(e) => {
                                            editQuestionType(
                                              sectionIndex,
                                              categoryIndex,
                                              questionIndex,
                                              e.target.value as string
                                            );
                                          }}
                                        >
                                          <MenuItem
                                            id="short-answer"
                                            value="short_answer"
                                          >
                                            Short Answer
                                          </MenuItem>
                                          <MenuItem
                                            id="multichoice"
                                            value="multichoice"
                                          >
                                            Multichoice
                                          </MenuItem>
                                        </Select>
                                        <IconButton
                                          onClick={() => {
                                            handleOpenQuestionDelete(
                                              sectionIndex,
                                              categoryIndex,
                                              questionIndex
                                            );
                                          }}
                                        >
                                          <DeleteIcon
                                            fontSize="medium"
                                            color="primary"
                                          />
                                        </IconButton>
                                        <Dialog
                                          open={
                                            deleteQuestionIs.sectionIndex ===
                                              sectionIndex &&
                                            deleteQuestionIs.categoryIndex ===
                                              categoryIndex &&
                                            deleteQuestionIs.questionIndex ===
                                              questionIndex
                                          }
                                          onClose={handleCloseQuestionDeleter}
                                          // style={{width:"45rem"}}
                                          sx={{
                                            m: 1,
                                            "& .MuiDialog-paper": {
                                              width: "30rem",
                                            },
                                          }}
                                        >
                                          <Box
                                            sx={{
                                              display: "flex",
                                              justifyContent: "flex-end",
                                              alignItems: "flex-end",
                                            }}
                                          >
                                            <IconButton
                                              onClick={() =>
                                                handleCloseQuestionDeleter()
                                              }
                                            >
                                              <CloseIcon />
                                            </IconButton>
                                          </Box>
                                          <DialogTitle>
                                            Confirmation
                                          </DialogTitle>
                                          <DialogContent>
                                            <Typography>
                                              Do you want to delete?
                                            </Typography>
                                          </DialogContent>
                                          <DialogActions>
                                            <Button
                                              id="no"
                                              onClick={
                                                handleCloseQuestionDeleter
                                              }
                                            >
                                              No
                                            </Button>
                                            <Button
                                              id="yes"
                                              variant="contained"
                                              onClick={() => {
                                                deleteQuestion(
                                                  sectionIndex,
                                                  categoryIndex,
                                                  questionIndex
                                                );
                                                handleCloseQuestionDeleter();
                                              }}
                                            >
                                              Yes
                                            </Button>
                                          </DialogActions>
                                        </Dialog>
                                      </Box>
                                    </Box>
                                    <Box>
                                      {question.answer_type ===
                                        "multichoice" && (
                                        <Container sx={{ p: 1 }}>
                                          {question.answer_choices.map(
                                            (answerChoice, index) => (
                                              <Box
                                                sx={{
                                                  display: "flex",
                                                  alignItems: "center",
                                                }}
                                                key={index}
                                              >
                                                <Radio size="small" disabled />
                                                <TextField
                                                  id={`answer_choices-section${sectionIndex}-category${categoryIndex}-question${questionIndex}-${index}`}
                                                  // label="Standard"
                                                  fullWidth
                                                  multiline
                                                  maxRows={4}
                                                  sx={{
                                                    overflow: "auto",
                                                    m: 1,
                                                  }}
                                                  size="small"
                                                  variant="standard"
                                                  value={
                                                    answerChoice.answer_value
                                                  }
                                                  disabled
                                                />
                                                <Button
                                                  id={`deleteOption-${index}`}
                                                  onClick={() =>
                                                    deleteOption(
                                                      sectionIndex,
                                                      categoryIndex,
                                                      questionIndex,
                                                      index
                                                    )
                                                  }
                                                >
                                                  <DeleteIcon />
                                                </Button>
                                              </Box>
                                            )
                                          )}
                                          <Box>
                                            <TextField
                                              id={`answer_value-section-${sectionIndex}-catrgory-${categoryIndex}-question-${questionIndex}`}
                                              name="answer_value"
                                              variant="standard"
                                              size="small"
                                              multiline
                                              maxRows={4}
                                              sx={{
                                                overflow: "auto",
                                                m: 1,
                                                width: "40%",
                                              }}
                                              value={
                                                optionValue[sectionIndex]?.[
                                                  categoryIndex
                                                ]?.[questionIndex]?.value
                                              }
                                              onChange={(e) => {
                                                handleSetOptionValue(
                                                  sectionIndex,
                                                  categoryIndex,
                                                  questionIndex,
                                                  e.target.value
                                                );
                                              }}
                                              required={
                                                question.answer_type ===
                                                  "multichoice" &&
                                                question.answer_choices
                                                  .length === 0
                                              }
                                              placeholder="Add an option"
                                              helperText={
                                                answerChoicesError[
                                                  sectionIndex
                                                ]?.[categoryIndex]?.[
                                                  questionIndex
                                                ]?.value &&
                                                flag && (
                                                  <Typography
                                                    color={"error"}
                                                    id="minimum-two-options-error"
                                                  >
                                                    Please give minimum two
                                                    options.
                                                  </Typography>
                                                )
                                              }
                                            />
                                            <Button
                                              id={`add-option`}
                                              size="small"
                                              onClick={() =>
                                                addOption(
                                                  sectionIndex,
                                                  categoryIndex,
                                                  questionIndex
                                                )
                                              }
                                              sx={{ m: 1 }}
                                            >
                                              Add Option
                                            </Button>
                                          </Box>
                                        </Container>
                                      )}
                                    </Box>
                                  </Box>
                                );
                              }
                            )}
                            <Box
                              sx={{
                                display: "flex",
                                justifyContent: "right",
                                m: 1,
                              }}
                            >
                              <Button
                                id="addQuestion"
                                variant="outlined"
                                onClick={() =>
                                  addQuestion(sectionIndex, categoryIndex)
                                }
                                size="small"
                              >
                                {/* <AddCircleOutlineIcon /> */}
                                Add Question
                              </Button>
                            </Box>
                          </Box>
                        </Box>
                      );
                    })}
                    <Box
                      sx={{
                        display: "flex",
                        justifyContent: "right",
                        m: 2,
                      }}
                    >
                      <Button
                        id="addCategory"
                        variant="contained"
                        size="small"
                        onClick={(e) => {
                          addCategory(sectionIndex);
                        }}
                      >
                        Add Category
                      </Button>
                    </Box>
                  </Card>
                ))}
              </Box>
            </Box>
            <Box>
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "right",
                  m: 2,
                }}
              >
                <Button
                  id="addSection"
                  size="small"
                  variant="outlined"
                  onClick={addSection}
                >
                  Add Section
                </Button>
              </Box>
            </Box>
            <Box
              sx={{
                display: { xs: "block", sm: "block", md: "flex" },
                justifyContent: "center",
              }}
            >
              <Box id="employee_date_picker" sx={{ m: 1, p: 1 }}>
                <LocalizationProvider dateAdapter={AdapterDayjs}>
                  <DatePicker
                    data-testid="employee_date_pick"
                    className="emp_date_picker"
                    label="Employee DeadLine"
                    value={empDate}
                    sx={{ color: "green" }}
                    disablePast
                    format="YYYY-MM-DD"
                    onChange={(newValue) => setEmpDate(newValue)}
                  />
                </LocalizationProvider>
              </Box>
              <Box id="manager_date_picker" sx={{ m: 1, p: 1 }}>
                <LocalizationProvider dateAdapter={AdapterDayjs}>
                  <DatePicker
                    data-testid="manager_date_pick"
                    className="Manager_date"
                    label="Manager DeadLine "
                    value={managerDate}
                    disablePast
                    format="YYYY-MM-DD"
                    onChange={(newValue) => setManagerDate(newValue)}
                  />
                </LocalizationProvider>
              </Box>
            </Box>
            <Box sx={{ display: "flex", justifyContent: "center" }}>
              <Button
                id="CreateFormButton"
                variant="contained"
                type="submit"
                // onClick={(e) => {
                //   handleSubmit(e);
                // }}
              >
                Create Form
              </Button>
            </Box>
          </Box>
        </Box>
      </Container>
    </>
  );
};

export default AppraisalForm;
