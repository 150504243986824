export interface DataPointsAddOrEditType {
  data_point_id: string;
  data_point_name: string;
  data_point_description: string;
  questions: {
    data_point_question_id: string;
    data_point_question: string;
    inverse: boolean;
    bench_mark_value: number;
    created_at: string;
  }[];
}

export interface DataPointEmployeeResponses {
  data_point_assign_id: string;
  project_name: string;
  team_id: string;
  department_id: string;
  department_name: string;
  data_point_id: string;
  data_point_name: string;
  data_point_description: string;
  questions: {
    data_point_question_id: string;
    data_point_question: string;
    data_point_response: number | null;
    inverse: boolean;
    bench_mark_value: number;
    created_at: string;
  }[];
}

export interface DataPointViewPreviousEmployeeResponses {
  data_point_response_id: string;
  data_point_assign_id: string;
  date: string;
  department_name: string;
  project_name: string;
  employee_name: string;
  user_id: string;
  data_point_name: string;
  data_point_description: string;
  data_point_questions: [
    {
      data_point_question_id: string;
      data_point_question: string;
      data_point_id: string;
      inverse: boolean;
      bench_mark_value: string;
      data_point_response_id: string;
      data_point_response: number;
    }
  ];
}

export const intialStateDataPointsAdd: DataPointsAddOrEditType = {
  data_point_id: "",
  data_point_name: "",
  data_point_description: "",
  questions: [
    {
      data_point_question_id: "",
      data_point_question: "",
      inverse: false,
      bench_mark_value: 0,
      created_at: "",
    },
  ],
};

export const data = [
  {
    data_point_id: "1",
    data_point_name: "Revenue",
    data_point_description: "Total revenue generated",

    questions: [
      {
        data_point_question_id: "1",
        data_point_question: "What is the total revenue generated?",
        data_point_response: 0,
      },
      {
        data_point_question_id: "2",
        data_point_question: "How much revenue was generated last month?",
        data_point_response: 0,
      },
      {
        data_point_question_id: "3",
        data_point_question: "What are the sources of revenue?",
        data_point_response: 0,
      },
      {
        data_point_question_id: "4",
        data_point_question: "What is the trend of revenue over the past year?",
        data_point_response: 0,
      },
      {
        data_point_question_id: "5",
        data_point_question: "What percentage of revenue comes from product A?",
        data_point_response: 0,
      },
      {
        data_point_question_id: "6",
        data_point_question: "What is the average revenue per customer?",
        data_point_response: 0,
      },
      {
        data_point_question_id: "7",
        data_point_question: "What factors influence fluctuations in revenue?",
        data_point_response: 0,
      },
    ],
  },
  {
    data_point_id: "2",
    data_point_name: "Expenses",
    data_point_description: "Total expenses incurred",

    questions: [
      {
        data_point_question_id: "8",
        data_point_question: "What are the total expenses incurred?",
        data_point_response: 0,
      },
      {
        data_point_question_id: "9",
        data_point_question: "How much was spent on salaries?",
        data_point_response: 0,
      },
      {
        data_point_question_id: "10",
        data_point_question: "What are the main categories of expenses?",
        data_point_response: 0,
      },
      {
        data_point_question_id: "11",
        data_point_question: "How do expenses compare to budgeted amounts?",
        data_point_response: 0,
      },
      {
        data_point_question_id: "12",
        data_point_question:
          "What percentage of expenses is attributed to marketing?",
        data_point_response: 0,
      },
      {
        data_point_question_id: "13",
        data_point_question: "What is the average expense per employee?",
        data_point_response: 0,
      },
      {
        data_point_question_id: "14",
        data_point_question: "How do expenses vary by department?",
        data_point_response: 0,
      },
    ],
  },
  {
    data_point_id: "3",
    data_point_name: "Profit",
    data_point_description: "Net profit earned",
    questions: [
      {
        data_point_question_id: "15",
        data_point_question: "What is the net profit earned?",
        data_point_response: 0,
      },
      {
        data_point_question_id: "16",
        data_point_question: "How does the profit compare to last quarter?",
        data_point_response: 0,
      },
      {
        data_point_question_id: "17",
        data_point_question: "What factors contribute to changes in profit?",
        data_point_response: 0,
      },
      {
        data_point_question_id: "18",
        data_point_question: "What percentage of revenue is profit?",
        data_point_response: 0,
      },
      {
        data_point_question_id: "19",
        data_point_question: "How does profit margin vary by product?",
        data_point_response: 0,
      },
      {
        data_point_question_id: "20",
        data_point_question: "What is the forecasted profit for next quarter?",
        data_point_response: 0,
      },
      {
        data_point_question_id: "21",
        data_point_question: "How does profit relate to market trends?",
        data_point_response: 0,
      },
    ],
  },
  {
    data_point_id: "4",
    data_point_name: "Customer Satisfaction",
    data_point_description: "Level of satisfaction among customers",

    questions: [
      {
        data_point_question_id: "22",
        data_point_question:
          "What is the overall level of customer satisfaction?",
        data_point_response: 0,
      },
      {
        data_point_question_id: "23",
        data_point_question:
          "What are the main factors influencing customer satisfaction?",
        data_point_response: 0,
      },
      {
        data_point_question_id: "24",
        data_point_question: "How does satisfaction vary by product?",
        data_point_response: 0,
      },
      {
        data_point_question_id: "25",
        data_point_question: "What actions can improve customer satisfaction?",
        data_point_response: 0,
      },
      {
        data_point_question_id: "26",
        data_point_question: "How does customer satisfaction impact sales?",
        data_point_response: 0,
      },
      {
        data_point_question_id: "27",
        data_point_question: "What is the average rating given by customers?",
        data_point_response: 0,
      },
      {
        data_point_question_id: "28",
        data_point_question: "How often do customers provide feedback?",
        data_point_response: 0,
      },
    ],
  },
  {
    data_point_id: "5",
    data_point_name: "Market Share",
    data_point_description: "Percentage of total market held by the company",

    questions: [
      {
        data_point_question_id: "29",
        data_point_question: "What is the current market share?",
        data_point_response: 0,
      },
      {
        data_point_question_id: "30",
        data_point_question:
          "How does the market share compare to competitors?",
        data_point_response: 0,
      },
      {
        data_point_question_id: "31",
        data_point_question: "What strategies can increase market share?",
        data_point_response: 0,
      },
      {
        data_point_question_id: "32",
        data_point_question:
          "What is the market share trend over the past year?",
        data_point_response: 0,
      },
      {
        data_point_question_id: "33",
        data_point_question: "How does market share vary by region?",
        data_point_response: 0,
      },
      {
        data_point_question_id: "34",
        data_point_question: "What is the target market share for next year?",
        data_point_response: 0,
      },
      {
        data_point_question_id: "35",
        data_point_question:
          "What marketing channels are most effective for increasing market share?",
        data_point_response: 0,
      },
    ],
  },
  {
    data_point_id: "6",
    data_point_name: "Product Performance",
    data_point_description: "Performance metrics of company products",

    questions: [
      {
        data_point_question_id: "36",
        data_point_question: "What are the top-selling products?",
        data_point_response: 0,
      },
      {
        data_point_question_id: "37",
        data_point_question:
          "How does product performance compare to industry standards?",
        data_point_response: 0,
      },
      {
        data_point_question_id: "38",
        data_point_question:
          "What features do customers value most in products?",
        data_point_response: 0,
      },
      {
        data_point_question_id: "39",
        data_point_question: "What is the return rate for products?",
        data_point_response: 0,
      },
      {
        data_point_question_id: "40",
        data_point_question:
          "How does product performance impact customer retention?",
        data_point_response: 0,
      },
      {
        data_point_question_id: "41",
        data_point_question:
          "What improvements can be made to product quality?",
        data_point_response: 0,
      },
      {
        data_point_question_id: "42",
        data_point_question:
          "What is the average customer rating for products?",
        data_point_response: 0,
      },
    ],
  },
  {
    data_point_id: "7",
    data_point_name: "Employee Engagement",
    data_point_description:
      "Level of engagement and satisfaction among employees",

    questions: [
      {
        data_point_question_id: "43",
        data_point_question: "What is the level of employee engagement?",
        data_point_response: 0,
      },
      {
        data_point_question_id: "44",
        data_point_question: "How do employees rate job satisfaction?",
        data_point_response: 0,
      },
      {
        data_point_question_id: "45",
        data_point_question: "What factors contribute to employee morale?",
        data_point_response: 0,
      },
      {
        data_point_question_id: "46",
        data_point_question: "What initiatives improve employee engagement?",
        data_point_response: 0,
      },
      {
        data_point_question_id: "47",
        data_point_question:
          "How does employee turnover compare to industry averages?",
        data_point_response: 0,
      },
      {
        data_point_question_id: "48",
        data_point_question: "What is the average tenure of employees?",
        data_point_response: 0,
      },
      {
        data_point_question_id: "49",
        data_point_question: "What are the main reasons for employee turnover?",
        data_point_response: 0,
      },
    ],
  },
  {
    data_point_id: "8",
    data_point_name: "Customer Acquisition",
    data_point_description:
      "Methods and effectiveness of acquiring new customers",

    questions: [
      {
        data_point_question_id: "50",
        data_point_question: "What are the main customer acquisition channels?",
        data_point_response: 0,
      },
      {
        data_point_question_id: "51",
        data_point_question:
          "How effective is each customer acquisition channel?",
        data_point_response: 0,
      },
      {
        data_point_question_id: "52",
        data_point_question:
          "What is the cost per acquisition for each channel?",
        data_point_response: 0,
      },
      {
        data_point_question_id: "53",
        data_point_question:
          "What strategies increase customer acquisition rates?",
        data_point_response: 0,
      },
      {
        data_point_question_id: "54",
        data_point_question: "How does the conversion rate vary by channel?",
        data_point_response: 0,
      },
      {
        data_point_question_id: "55",
        data_point_question:
          "What is the lifetime value of customers acquired?",
        data_point_response: 0,
      },
      {
        data_point_question_id: "56",
        data_point_question:
          "How does customer acquisition cost compare to customer lifetime value?",
        data_point_response: 0,
      },
    ],
  },
  {
    data_point_id: "9",
    data_point_name: "Website Performance",
    data_point_description:
      "Performance and user experience of the company website",

    questions: [
      {
        data_point_question_id: "57",
        data_point_question: "What is the average website traffic?",
        data_point_response: 0,
      },
      {
        data_point_question_id: "58",
        data_point_question: "How does website traffic vary by source?",
        data_point_response: 0,
      },
      {
        data_point_question_id: "59",
        data_point_question: "What is the bounce rate of the website?",
        data_point_response: 0,
      },
      {
        data_point_question_id: "60",
        data_point_question:
          "How long do users spend on the website on average?",
        data_point_response: 0,
      },
      {
        data_point_question_id: "61",
        data_point_question: "What are the most visited pages on the website?",
        data_point_response: 0,
      },
      {
        data_point_question_id: "62",
        data_point_question: "What actions do users take on the website?",
        data_point_response: 0,
      },
      {
        data_point_question_id: "63",
        data_point_question:
          "How does website performance impact conversion rates?",
        data_point_response: 0,
      },
    ],
  },
  {
    data_point_id: "10",
    data_point_name: "Social Media Presence",
    data_point_description: "Engagement and reach on social media platforms",

    questions: [
      {
        data_point_question_id: "64",
        data_point_question: "What is the reach of social media posts?",
        data_point_response: 0,
      },
      {
        data_point_question_id: "65",
        data_point_question:
          "How does engagement vary across different social media platforms?",
        data_point_response: 0,
      },
      {
        data_point_question_id: "66",
        data_point_question:
          "What types of content receive the most engagement?",
        data_point_response: 0,
      },
      {
        data_point_question_id: "67",
        data_point_question:
          "What is the growth rate of social media followers?",
        data_point_response: 0,
      },
      {
        data_point_question_id: "68",
        data_point_question:
          "How do social media metrics correlate with website traffic?",
        data_point_response: 0,
      },
      {
        data_point_question_id: "69",
        data_point_question:
          "What are the demographics of the social media audience?",
        data_point_response: 0,
      },
      {
        data_point_question_id: "70",
        data_point_question:
          "What strategies increase social media engagement?",
        data_point_response: 0,
      },
    ],
  },
  // Add more data points with questions here...
];
