import React from 'react'

import { Box, Container, Skeleton,  } from "@mui/material";

const Skeletons = () => {
  return (
		<>
			<Box sx={{ flexGrow: 1 }}>
				<Box sx={{ bgcolor: "" }}>
					<Skeleton
						variant="rectangular"
						width="100%"
						height={64}
						animation="wave"
					/>
				</Box>
			</Box>
			<Box sx={{ flexGrow: 1 }}>
				<Container maxWidth="md">
					<Box
						sx={{
							display: "flex",
							justifyContent: "center",
							alignItems: "center",
							minHeight: "80vh",
						}}
					>
						<Skeleton
							variant="rectangular"
							width={800}
							height={450}
							sx={{ borderRadius: 2 }}
							animation="wave"
						/>
					</Box>
				</Container>
			</Box>
			<footer>
				<Box sx={{ flexGrow: 1 }}>
					<Box sx={{ bgcolor: "" }}>
						<Skeleton
							variant="rectangular"
							width="100%"
							height={64}
							animation="wave"
						/>
					</Box>
				</Box>
			</footer>
		</>
	);
}

export default Skeletons