import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  FetchDataFailureAction,
  FetchDataSuccessAction,
  fetchData,
} from "../../store/actions";
import { setIsLoading } from "../../store/LoadingSlice";
import { ThunkDispatch } from "@reduxjs/toolkit";
import { RootState } from "../../store/Store";
import { ShowForms, viewHierarchicalForm } from "../../Service/Apis";
import AppraisalEditForm from "./AppraisalEditForm";
import EditThreeSixtyForm from "./ThreeSixtyForm/EditThreeSixtyForm";
import EditFeedBackForm from "./FeedBackForm/EditFeedBackForm";

interface FormType {
  created_by: string;
  creator_email: string;
  creator_name: string;
  employee_deadline: string;
  form_description: string;
  form_id: string;
  form_name: string;
  is_360: boolean;
  is_appraisal: boolean;
  is_hierarchical: boolean;
  is_uploaded: boolean;
  manager_deadline: string;
  teams: [];
}

const EditAllForms = () => {
  const [formDetails, setFormDetails] = useState<FormType[]>([]);
  const isLoading = useSelector((state: any) => state.loading.isLoading);
  const dispatch = useDispatch();
  const formId =
    useSelector((state: any) => state.form.id) ||
    localStorage.getItem("formid");
  const dispatch2: ThunkDispatch<
    RootState,
    null,
    FetchDataSuccessAction | FetchDataFailureAction
  > = useDispatch();
  //fetching the form
  useEffect(() => {
    const fetchAppraisalForm = async () => {
      try {
        dispatch(setIsLoading(true));
        const response = await dispatch2(
          fetchData(`${ShowForms}?form_id=${formId}`)
        );
        setFormDetails(response as FormType[]);
        dispatch(setIsLoading(false));
      } catch (error) {
        console.log(error);
        dispatch(setIsLoading(false));
      }
    };
    fetchAppraisalForm();
  }, []);

  if (formDetails[0]?.is_hierarchical) {
    return (
      <>
        <AppraisalEditForm />
      </>
    );
  } else if (formDetails[0]?.is_360) {
    return (
      <>
        <EditThreeSixtyForm />
      </>
    );
  } else if (
    !formDetails[0]?.is_hierarchical &&
    !formDetails[0]?.is_appraisal
  ) {
    return (
      <>
        <EditFeedBackForm />
      </>
    );
  }
};

export default EditAllForms;
