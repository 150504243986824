import { Autocomplete, Box, Paper, Rating, TextField } from "@mui/material";
import { useEffect, useMemo, useState } from "react";

import { CircularProgress } from "@mui/material";

import { ThunkDispatch } from "@reduxjs/toolkit";
import { useDispatch, useSelector } from "react-redux";

import { setInPageLoading, setIsLoading } from "../../store/LoadingSlice";
import { RootState } from "../../store/Store";
import {
  FetchDataFailureAction,
  FetchDataSuccessAction,
  fetchData,
} from "../../store/actions";

import {
  allEmployeeSkiillResponses,
  viewSkillHistory,
  viewUsers,
} from "../../Service/Apis";

import { DataGrid, GridColDef, GridToolbar } from "@mui/x-data-grid";
import CustomNoRowsOverlay from "../../components/Data/NoRowsOverlayComponent";
import NothingToDisplayComponent from "../../components/Data/NothingToShowComponent";
import {
  AllEmployeeResponsesType,
  EmployeeSkillSetType,
  SkillHistoryType,
} from "./Types/SkillTypes";

interface Employeetype {
  id: string;
  employee_code: string;
  first_name: string;
  last_name: string;
  gender: string;
  roles: [];
  designation: string;
  date_of_birth: string;
  date_of_joining: string;
  date_of_confirmation: string;
  date_of_exit: string;
  email: string;
  password: string;
}

const SkillHistory = () => {
  const [data, setData] = useState<SkillHistoryType[]>([]);
  const [employeesData, setEmployeesData] = useState<Employeetype[]>([]);
  const [employee, setEmployee] = useState<Employeetype | null>(null);

  // Redux Hooks
  const dispatch = useDispatch();
  const dispatch2: ThunkDispatch<
    RootState,
    null,
    FetchDataSuccessAction | FetchDataFailureAction
  > = useDispatch();

  const isLoading = useSelector((state: any) => state.loading.isLoading);

  const handleEmployeeChange = (e: any, values: any) => {
    setEmployee(values);
  };

  const rows = useMemo(() => {
    return data.map((history, index) => ({
      ...history,
      sr_no: index + 1,
      index: index,
    }));
  }, [data]);

  const columns: GridColDef[] = [
    {
      field: "sr_no",
      headerName: "Sr. No",
      renderHeader: () => (
        <strong
          className="custom-header"
          style={{ fontSize: 17, textAlign: "center" }}
        >
          Sr No.
        </strong>
      ),
      //   flex: 1,
      width: 80,
      align: "center",
      headerAlign: "center",
      headerClassName: "header-background",
    },
    {
      field: "employee_name",
      headerName: "Employee Name",
      renderHeader: () => (
        <strong
          className="custom-header"
          style={{ paddingRight: 2, fontSize: 17, textAlign: "center" }}
        >
          Employee Name
        </strong>
      ),
      //   flex: 1,
      width: 160,
      align: "center",
      headerAlign: "center",
      headerClassName: "header-background",
    },
    {
      field: "skill_name",
      headerName: "Skill Name",
      renderHeader: () => (
        <strong
          className="custom-header"
          style={{ fontSize: 17, textAlign: "center" }}
        >
          Skill Name
        </strong>
      ),
      width: 110,
      //   flex: 1,
      align: "center",
      headerAlign: "center",
      headerClassName: "header-background",
    },
    {
      field: "previous_avg",
      headerName: "Previous AVG",
      width: 160,
      // flex: 1,
      align: "center",
      headerAlign: "center",
      headerClassName: "header-background",
      renderHeader: () => (
        <strong
          className="custom-header"
          style={{ fontSize: 17, textAlign: "center" }}
        >
          Previous AVG
        </strong>
      ),
      renderCell: (params: any) => (
        <Rating
          readOnly
          sx={{ color: "#007300" }}
          value={parseInt(
            params.row.previous_avg ? params.row.previous_avg : "0"
          )}
        />
      ),
    },
    {
      field: "current_avg",
      headerName: " Current AVG",
      width: 160,
      // flex: 1,
      align: "center",
      headerAlign: "center",
      headerClassName: "header-background",
      renderHeader: () => (
        <strong
          className="custom-header"
          style={{ fontSize: 17, textAlign: "center" }}
        >
          Current AVG
        </strong>
      ),
      renderCell: (params: any) => (
        <Rating
          readOnly
          sx={{ color: "#007300" }}
          value={parseInt(
            params.row.current_avg ? params.row.current_avg : "0"
          )}
        />
      ),
    },
  ];

  useEffect(() => {
    // API Call get the User Details.
    const fetchSkillHistory = async () => {
      try {
        dispatch(setIsLoading(true));
        const response = await dispatch2(
          fetchData(
            `${viewSkillHistory}${employee ? `?user_id=${employee.id}` : ""}`
          )
        );
        setData(response as SkillHistoryType[]);
      } catch (error) {
        console.log(error);
      } finally {
        dispatch(setIsLoading(false));
      }
    };

    fetchSkillHistory();
  }, [employee]);

  useEffect(() => {
    // API Call get the User Details.
    const fetchUserDetails = async () => {
      try {
        dispatch(setIsLoading(true));
        const response = await dispatch2(fetchData(`${viewUsers}`));
        setEmployeesData(response as Employeetype[]);
      } catch (error) {
        console.log(error);
      } finally {
        dispatch(setIsLoading(false));
      }
    };

    fetchUserDetails();
  }, []);

  if (isLoading) {
    return (
      <>
        {/* Fullscreen Overlay */}
        <Box
          id="fullscreen-overlay"
          style={{
            position: "fixed",
            top: 0,
            left: 0,
            width: "100%",
            height: "100%",
            backdropFilter: "blur(1.5px)", // Adjust the blur intensity as needed
            zIndex: 9999,
          }}
        />
        {/* Loader Container */}
        <Box
          id="loader-container"
          sx={{
            position: "fixed", // Use fixed positioning for the loader container
            top: 0,
            left: 0,
            width: "100%",
            height: "100%",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            zIndex: 10000,
          }}
        >
          {/* Loader */}
          <Box
            id="loader"
            sx={{
              position: "relative", // Use relative positioning for the actual loader
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            {isLoading && <CircularProgress size={50} color="success" />}
          </Box>
        </Box>
      </>
    );
  }

  return (
    <>
      <Box sx={{ display: "flex", justifyContent: "center", width: "85vw" }}>
        <Box sx={{ m: 2 }}>
          <Box>
            <Autocomplete
              size="small"
              isOptionEqualToValue={(option, value) => option.id === value.id}
              getOptionLabel={(option) =>
                option.first_name + " " + option.last_name
              }
              value={employee}
              onChange={handleEmployeeChange}
              id="employee-autocomplete"
              options={employeesData}
              sx={{ width: "65vw" }}
              renderInput={(params) => <TextField {...params} />}
            />
          </Box>
          {data.length === 0 ? (
            <Box sx={{ mt: 10 }}>
              <NothingToDisplayComponent />
            </Box>
          ) : (
            <Box sx={{ display: "flex", justifyContent: "center", m: 2, p: 2 }}>
              <Paper sx={{ width: "fit-content" }}>
                <DataGrid
                  autoHeight
                  initialState={{
                    pagination: {
                      paginationModel: {
                        pageSize: 10,
                      },
                    },
                  }}
                  disableColumnMenu
                  sx={{
                    width: "100%",
                    height: "100%",
                    "--DataGrid-overlayHeight": "50vh",
                  }}
                  pagination
                  pageSizeOptions={[5, 10, 15]}
                  rows={rows}
                  columns={columns}
                  // disableColumnFilter
                  disableColumnSelector
                  disableDensitySelector
                  slots={{
                    toolbar: GridToolbar,
                    noRowsOverlay: CustomNoRowsOverlay,
                    noResultsOverlay: CustomNoRowsOverlay,
                  }}
                  slotProps={{
                    toolbar: {
                      // csvOptions: { disableToolbarButton: true },
                      showQuickFilter: true,
                      // printOptions: { disableToolbarButton: true },
                      style: { margin: "0.5rem" },
                    },
                  }}
                  getRowId={(row) => row.index}
                />
              </Paper>
            </Box>
          )}
        </Box>
      </Box>
    </>
  );
};

export default SkillHistory;
