import {
  Box,
  Button,
  CardMedia,
  FormControl,
  IconButton,
  InputAdornment,
  TextField,
  Typography,
  Link as RouteLink,
} from "@mui/material";
import jwt from "jwt-decode";

import VisibilityIcon from "@mui/icons-material/Visibility";
import VisibilityOffIcon from "@mui/icons-material/VisibilityOff";

import { useEffect, useState } from "react";

import { ThunkDispatch } from "@reduxjs/toolkit";
import { useDispatch } from "react-redux";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { passwordChange } from "../../Service/Apis";
import Yodaplus from "../../asset/home-logo1.png";
import Yoda from "../../asset/logo-removebg-preview.png";
import { RootState } from "../../store/Store";
import {
  FetchDataFailureAction,
  FetchDataSuccessAction,
  putData,
} from "../../store/actions";
import { ToastVariant, useCustomToast } from "../Methods/SnackBar";
import axios from "axios";

interface ForgotPaawordType {
  newPassword: string;
  confirmNewPassword: string;
}

const ForgotPassword = () => {
  const [forgotPassword, setForgotPassword] = useState<ForgotPaawordType>({
    newPassword: "",
    confirmNewPassword: "",
  });
  const [validityToken, setValidityToken] = useState<boolean>(false);
  const [passwordFocused, setPasswordFocused] = useState(false);
  const [passwordFocused1, setPasswordFocused1] = useState(false);
  const [passwordFocused2, setPasswordFocused2] = useState(false);

  const [showPassword, setShowPassword] = useState(false);
  const [showPassword1, setShowPassword1] = useState(false);
  const [showPassword2, setShowPassword2] = useState(false);

  // const { token } = useParams();
  const location = useLocation();
  const token = new URLSearchParams(location.search).get("token");
  const decodedToken = base64UrlDecode(token);
  const decode: any = jwt(decodedToken as string);
  const exp: any = decode.iat;
  const iat: number = decode.iat;
  const currentTime: number = Math.floor(Date.now() / 1000);

  const navigate = useNavigate();

  const dispatch = useDispatch();
  // const success = useSelector((state: any) => state.http.data);

  const showToast = useCustomToast();
  const dispatch2: ThunkDispatch<
    RootState,
    null,
    FetchDataSuccessAction | FetchDataFailureAction
  > = useDispatch();

  const handleChange = (e: any) => {
    const { name, value } = e.target;
    setForgotPassword((prevForgotPassword) => ({
      ...prevForgotPassword,
      [name]: value,
    }));
  };

  function base64UrlDecode(base64Url: any) {
    // Add padding to the base64Url to make it a valid base64 string.
    while (base64Url.length % 4 !== 0) {
      base64Url += "=";
    }

    // Convert the base64Url to base64.
    const base64 = base64Url.replace(/-/g, "+").replace(/_/g, "/");

    // Decode the base64 string.
    return atob(base64);
  }

  const handleShowToast = (
    message: string,
    variant: ToastVariant,
    isCloseable: boolean
  ) => {
    showToast(message, variant, isCloseable);
  };

  const handleSubmit = (e: any) => {
    e.preventDefault();
    var data = {
      new_password: forgotPassword.newPassword,
      confirm_new_password: forgotPassword.confirmNewPassword,
    };

    try {
      const response = axios
        .put(`${passwordChange}${decode.user_id}`, data, {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${decodedToken}`,
          },
        })
        .then((response) => {
          handleShowToast("Password Changed Successfully!", "success", true);
          navigate("/login");
        })
        .catch((error: any) => {
          console.error(error);
          handleShowToast(error.response.data.message, "error", true);
        });
    } catch (error) {
      console.log(error);
    }
  };

  const handlePasswordFocus = () => {
    setPasswordFocused(true);
  };

  const handlePasswordBlur = () => {
    setPasswordFocused(false);
  };

  const handlePasswordFocus1 = () => {
    setPasswordFocused1(true);
  };

  const handlePasswordBlur1 = () => {
    setPasswordFocused1(false);
  };

  const handleTogglePassword = () => {
    setShowPassword((prevShowPassword) => !prevShowPassword);
  };
  const handleTogglePassword1 = () => {
    setShowPassword1((prevShowPassword) => !prevShowPassword);
  };
  // const time = currentTime - iat;
  useEffect(() => {
    if (Math.round((currentTime - iat) / 60) > 10) {
      setValidityToken(true);
    } else {
      setValidityToken(false);
    }
  }, [validityToken, currentTime]);

  return (
    <>
      <Box
        sx={{
          display: "flex",
          justifyContent: "center",
          minHeight: "100vh",
          alignItems: "center",
        }}
      >
        <Box
          sx={{
            display: "flex",
            justifyContent: { xs: "center", sm: "center", md: "space-between" },
          }}
        >
          {/* <Paper> */}
          <Box
            sx={{
              display: { xs: "none", sm: "none", md: "flex" },
              alignItems: "center",
              mr: 10,
            }}
          >
            <CardMedia
              id="yodapluslogo"
              component="img"
              src={Yodaplus}
              sx={{
                height: "150px",
              }}
            ></CardMedia>
          </Box>
          <Box sx={{ ml: { md: 30 } }}>
            <Box
              sx={{
                display: { xs: "flex", sm: "flex", md: "none" },
                justifyContent: "center",
              }}
            >
              <CardMedia
                id="miniYodaplusLogo"
                component="img"
                src={Yoda}
                sx={{ height: "6rem", width: "6rem" }}
              ></CardMedia>
            </Box>
            <Box sx={{ display: "flex", justifyContent: "center", m: 3 }}>
              {validityToken ? (
                <Box>
                  <Box sx={{ display: "flex", justifyContent: "center" }}>
                    <Typography
                      variant="h6"
                      fontSize={26}
                      fontWeight={"bold"}
                      color={"error"}
                    >
                      Your link has been expired !
                    </Typography>
                  </Box>
                  <Box sx={{ display: "flex", justifyContent: "center" }}>
                    <Typography fontSize={18} variant="h6" color={"error"}>
                      <Link to={"/login"}>
                        <RouteLink>Click here to go to login page.</RouteLink>
                      </Link>
                    </Typography>
                  </Box>
                </Box>
              ) : (
                <Box component="form" sx={{}} onSubmit={handleSubmit}>
                  <Box
                    sx={{
                      display: "flex",
                      justifyContent: "center",
                      m: 1,
                      p: 1,
                    }}
                  >
                    <Typography
                      
                      fontWeight={"bold"}
                      variant="h4"
                    >
                      Change Password
                    </Typography>
                  </Box>
                  <Box sx={{ display: "flex", justifyContent: "center" }}>
                    <FormControl>
                      <TextField
                        id="newPassword"
                        data-testid="newPassword"
                        name="newPassword"
                        size="small"
                        label="New Password"
                        autoFocus
                        onFocus={handlePasswordFocus}
                        onBlur={handlePasswordBlur}
                        type={showPassword ? "text" : "password"}
                        InputProps={{
                          inputProps: { tabIndex: 0 },
                          endAdornment: (
                            <InputAdornment position="end">
                              {passwordFocused && (
                                <IconButton
                                  onClick={handleTogglePassword}
                                  edge="end"
                                  onMouseDown={(e) => e.preventDefault()}
                                >
                                  {showPassword ? (
                                    <VisibilityOffIcon />
                                  ) : (
                                    <VisibilityIcon />
                                  )}
                                </IconButton>
                              )}
                            </InputAdornment>
                          ),
                        }}
                        variant="outlined"
                        sx={{ m: 2, background: "white", width: "18rem" }}
                        value={forgotPassword.newPassword}
                        onChange={handleChange}
                        required
                        
                      />
                      <TextField
                        id="confirmNewPassword"
                        name="confirmNewPassword"
                        size="small"
                        label=" Confirm New Password"
                        value={forgotPassword.confirmNewPassword}
                        onChange={handleChange}
                        sx={{ m: 2, background: "white", width: "18rem" }}
                        required
                        
                        InputProps={{
                          inputProps: { tabIndex: 0 },
                          endAdornment: (
                            <InputAdornment position="end">
                              {passwordFocused1 && (
                                <IconButton
                                  onClick={handleTogglePassword1}
                                  edge="end"
                                  onMouseDown={(e) => e.preventDefault()}
                                >
                                  {showPassword1 ? (
                                    <VisibilityOffIcon />
                                  ) : (
                                    <VisibilityIcon />
                                  )}
                                </IconButton>
                              )}
                            </InputAdornment>
                          ),
                        }}
                        onFocus={handlePasswordFocus1}
                        onBlur={handlePasswordBlur1}
                        type={showPassword1 ? "text" : "password"}
                      />
                      <Box
                        sx={{ m: 2, justifyContent: "center", display: "flex" }}
                      >
                        <Button
                          id="changepassword"
                          variant="contained"
                          sx={{
                            width: "90%",
                            background: "#007300",
                            color: "#cccccc",
                            "&:hover": {
                              background: "#006600",
                              color: "white",
                            },
                            borderRadius: "15px",
                          }}
                          type="submit"
                        >
                          Change Password
                        </Button>
                      </Box>
                    </FormControl>
                  </Box>
                </Box>
              )}
            </Box>
            {/* </Paper> */}
          </Box>
        </Box>
      </Box>
    </>
  );
};

export default ForgotPassword;
