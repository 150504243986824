import React, { useEffect, useState } from "react";

import CloseIcon from "@mui/icons-material/Close";
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  IconButton,
  MenuItem,
  Paper,
  Select,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  Typography,
} from "@mui/material";

import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";

import { ThunkDispatch } from "@reduxjs/toolkit";
import { createTeam, viewUsers } from "../../Service/Apis";
import {
  ToastVariant,
  useCustomToast,
} from "../../components/Methods/SnackBar";
import Footer from "../../components/Page Component/Footer";
import Navbar from "../../components/Page Component/Navbar";
import { postDataSuccess } from "../../store/AxiosSlice";
import { setCounts, setIsLoading } from "../../store/LoadingSlice";
import { RootState } from "../../store/Store";
import {
  FetchDataFailureAction,
  FetchDataSuccessAction,
  fetchData,
  postData,
} from "../../store/actions";

const AddTeam = () => {
  const [usersDetails, setUserDetails] = useState<any[]>([]); //for storing users details
  const [employee, setEmployee] = useState<any[]>([]);
  const [selectedEmployees, setSelectedEmployees] = useState<string[]>([]);
  const [empId, setEmpId] = useState<string[]>([]);
  const [projectName, setProjectName] = useState<string>("");
  const [managerId, setManagerId] = useState<string>("");
  const [managerName, setManagerName] = useState<string>("");
  const [count, setCount] = useState(0);
  const [openDeleted, setOpenDeleted] = useState<number | null>(null);

  const isloading = useSelector((state: any) => state.loading.isLoading);
  const dispatch = useDispatch();
  const dispatch2: ThunkDispatch<
    RootState,
    null,
    FetchDataSuccessAction | FetchDataFailureAction
  > = useDispatch();

  // const success = useSelector((state: any) => state.http.data);

  const navigate = useNavigate();

  const showToast = useCustomToast();

  const counts = useSelector((state: any) => state.loading.count);

  const handleShowToast = (
    message: string,
    variant: ToastVariant,
    isCloseable: boolean
  ) => {
    showToast(message, variant, isCloseable);
  };

  const handleOpenDelete = (index: number) => {
    setOpenDeleted(index);
  };
  const handleCLoseDelete = () => {
    setOpenDeleted(null);
  };

  const handleId = (e: any) => {
    setManagerName(e.target.value);
    const manager = usersDetails.filter(
      (user) =>
        user.first_name + " " + user.last_name === e.target.value &&
        setManagerId(user.id)
    );
    // setManagerId(key);
  };

  const handleEmp = (name: string) => {
    const manager = usersDetails.filter(
      (user) =>
        user.first_name + " " + user.last_name === name &&
        setEmpId([...empId, user.id])
    );
    // empId.push(manager.id);
  };

  const handleChange = (event: any) => {
    var value = event.target.value;

    setProjectName(value);
  };

  const handleDelete = (
    e: React.MouseEvent<HTMLButtonElement, MouseEvent> | undefined,
    index: number
  ) => {
    setSelectedEmployees((prevEmployee) => {
      const updatedEmployee = [...prevEmployee];
      const removedEmployee = updatedEmployee.splice(index, 1)[0]; // Get the removed employee name

      // Find the corresponding employee id and remove it from empId
      setEmpId((prevEmpId) =>
        prevEmpId.filter((_, idx) => {
          return idx !== index;
        })
      );

      return updatedEmployee;
    });
  };

  const handleEmployee = (event: React.ChangeEvent<{ value: unknown }>) => {
    const { value } = event.target;

    if (value !== managerName && !selectedEmployees.includes(value as string)) {
      setEmployee((prevEmployee) => [...prevEmployee, value as string]);
      setSelectedEmployees((prevSelectedEmployees) => [
        ...prevSelectedEmployees,
        value as string,
      ]);
      handleEmp(value as string);
    }
  };

  //create a new team
  const handleSubmit = (e: any) => {
    e.preventDefault();
    const data = {
      project_name: projectName,
      manager: managerId,
      is_360: false,
      team: empId.map((id) => {
        return {
          employee_id: id,
        };
      }),
    };
    try {
      const response = dispatch2(postData(`${createTeam}`, data))
        .then((response) => {
          handleShowToast("Team Successfully Added", "success", true);
          setCount(count + 1);
          setProjectName("");
          setManagerId("");
          setSelectedEmployees([]);
          dispatch(postDataSuccess(null));
          dispatch(setCounts(counts + 1));
          navigate("/createteam");
        })
        .catch((error) => {
          console.log(error);
        });
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    const fetchUsers = async () => {
      try {
        dispatch(setIsLoading(true));
        const response = await dispatch2(fetchData(`${viewUsers}`));
        setUserDetails(response as any[]);
        dispatch(setIsLoading(false));
      } catch (error) {
        console.log(error);
        dispatch(setIsLoading(false));
      }
    };
    fetchUsers();
  }, []);

  //shows all the teams

  return (
    <>
      <Box>
        <Navbar />
      </Box>
      <Box sx={{ mt: 12, mb: 10 }}>
        <Box sx={{ m: 2, p: 2 }}>
          <Box sx={{ display: "flex", justifyContent: "center", m: 1, p: 1 }}>
            <Typography variant="h4" fontWeight={"bold"}>
              Create Teams
            </Typography>
          </Box>
          <Box
            component="form"
            onSubmit={handleSubmit}
            sx={{ display: "flex", justifyContent: "center", m: 1, p: 2 }}
          >
            <FormControl>
              <Box sx={{ display: "flex", alignItems: "center", p: 1 }}>
                <Typography paragraph sx={{ p: 0.5, width: "9rem" }}>
                  Project Name :
                </Typography>
                <TextField
                  id="projectname"
                  required
                  name="projectName"
                  size="small"
                  variant="outlined"
                  sx={{ m: 0, width: "15rem" }}
                  value={projectName}
                  // helperText={"Project name is required"}
                  onChange={(e) => {
                    handleChange(e);
                  }}
                />
              </Box>
              <Box sx={{ display: "flex", alignItems: "center", p: 1 }}>
                <Typography
                  paragraph
                  sx={{ p: 0.5, width: "9rem", color: "black" }}
                >
                  Manager :
                </Typography>
                <Select
                  id="manager"
                  size="small"
                  required
                  // label="manager"
                  name="manager"
                  sx={{ width: "15rem" }}
                  // value={managerId}
                  onChange={(e) => {
                    handleId(e);
                  }}
                >
                  {usersDetails
                    .filter((manager) =>
                      manager.roles.some(
                        (data: string) => data.toLowerCase() === "manager" //|| "hr"
                      )
                    )

                    .map((data) => {
                      return (
                        <MenuItem
                          id={`${data.first_name} ${data.last_name}`}
                          key={data.id}
                          value={`${data.first_name} ${data.last_name}`}
                          // onChange={(e) => {
                          // 	handleId(e, data.id);
                          // }}
                        >
                          {data.first_name} {data.last_name}(
                          {data.employee_code})
                        </MenuItem>
                      );
                    })}
                </Select>
              </Box>
              <Box sx={{ p: 1 }}>
                <Box sx={{ display: "flex", alignItems: "center" }}>
                  <Typography paragraph sx={{ p: 0.5, width: "9rem" }}>
                    Employee :{" "}
                  </Typography>

                  <Select
                    id="employee"
                    required
                    size="small"
                    // label="employee"
                    name="employee"
                    sx={{ width: "15rem" }}
                    onChange={(e: any) => {
                      handleEmployee(e);
                    }}
                  >
                    {usersDetails
                      .filter(
                        (employee) =>
                          employee.roles.some(
                            (data: string) =>
                              data.toLowerCase() === "employee" || "hr"
                          ) //forEach((data : string)=> data.toLowerCase() === "employee" || "hr")
                      )
                      .map((data) => {
                        if (data.id === managerId || empId.includes(data.id)) {
                          return null;
                        }
                        return (
                          <MenuItem
                            id={`${data.first_name} ${data.last_name}`}
                            key={data.id}
                            value={`${data.first_name} ${data.last_name}`}
                            // onChange={(e) => {
                            //   handleEmp(e, data.id);
                            // }}
                          >
                            {data.first_name} {data.last_name}(
                            {data.employee_code})
                          </MenuItem>
                        );
                      })}
                  </Select>
                </Box>
                {selectedEmployees.length !== 0 && (
                  <Box
                    sx={{
                      m: 1,
                      p: 1,
                      display: "flex",
                      justifyContent: "center",
                    }}
                  >
                    <TableContainer
                      component={Paper}
                      sx={{
                        width: "fit-content",
                      }}
                    >
                      <TableHead className="header-background">
                        <TableRow>
                          <TableCell
                            sx={{ textAlign: "center", fontWeight: "bold" }}
                          >
                            Name
                          </TableCell>
                          <TableCell
                            sx={{ textAlign: "center", fontWeight: "bold" }}
                          >
                            Delete
                          </TableCell>
                        </TableRow>
                      </TableHead>
                      {selectedEmployees.map((emp, index) => {
                        return (
                          <TableBody key={index}>
                            <TableRow>
                              <TableCell id={emp} sx={{ textAlign: "center" }}>
                                {emp}
                              </TableCell>
                              <TableCell sx={{ textAlign: "center" }}>
                                <Box>
                                  <Button
                                    id={`delete${index}`}
                                    size="small"
                                    onClick={() => {
                                      handleOpenDelete(index);
                                    }}
                                  >
                                    Delete
                                  </Button>
                                  <Dialog
                                    open={openDeleted === index}
                                    onClose={handleCLoseDelete}
                                    sx={{
                                      m: 1,
                                      "& .MuiDialog-paper": {
                                        width: "30rem",
                                      },
                                    }}
                                  >
                                    <Box
                                      sx={{
                                        display: "flex",
                                        justifyContent: "flex-end",
                                        alignItems: "flex-end",
                                      }}
                                    >
                                      <IconButton
                                        id="close"
                                        onClick={() => handleCLoseDelete()}
                                      >
                                        <CloseIcon />
                                      </IconButton>
                                    </Box>
                                    <DialogTitle>Confirmation</DialogTitle>
                                    <DialogContent>
                                      <Typography>
                                        Do you want to delete?
                                      </Typography>
                                    </DialogContent>
                                    <DialogActions>
                                      <Button
                                        id="no"
                                        onClick={handleCLoseDelete}
                                      >
                                        No
                                      </Button>
                                      <Button
                                        id="yes"
                                        variant="contained"
                                        onClick={(e) => {
                                          handleDelete(e, index);
                                          handleCLoseDelete();
                                        }}
                                      >
                                        Yes
                                      </Button>
                                    </DialogActions>
                                  </Dialog>
                                </Box>
                              </TableCell>
                            </TableRow>
                          </TableBody>
                        );
                      })}
                    </TableContainer>
                  </Box>
                )}
              </Box>
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "right",
                  m: 1,
                  p: 1,
                }}
              >
                <Button
                  size="small"
                  onClick={() => navigate("/createteam")}
                  sx={{ m: 1 }}
                >
                  Back
                </Button>
                <Button
                  id="createteam"
                  type="submit"
                  variant="contained"
                  size="small"
                  sx={{ m: 1 }}

                  // onClick={(e) => handleSubmit(e)}
                >
                  Create Team
                </Button>
              </Box>
            </FormControl>
          </Box>
        </Box>
      </Box>
      <Box>
        <Footer />
      </Box>
    </>
  );
};

export default AddTeam;
