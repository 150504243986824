import React, { useEffect, useState } from "react";

import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";

import {
  Box,
  Button,
  Card,
  Divider,
  FormControl,
  FormControlLabel,
  Radio,
  RadioGroup,
  Rating,
  TextField,
  Typography,
} from "@mui/material";
import ArrowForwardIosSharpIcon from "@mui/icons-material/ArrowForwardIosSharp";
import MuiAccordion, { AccordionProps } from "@mui/material/Accordion";
import MuiAccordionSummary, {
  AccordionSummaryProps,
} from "@mui/material/AccordionSummary";
import MuiAccordionDetails from "@mui/material/AccordionDetails";
import { styled } from "@mui/material/styles";

import Navbar from "../../components/Page Component/Navbar";
import Footer from "../../components/Page Component/Footer";
import {
  editEmployeeSelfResponse,
  viewEmployeeSelfResponse,
} from "../../Service/Apis";
import useAxiosInterceptor from "../../components/layout/AxiosInstance";
import NothingToDisplayImage from "../../asset/NothingToDisplayImage.png";

import { enqueueSnackbar } from "notistack";
import { date } from "../Form Add/AddForm";
import { setIsLoading } from "../../store/LoadingSlice";
import Skeletons from "../../components/Skeleton/Skeletons";
import { ResponseTypee } from "./Types/ManagerReviewFormTypes";
import {
  FetchDataFailureAction,
  FetchDataSuccessAction,
  fetchData,
  postData,
  putData,
} from "../../store/actions";
import { ThunkDispatch } from "@reduxjs/toolkit";
import { RootState } from "../../store/Store";
import NothingToDisplayComponent from "../../components/Data/NothingToShowComponent";

const Accordion = styled((props: AccordionProps) => (
  <MuiAccordion disableGutters elevation={0} square {...props} />
))(({ theme }) => ({
  border: `1px solid ${theme.palette.divider}`,
  "&:not(:last-child)": {
    borderBottom: 0,
  },
  "&:before": {
    display: "none",
  },
}));

const AccordionSummary = styled((props: AccordionSummaryProps) => (
  <MuiAccordionSummary
    expandIcon={<ArrowForwardIosSharpIcon sx={{ fontSize: "0.9rem" }} />}
    {...props}
  />
))(({ theme }) => ({
  backgroundColor:
    theme.palette.mode === "dark"
      ? "rgba(255, 255, 255, .05)"
      : "rgba(0, 0, 0, .00)",
  flexDirection: "row-reverse",
  "& .MuiAccordionSummary-expandIconWrapper.Mui-expanded": {
    transform: "rotate(90deg)",
  },
  "& .MuiAccordionSummary-content": {
    marginLeft: theme.spacing(1),
  },
}));

const AccordionDetails = styled(MuiAccordionDetails)(({ theme }) => ({
  padding: theme.spacing(2),
  borderTop: "1px solid rgba(0, 0, 0, .125)",
}));

const EmployeeSpecificResponse = () => {
  const [viewResponse, setViewResponse] = useState<ResponseTypee[]>([]); //gets the response filled by the same employee

  const [value, setValue] = useState<number | null>(0);
  const axiosInstance = useAxiosInterceptor();
  const dispatch = useDispatch();
  const isLoading = useSelector((state: any) => state.loading.isLoading);
  const Navigate = useNavigate();
  const dispatch2: ThunkDispatch<
    RootState,
    null,
    FetchDataSuccessAction | FetchDataFailureAction
  > = useDispatch();

  const formId =
    useSelector((state: any) => state.form.formTeamId) ||
    localStorage.getItem("formTeamId");

  const formTeamId =
    useSelector((state: any) => state.form.formTeamId) ||
    localStorage.getItem("formTeamId");

  const token = localStorage.getItem("token");

  const todaysDate = new Date(date);

  const manager_deadline = new Date(viewResponse[0]?.manager_deadline);

  const hasSpecialCharacters = (input: string): boolean => {
    const specialCharacterPattern = /[!#%^*()_+\=[\]{};:"\\|<>/?]/;
    return specialCharacterPattern.test(input);
  };

  const handleSubmit = (e: any) => {
    e.preventDefault();
  };

  //for fetching the storing the form that employee has filled
  useEffect(() => {
    const fetchEmployeeResponse = async () => {
      try {
        dispatch(setIsLoading(true));
        const response = await dispatch2(
          fetchData(`${viewEmployeeSelfResponse}${formId}`)
        );
        setViewResponse(response as ResponseTypee[]);
        dispatch(setIsLoading(false));
      } catch (error) {
        console.log(error);
        dispatch(setIsLoading(false));
      }
    };
    fetchEmployeeResponse();
  }, []);

  if (isLoading) {
    return (
      <>
        <Skeletons />
      </>
    );
  }

  return (
    <>
      <Box>
        <Navbar />
      </Box>
      <Box sx={{ mt: 12, mb: 10, display: "flex", justifyContent: "center" }}>
        <Box>
          {viewResponse.length === 0 ? (
            <Box
              sx={{
                mt: 9,
                // "&:hover": { boxShadow: 20 },
                display: "flex",
                flexDirection: "column",
                height: "100%",
                width: "75vw",
                // width: "fit-content",
              }}
            >
              <NothingToDisplayComponent />
            </Box>
          ) : (
            <>
              {viewResponse?.map((res) => {
                return (
                  <Box
                    key={res.form_id}
                    component="form"
                    onSubmit={handleSubmit}
                    sx={{ maxWidth: "80vw" }}
                  >
                    <FormControl>
                      <Box
                        sx={{
                          mt: 1,
                          pt: 2,
                          display: "flex",
                          justifyContent: "center",
                          width: "100% ",
                        }}
                      >
                        <Typography
                          fontWeight={"bold"}
                          letterSpacing={3}
                          variant="h4"
                          sx={{ textDecoration: "underline" }}
                        >
                          {res.form_name}
                        </Typography>
                      </Box>
                      <Box
                        sx={{
                          m: 1,
                          mt: 2,
                          mb: 3,
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center",
                        }}
                      >
                        <Typography variant="h5">
                          {res.form_description
                            ? `(${res.form_description})`
                            : "(No description)"}
                        </Typography>
                      </Box>
                      <Box
                        sx={{
                          m: 2,
                          display: "flex",
                          justifyContent: "space-between",
                        }}
                      >
                        <Box>
                          <Box
                            sx={{ display: "flex", alignItems: "center", m: 1 }}
                          >
                            <Typography
                              sx={{ width: "9rem" }}
                              fontWeight={"bold"}
                            >
                              Appraisee :{" "}
                            </Typography>
                            <Typography>{res.employee_name}</Typography>
                          </Box>
                          <Box
                            sx={{ display: "flex", alignItems: "center", m: 1 }}
                          >
                            <Typography
                              sx={{ width: "9rem" }}
                              fontWeight={"bold"}
                            >
                              Manager Name :{" "}
                            </Typography>
                            <Typography>{res.manager}</Typography>
                          </Box>
                        </Box>
                        <Box>
                          <Box
                            sx={{ display: "flex", alignItems: "center", m: 1 }}
                          >
                            <Typography
                              sx={{ width: "9rem" }}
                              fontWeight={"bold"}
                            >
                              Employee Code :{" "}
                            </Typography>
                            <Typography>{res.employee_code}</Typography>
                          </Box>
                          <Box
                            sx={{ display: "flex", alignItems: "center", m: 1 }}
                          >
                            <Typography
                              sx={{ width: "9rem" }}
                              fontWeight={"bold"}
                            >
                              Designation :{" "}
                            </Typography>
                            <Typography>{res.designation}</Typography>
                          </Box>
                        </Box>
                      </Box>
                      <Divider />
                      <Box
                        sx={{
                          display: "flex",
                          alignItems: "center",
                          m: 2,
                          p: 1,
                          justifyContent: "left",
                        }}
                      >
                        <Typography sx={{ width: "10rem" }} fontWeight={"bold"}>
                          Employee Rating :{" "}
                        </Typography>
                        <Typography>{res.employee_average_rating}</Typography>
                      </Box>
                      <Box sx={{}}>
                        {res.response.map((section, sectionIndex) => {
                          return (
                            <Box sx={{ m: 2, width: "75vw" }}>
                              <Accordion sx={{ m: 2, p: 0, width: "100%" }}>
                                <AccordionSummary>
                                  <Box>
                                    <Typography
                                      variant="h5"
                                      fontWeight={"bold"}
                                    >
                                      {String.fromCharCode(
                                        sectionIndex + "A".charCodeAt(0)
                                      ) +
                                        ") " +
                                        section.section}
                                    </Typography>
                                  </Box>
                                </AccordionSummary>
                                <AccordionDetails>
                                  <Box sx={{ m: 2, p: 2 }}>
                                    {section.categories.map(
                                      (category, categoryIndex) => {
                                        return (
                                          <Box>
                                            <Box>
                                              <Typography
                                                variant="h5"
                                                fontWeight={"bold"}
                                              >
                                                {String.fromCharCode(
                                                  categoryIndex +
                                                    "a".charCodeAt(0)
                                                ) +
                                                  ". " +
                                                  category.category}
                                              </Typography>
                                            </Box>
                                            <Box sx={{ m: 2, p: 2 }}>
                                              {category.questions.map(
                                                (question, questionIndex) => {
                                                  return (
                                                    <Box
                                                      key={question.question_id}
                                                    >
                                                      <Box>
                                                        <Typography
                                                          fontWeight={"bold"}
                                                          fontSize={19}
                                                          variant="h6"
                                                        >
                                                          {questionIndex +
                                                            1 +
                                                            "."}{" "}
                                                          {
                                                            question.question_name
                                                          }
                                                        </Typography>
                                                      </Box>
                                                      <Box
                                                        sx={{ ml: 2, mr: 2 }}
                                                      >
                                                        {question.answer_type ===
                                                        "short_answer" ? (
                                                          <Box>
                                                            <TextField
                                                              required
                                                              name={`descriptive_${questionIndex}`}
                                                              size="small"
                                                              fullWidth
                                                              multiline
                                                              maxRows={4}
                                                              InputProps={{
                                                                readOnly: true,
                                                              }}
                                                              sx={{
                                                                m: 2,
                                                                overflow:
                                                                  "auto",
                                                              }}
                                                              // disabled
                                                              value={
                                                                question.employee_response
                                                              }
                                                            />
                                                          </Box>
                                                        ) : (
                                                          <Box>
                                                            <Box
                                                              sx={{
                                                                display:
                                                                  "block",
                                                                alignItems:
                                                                  "center",
                                                                m: 1,
                                                              }}
                                                            >
                                                              <RadioGroup
                                                                name={`choices_${questionIndex}`}
                                                                value={
                                                                  question.employee_response
                                                                }
                                                              >
                                                                {question.answer_choices &&
                                                                  question.answer_choices.map(
                                                                    (
                                                                      ans: any
                                                                    ) => (
                                                                      <FormControlLabel
                                                                        required
                                                                        disabled={
                                                                          true
                                                                        }
                                                                        key={
                                                                          ans.option_id
                                                                        }
                                                                        value={
                                                                          ans.answer_value
                                                                        }
                                                                        control={
                                                                          <Radio />
                                                                        }
                                                                        label={
                                                                          ans.answer_value
                                                                        }
                                                                      />
                                                                    )
                                                                  )}
                                                              </RadioGroup>
                                                            </Box>
                                                            <Box
                                                              sx={{
                                                                display: "flex",
                                                                m: 1,
                                                                p: 1,
                                                              }}
                                                            >
                                                              <Typography
                                                                fontWeight={
                                                                  "bold"
                                                                }
                                                                paragraph
                                                                sx={{
                                                                  width:
                                                                    "11rem",
                                                                }}
                                                              >
                                                                Comments :{" "}
                                                              </Typography>
                                                              <TextField
                                                                name="employee_comment"
                                                                fullWidth
                                                                multiline
                                                                // rows={2}
                                                                maxRows={4}
                                                                InputProps={{
                                                                  readOnly:
                                                                    true,
                                                                }}
                                                                style={{
                                                                  height:
                                                                    "auto",
                                                                  overflow:
                                                                    "auto",
                                                                }}
                                                                value={
                                                                  question.employee_comment
                                                                }
                                                              />
                                                            </Box>
                                                          </Box>
                                                        )}
                                                        <Box
                                                          sx={{
                                                            display: "flex",
                                                            m: 1,
                                                            p: 1,
                                                          }}
                                                        >
                                                          <Typography
                                                            fontWeight={"bold"}
                                                            paragraph
                                                            sx={{
                                                              width: "8.5rem",
                                                              mt: 0.5,
                                                            }}
                                                          >
                                                            Rating :{" "}
                                                          </Typography>
                                                          <Rating
                                                            size="large"
                                                            precision={0.5}
                                                            readOnly={true}
                                                            value={parseFloat(
                                                              question.employee_rating
                                                            )}
                                                            className="rating-color"
                                                            aria-required="true"
                                                          />
                                                        </Box>
                                                      </Box>
                                                      {todaysDate >
                                                        manager_deadline &&
                                                        res.manager_fields_visible && (
                                                          <>
                                                            <Box
                                                              sx={{
                                                                display: "flex",
                                                                m: 3,
                                                                p: 1,
                                                              }}
                                                            >
                                                              <Typography
                                                                fontWeight={
                                                                  "bold"
                                                                }
                                                                paragraph
                                                                sx={{
                                                                  width:
                                                                    "8.5rem",
                                                                  mt: 0.5,
                                                                }}
                                                              >
                                                                Manager Rating :{" "}
                                                              </Typography>
                                                              <Rating
                                                                size="large"
                                                                readOnly
                                                                precision={0.5}
                                                                value={parseFloat(
                                                                  question.manager_response as string
                                                                )}
                                                              />
                                                            </Box>
                                                            <Box
                                                              sx={{
                                                                display: "flex",
                                                                m: 3,
                                                                p: 1,
                                                              }}
                                                            >
                                                              <Typography
                                                                fontWeight={
                                                                  "bold"
                                                                }
                                                                paragraph
                                                                sx={{
                                                                  width:
                                                                    "11rem",
                                                                }}
                                                              >
                                                                Manager Comments
                                                                :{" "}
                                                              </Typography>
                                                              <TextField
                                                                name="manager_comment"
                                                                fullWidth
                                                                multiline
                                                                maxRows={4}
                                                                style={{
                                                                  height:
                                                                    "auto",
                                                                  overflow:
                                                                    "auto",
                                                                }}
                                                                InputProps={{
                                                                  readOnly:
                                                                    true,
                                                                }}
                                                                value={
                                                                  question.manager_comment
                                                                }
                                                              />
                                                            </Box>
                                                          </>
                                                        )}
                                                    </Box>
                                                  );
                                                }
                                              )}
                                            </Box>
                                          </Box>
                                        );
                                      }
                                    )}
                                  </Box>
                                </AccordionDetails>
                              </Accordion>
                            </Box>
                          );
                        })}
                      </Box>
                    </FormControl>
                    {todaysDate > manager_deadline && (
                      <Box
                        sx={{
                          display: "flex",
                          justifyContent: "space-between",
                          alignItems: "center",
                        }}
                      >
                        <Box
                          sx={{
                            display: "flex",
                            alignItems: "center",
                            m: 2,
                            p: 1,
                            justifyContent: "right",
                          }}
                        >
                          <Typography
                            sx={{ width: "12rem" }}
                            fontWeight={"bold"}
                          >
                            Admin Rating :{" "}
                          </Typography>
                          <Typography>{res.admin_average_rating}</Typography>
                        </Box>
                        <Box
                          sx={{
                            display: "flex",
                            alignItems: "center",
                            m: 2,
                            p: 1,
                            justifyContent: "right",
                          }}
                        >
                          <Typography
                            sx={{ width: "12rem" }}
                            fontWeight={"bold"}
                          >
                            Manager Rating :{" "}
                          </Typography>
                          <Typography>{res.manager_avg_rating}</Typography>
                        </Box>
                      </Box>
                    )}
                  </Box>
                );
              })}
            </>
          )}
        </Box>
      </Box>
      <Box>
        <Footer />
      </Box>
    </>
  );
};

export default EmployeeSpecificResponse;
