import { createSlice, PayloadAction } from "@reduxjs/toolkit";

export interface FormState {
  id: string;
  qId: string;
  empId: string;
  role: string;
  response_id: string;
  manager: string;
  formTeamId: string;
  resId: string;
  projectName: string;
}

const initialState: FormState = {
  id: "",
  qId: "",
  empId: "",
  role: "Employee",
  response_id: "",
  manager: "",
  formTeamId: "",
  resId: "",
  projectName: "",
};

const FormSlice = createSlice({
  name: "form",
  initialState,
  reducers: {
    setId: (state, action: PayloadAction<string>) => {
      state.id = action.payload;
    },
    setQid: (state, action: PayloadAction<string>) => {
      state.qId = action.payload;
    },
    setEmpId: (state, action: PayloadAction<string>) => {
      state.empId = action.payload;
    },
    setRole: (state, action: PayloadAction<string>) => {
      state.role = action.payload;
      localStorage.setItem("nRole", action.payload);
    },
    setManager: (state, action: PayloadAction<string>) => {
      state.manager = action.payload;
      localStorage.setItem("manager", action.payload);
    },
    setResponseId: (state, action: PayloadAction<string>) => {
      state.response_id = action.payload;
    },
    setFormTeamId: (state, action: PayloadAction<string>) => {
      state.formTeamId = action.payload;
    },
    setResId: (state, action: PayloadAction<string>) => {
      state.resId = action.payload;
      localStorage.setItem("resId", action.payload);
    },
    setProjectNameState: (state, action: PayloadAction<string>) => {
      state.projectName = action.payload;
      localStorage.setItem("projectName", action.payload);
    },
  },
});

export const {
  setId,
  setQid,
  setEmpId,
  setRole,
  setFormTeamId,
  setResId,
  setResponseId,
  setManager,
  setProjectNameState,
} = FormSlice.actions;

export default FormSlice;
