import { useEffect, useMemo, useState } from "react";

import CloseIcon from "@mui/icons-material/Close";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import ArrowDropUpIcon from "@mui/icons-material/ArrowDropUp";
import {
  Box,
  Button,
  Card,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Divider,
  IconButton,
  InputLabel,
  Menu,
  MenuItem,
  Modal,
  Paper,
  Rating,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  Typography,
} from "@mui/material";
import { ThemeProvider, createTheme } from "@mui/material/styles";

import useAxiosInterceptor from "../../components/layout/AxiosInstance";

import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";

import { ThunkDispatch } from "@reduxjs/toolkit";
import {
  adminDash,
  adminRatingChange,
  hrEditResponse,
} from "../../Service/Apis";
import NothingToDisplayImage from "../../asset/NothingToDisplayImage.png";
import {
  ToastVariant,
  useCustomToast,
} from "../../components/Methods/SnackBar";
import { setId } from "../../store/FormSlice";
import { setIsLoading } from "../../store/LoadingSlice";
import { RootState } from "../../store/Store";
import {
  FetchDataFailureAction,
  FetchDataSuccessAction,
  patchData,
  putData,
} from "../../store/actions";
import { postDataSuccess } from "../../store/AxiosSlice";
import { labels } from "./ReviewForm";
import { error } from "console";
import { DataGrid, GridColDef, GridToolbar } from "@mui/x-data-grid";
import CustomNoRowsOverlay from "../../components/Data/NoRowsOverlayComponent";
import NothingToDisplayComponent from "../../components/Data/NothingToShowComponent";
import Loading from "../../components/Loading/Loading";

type hrComponent = {
  hr_id: string;
  hr_edit_permission: boolean;
  employee_name: string;
  form_name: string;
  manager_average_rating: string;
  manager_name: string;
  response_id: string;
  team_name: string;
  admin_average_rating: string;
  [key: string]: any;
};
interface ResponseType {
  response_id: string;
  form_id: string;
  form_name: string;
  employee_id: string;
  employee_name: string;
  admin_average_rating: string;
  final_response: [
    {
      hr_id: string;
      hr_edit_permission: boolean;
      final_response_id: string;
      manager_average_rating: number;
      response_id: string;
    }
  ];
  response: [
    {
      response_question_id: string;
      question_id: string;
      question_name: string;
      employee_response: string;
      employee_comment: string;
      manager_response: string;
      manager_comment: string;
    }
  ];
}

const AdminReviewForm = () => {
  const [reviewItems, setReviewItems] = useState<hrComponent[]>([]);
  const [count, setCount] = useState<number>(0);
  const [ratingvalidation, setRatingValidation] = useState<boolean>(false);
  const [adminRatingModal, setAdminRatingModal] = useState<number | null>(null);
  const [hoverValue, setHoverValue] = useState<number | null>(0);
  const [hover, setHover] = useState<number[]>([]);
  const [openMenus, setOpenMenus] = useState<Record<number, boolean>>({});
  const [searchTerm, setSearchTerm] = useState("");

  const dispatch = useDispatch();
  const Navigate = useNavigate();

  const axiosInstance = useAxiosInterceptor();
  const showToast = useCustomToast();
  const dispatch2: ThunkDispatch<
    RootState,
    null,
    FetchDataSuccessAction | FetchDataFailureAction
  > = useDispatch();
  const token = localStorage.getItem("token");
  // const success = useSelector((state: any) => state.http.data);

  const isLoading = useSelector((state: any) => state.loading.isLoading);

  const handleOpenAdminRatingModal = (index: number) => {
    setAdminRatingModal(index);
  };

  const handleCloseAdminRatingModal = () => {
    if (ratingvalidation) {
      handleShowToast("please save the admin rating", "error", true);
    } else {
      setAdminRatingModal(null);
      setRatingValidation(false);
    }
  };

  const handleOpenNavMenu = (index: number) => {
    setOpenMenus({ ...openMenus, [index]: true });
  };

  const handleCloseNavMenu = (index: number) => {
    setOpenMenus({ ...openMenus, [index]: false });
    // setAdminRating(null);
  };

  const handleGoToReview = (value: any) => {
    dispatch(setId(value));
    localStorage.setItem("formid", value);
    Navigate("/showspecificresponse");
  };

  const handleShowToast = (
    message: string,
    variant: ToastVariant,
    isCloseable: boolean
  ) => {
    showToast(message, variant, isCloseable);
  };

  const handleAdminRatingSave = (
    e: any,
    index: number,
    response_id: string
  ) => {
    e.preventDefault();
    if (reviewItems[index].admin_average_rating == undefined) {
      reviewItems[index].admin_average_rating = "0";
    }
    var data = {
      admin_average_rating: reviewItems[index].admin_average_rating,
    };
    try {
      const response = dispatch2(
        patchData(`${adminRatingChange}?response_id=${response_id}`, data)
      )
        .then((res) => {
          setRatingValidation(false);
          setAdminRatingModal(null);
          handleCloseNavMenu(index);
          handleShowToast("Admin Rating Changed !", "success", true);
        })
        .catch((error) => {
          handleShowToast(`${error.response.data.message}`, "error", true);
        });
    } catch (error) {
      console.log(error);
    }
  };

  //
  const filteredData = useMemo(() => {
    if (searchTerm === "") {
      return reviewItems;
    }
    return reviewItems.filter((review) =>
      Object.values(review).some(
        (value) =>
          value &&
          value.toString().toLowerCase().includes(searchTerm.toLowerCase())
      )
    );
  }, [reviewItems, searchTerm]);

  const rows = useMemo(() => {
    return filteredData.map((employee, index) => ({
      ...employee,
      index: index,
    }));
  }, [filteredData]);
  const columns: GridColDef[] = useMemo(
    () => [
      {
        headerName: "Employee Name",
        field: "employee_name",
        align: "center",
        headerAlign: "center",
        headerClassName: "header-background",
        width: 175,
        renderHeader: () => (
          <strong className="custom-header" style={{ fontSize: 15 }}>
            Employee Name
          </strong>
        ),
      },
      {
        headerName: "Form Name",
        field: "form_name",
        align: "center",
        headerAlign: "center",
        headerClassName: "header-background",
        width: 165,
        renderHeader: () => (
          <strong className="custom-header" style={{ fontSize: 15 }}>
            Form Name
          </strong>
        ),
      },
      {
        headerName: "Team Name",
        field: "team_name",
        align: "center",
        headerAlign: "center",
        headerClassName: "header-background",
        width: 165,
        renderHeader: () => (
          <strong className="custom-header" style={{ fontSize: 15 }}>
            Team Name
          </strong>
        ),
      },
      {
        headerName: "Manager Name",
        field: "manager_name",
        align: "center",
        headerAlign: "center",
        headerClassName: "header-background",
        width: 195,
        renderHeader: () => (
          <strong className="custom-header" style={{ fontSize: 15 }}>
            Manager Name
          </strong>
        ),
      },
      {
        headerName: "Employee Average Rating",
        field: "employee_average_rating",
        align: "center",
        headerAlign: "center",
        headerClassName: "header-background",
        width: 205,
        renderHeader: () => (
          <strong className="custom-header" style={{ fontSize: 15 }}>
            Employee Average Rating
          </strong>
        ),
      },
      {
        headerName: "Manager Average Rating",
        field: "manager_average_rating",
        align: "center",
        headerAlign: "center",
        headerClassName: "header-background",
        width: 205,
        renderHeader: () => (
          <strong className="custom-header" style={{ fontSize: 15 }}>
            Manager Average Rating
          </strong>
        ),
      },
      {
        headerName: "Standard Deviation",
        field: "standard_deviation",
        align: "center",
        headerAlign: "center",
        headerClassName: "header-background",
        width: 185,
        renderHeader: () => (
          <strong className="custom-header" style={{ fontSize: 15 }}>
            Standard Deviation
          </strong>
        ),
      },
      {
        headerName: "Actions",
        field: "response_id",
        align: "center",
        headerAlign: "center",
        headerClassName: "header-background",
        width: 135,
        renderHeader: () => (
          <strong className="custom-header" style={{ fontSize: 15 }}>
            Actions
          </strong>
        ),
        renderCell: (params) => (
          <>
            <Button
              id={`dashboardMenu${params.row.index}`}
              onClick={() => handleOpenNavMenu(params.row.index)}
              endIcon={<KeyboardArrowDownIcon />}
            >
              Select
            </Button>
            <Menu
              id={`menu-appbar${params.row.index}`}
              anchorEl={
                openMenus[params.row.index]
                  ? document.getElementById(`dashboardMenu${params.row.index}`)
                  : null
              }
              keepMounted
              open={openMenus[params.row.index] || false}
              onClose={() => handleCloseNavMenu(params.row.index)}
            >
              <MenuItem
                onClick={() => {
                  handleGoToReview(params.row.response_id);
                }}
              >
                Go to Review
              </MenuItem>
              <MenuItem
                onClick={() => {
                  handleCloseNavMenu(params.row.index);
                  handleOpenAdminRatingModal(params.row.index);
                }}
                disabled={
                  reviewItems[params.row.index]?.manager_average_rating === "-"
                }
              >
                Admin Rating
              </MenuItem>
            </Menu>
            <Dialog
              open={adminRatingModal === params.row.index}
              // onClose={() => {
              //   handleCloseAdminRatingModal();
              //   handleCloseNavMenu(index);
              // }}
              aria-labelledby="alert-dialog-title"
              aria-describedby="alert-dialog-description"
            >
              <Box
                component={"form"}
                onSubmit={(e) => {
                  // handleCloseAdminRatingModal();
                  handleCloseNavMenu(params.row.index);
                  handleAdminRatingSave(
                    e,
                    params.row.index,
                    params.row.response_id
                  );
                }}
              >
                <Box
                  sx={{
                    display: "flex",
                    justifyContent: "flex-end",
                    alignItems: "flex-end",
                  }}
                >
                  <IconButton
                    onClick={() => {
                      handleCloseAdminRatingModal();
                      handleCloseNavMenu(params.row.index);
                    }}
                  >
                    <CloseIcon />
                  </IconButton>
                </Box>
                <DialogTitle
                  sx={{
                    textAlign: "center",
                    fontWeight: "bold",
                    fontSize: 24,
                  }}
                  id="alert-dialog-title"
                >
                  {"Admin Rating"}
                </DialogTitle>
                <DialogContent
                  sx={{
                    width: 300,
                    height: 55,
                    overflow: "hidden",
                    textAlign: "center",
                  }}
                >
                  {/* <Box sx={{ m: 0, p: 0 }}> */}
                  <Rating
                    precision={0.5}
                    className="rating-color"
                    size="large"
                    value={parseFloat(
                      reviewItems?.[params.row.index]?.admin_average_rating ||
                        "0"
                    )}
                    onChange={(e, newValue) => {
                      setReviewItems((prev) => {
                        setRatingValidation(true);
                        const updatedItems = [...prev];
                        updatedItems[params.row.index].admin_average_rating =
                          newValue?.toString() as string;
                        return updatedItems;
                      });
                    }}
                    onChangeActive={(event, newHover) => {
                      setHover((prevState) => {
                        const updatedHover = [...prevState];
                        updatedHover[params.row.index] = newHover; // Toggle the hover value for the specified index
                        return updatedHover;
                      });
                    }}
                  />
                  {hoverValue !== null && (
                    <>
                      <Typography sx={{ textAlign: "center" }}>
                        {
                          labels[
                            hover[params.row.index] !== -1
                              ? hover[params.row.index]
                              : hoverValue
                          ]
                        }
                      </Typography>
                    </>
                  )}
                  {/* </Box> */}
                </DialogContent>
                <DialogActions>
                  <Button
                    sx={{ p: 1, mr: 0.4, mb: 0.4 }}
                    size="small"
                    variant="outlined"
                    onClick={(e) => {
                      setAdminRatingModal(null);
                      setRatingValidation(false);
                      handleCloseNavMenu(params.row.index);
                      setCount(count + 1);
                    }}
                  >
                    cancel
                  </Button>
                  <Button
                    sx={{ p: 1, mr: 0.4, mb: 0.4 }}
                    size="small"
                    variant="outlined"
                    
                    type="submit"
                  >
                    Save
                  </Button>
                </DialogActions>
              </Box>
            </Dialog>
          </>
        ),
      },
    ],
    [openMenus, hover]
  );

  //getting the admin dashboard contents
  useEffect(() => {
    const fetchReviewItems = async () => {
      const token = localStorage.getItem("token");
      try {
        dispatch(setIsLoading(true));
        const response = await axiosInstance.get(`${adminDash}`, {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
        });
        setReviewItems(response.data);

        dispatch(setIsLoading(false));
      } catch (error: any) {
        console.error(error);
        if (error.response.status === 401) {
          Navigate("/login");
        }
      }
    };

    fetchReviewItems();
  }, [count]);

  if (isLoading) {
    return (
      <>
        <Loading isLoading={isLoading as boolean} />
      </>
    );
  }

  return (
    <>
      <Box sx={{}}>
        <Box sx={{ display: "flex", justifyContent: "center", m: 2 }}>
          <Typography fontWeight="bold" variant="h3">
            Reviews Submitted
          </Typography>
        </Box>
        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
            overflow: "hidden",
          }}
        >
          <Box>
            <Box>
              <Box
                component="form"
                onSubmit={(e) => {
                  e.preventDefault();
                }}
                sx={{
                  display: "flex",
                  justifyContent: { xs: "left", sm: "left", md: "right" },
                  alignItems: "center",
                  m: 1,
                  p: 1,
                }}
              >
                <Button
                  type="submit"
                  variant="contained"
                  onClick={(e) => {
                    Navigate("/downloadadmin");
                  }}
                  sx={{ m: 1, display: { xs: "none", sm: "none", md: "flex" } }}
                >
                  Go to Download
                </Button>
              </Box>

              <Box>
                <Box
                  sx={{
                    m: 2,
                    p: 1,
                    width: "100%",
                  }}
                >
                  <Box
                    sx={{
                      width: "100%",
                      display: "flex",
                      justifyContent: "center",
                    }}
                  >
                    {rows.length === 0 ? (
                      <Box
                        sx={{
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center",
                          p: 2,
                          width: "75vw",
                          height: "100%",
                        }}
                      >
                        <NothingToDisplayComponent />
                      </Box>
                    ) : (
                      <Paper sx={{ m: 2 }} style={{ width: "fit-content" }}>
                        <DataGrid
                          autoHeight
                          getRowId={(row) => row.response_id}
                          disableColumnMenu
                          initialState={{
                            pagination: {
                              paginationModel: {
                                pageSize: 10,
                              },
                            },
                          }}
                          sx={{
                            width: "100%",
                            height: "100%",
                            "--DataGrid-overlayHeight": "50vh",
                          }}
                          pagination
                          pageSizeOptions={[5, 10, 15]}
                          rows={rows}
                          columns={columns}
                          disableColumnSelector
                          disableDensitySelector
                          slots={{
                            toolbar: GridToolbar,
                            noRowsOverlay: CustomNoRowsOverlay,
                            noResultsOverlay: CustomNoRowsOverlay,
                          }}
                          slotProps={{
                            toolbar: {
                              csvOptions: { disableToolbarButton: true },
                              showQuickFilter: true,
                              printOptions: { disableToolbarButton: true },
                              style: { margin: "0.5rem" },
                            },
                          }}
                        />
                      </Paper>
                    )}
                  </Box>
                </Box>
              </Box>
            </Box>
          </Box>
        </Box>
      </Box>
    </>
  );
};

export default AdminReviewForm;
