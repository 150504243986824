import { CircularProgress } from "@mui/material";
import { Box } from "@mui/material";
import React from "react";

const Loading = ({ isLoading }: any) => {
  return (
    <>
      {/* Fullscreen Overlay */}
      <Box
        id="fullscreen-overlay"
        style={{
          position: "fixed",
          top: 0,
          left: 0,
          width: "100%",
          height: "100%",
          backdropFilter: "blur(1.5px)", // Adjust the blur intensity as needed
          zIndex: 9999,
        }}
      />
      {/* Loader Container */}
      <Box
        id="loader-container"
        sx={{
          position: "fixed", // Use fixed positioning for the loader container
          top: 0,
          left: 0,
          width: "100%",
          height: "100%",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          zIndex: 10000,
        }}
      >
        {/* Loader */}
        <Box
          id="loader"
          sx={{
            position: "relative", // Use relative positioning for the actual loader
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          {isLoading && <CircularProgress size={50} />}
        </Box>
      </Box>
    </>
  );
};

export default Loading;
